import React, { useState, useEffect } from "react";

const useValidation = (fetchData) => {

  const [validation, setValidation] = useState({
    loading: true,
    status: false,
  });

  const getErrorStatus = () => {
    if (Object.keys(fetchData.error).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {

    if(!fetchData.loading){
      setValidation((prev) => {
        return {
          ...prev,
          loading: false,
          status: getErrorStatus(),
        };
      });
    }else{
      setValidation((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
    }
    
  }, [fetchData.loading]);

  return { validation };
};

export default useValidation;
