import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import ImageIcon from "@material-ui/icons/Image";
import CustomSelect from "../../customComponents/CustomSelect";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { InputGroup, FormControl } from "react-bootstrap";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  fetchSingleNftCollectionsStart,
  nftCollectionsSaveStart,
  nftCollectionNameValidationStart,
} from "../../store/actions/NftAction";
import { useHistory, useParams } from "react-router-dom";
import { authContext } from "../authprovider/AuthProvider";
import PageNotFound from "../../components/404/PageNotFound";
import DeleteModal from "../../components/Modals/DeleteModal";
import EditCollectionLoader from "../loaders/EditCollectionLoader";
import { Switch } from "@material-ui/core";
import NftToken from "../../abis/NftToken.json";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import configuration from "react-global-configuration";
import { CHAIN_INFO } from "../authprovider/chains";

const EditCollection = (props) => {
  const { categories } = props;

  const history = useHistory();

  const { auth } = useContext(authContext);

  const { contract_address } = useParams();

  const [networkSelect, setNetworkSelect] = useState([
    { name: "Polygon", id: 1 },
  ]);

  const [skipInitalRender, setSkipInitalRender] = useState(false);

  const [validCollectionName, setValidCollectionName] = useState();

  const [collectionData, setCollectionData] = useState({
    name: "",
    external_link: "",
    category_id: "",
    website_link: "",
    discord_link: "",
    twitter_link: "",
    instagram_link: "",
    medium_link: "",
    telegram_link: "",
    description: "",
    royalty_percentage: "",
    collection_type: "",
    royalty_wallet_address: "",
  });

  const [logoImage, setLogoImage] = useState({
    file: "",
    preview_image: "",
  });

  const [coverImage, setCoverImage] = useState({
    file: "",
    preview_image: "",
  });

  const [deleteModalStatus, setDeleteModalStatus] = useState(false);

  const [validUser, setValidUser] = useState(null);

  const [loadingButtonContent, setLoadingButtonContent] = useState("");

  const createNftCollectionchema = Yup.object().shape({
    picture: Yup.mixed(),
    name: Yup.string().required("Collection name is required"),
    symbol: Yup.string().required("Symbol is required"),
    royalty_wallet_address: Yup.string().when("royalty_percentage", {
      is: (royalty_percentage) => Number(royalty_percentage) > 0,
      then: Yup.string().required("Royalty Wallet address is required"),
      otherwise: Yup.string(),
    }),
  });

  const handleNetworkSelect = (value) => {
    setNetworkSelect([value]);
  };

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setLogoImage({
        ...logoImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAddCoverImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setCoverImage({
        ...coverImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (values) => {
    const newData = {
      ...values,
      cover: coverImage.file,
      blockchain_type: networkSelect[0].id,
      contract_address: contract_address,
    };

    if (
      props.singleCollection.data.nft_collection.royalty_percentage !=
      values.royalty_percentage
    ) {
      changeRoyalityPercentage(newData);
    } else if (
      props.singleCollection.data.nft_collection.royalty_wallet_address !=
      values.royalty_wallet_address
    ) {
      changeRoyaltiesReceiver(newData);
    } else {
      props.dispatch(nftCollectionsSaveStart(newData));
    }
  };

  const handleSocialLinkChanges = (event) => {
    setCollectionData({
      ...collectionData,
      [event.target.name]: event.target.value,
    });
  };

  const handleRoyaltyChange = (event) => {
    setCollectionData({
      ...collectionData,
      royalty_percentage: isInt(Number(event.target.value))
        ? event.target.value
        : Number(event.target.value).toFixed(1),
    });
  };

  const handleCategorySelect = (value) => {
    setCollectionData({
      ...collectionData,
      category_id: value,
    });
  };

  useEffect(() => {
    if (!auth.loading && auth.accounts != "" && auth.authStatus) {
      props.dispatch(
        fetchSingleNftCollectionsStart({
          nft_collection_contract_address: contract_address,
        })
      );
    }
  }, [auth.authStatus]);

  useEffect(() => {
    if (
      !props.singleCollection.loading &&
      Object.keys(props.singleCollection.data).length > 0
    ) {
      setCollectionData({
        ...collectionData,
        name: props.singleCollection.data.nft_collection.name,
        symbol: props.singleCollection.data.nft_collection.symbol,
        external_link: props.singleCollection.data.nft_collection.external_link,
        category_id: props.singleCollection.data.nft_collection.category_id,
        website_link: props.singleCollection.data.nft_collection.website_link,
        discord_link: props.singleCollection.data.nft_collection.discord_link,
        twitter_link: props.singleCollection.data.nft_collection.twitter_link,
        instagram_link:
          props.singleCollection.data.nft_collection.instagram_link,
        medium_link: props.singleCollection.data.nft_collection.medium_link,
        telegram_link: props.singleCollection.data.nft_collection.telegram_link,
        description:
          props.singleCollection.data.nft_collection.description != null
            ? props.singleCollection.data.nft_collection.description
            : "",
        collection_type:
          props.singleCollection.data.nft_collection.collection_type,
        royalty_percentage:
          props.singleCollection.data.nft_collection.royalty_percentage == ""
            ? "0"
            : props.singleCollection.data.nft_collection.royalty_percentage,
        royalty_wallet_address:
          props.singleCollection.data.nft_collection.royalty_wallet_address,
        contract_address:
					props.singleCollection.data.nft_collection.contract_address,
				blockchain_type:
					props.singleCollection.data.nft_collection.blockchain_type,
				sign_transaction_hash:
					props.singleCollection.data.nft_collection.sign_transaction_hash,
      });
      setLogoImage({
        ...logoImage,
        preview_image: props.singleCollection.data.nft_collection.picture,
      });
      setCoverImage({
        ...coverImage,
        preview_image: props.singleCollection.data.nft_collection.cover,
      });
      setValidUser(
        auth.userId == props.singleCollection.data.nft_collection.user_id
      );
    }
  }, [props.singleCollection.data]);

  useEffect(() => {
    if (
      !props.collectionSave.loading &&
      Object.keys(props.collectionSave.data).length > 0
    ) {
      const delayDebounceFn = setTimeout(() => {
        history.push(`/assets/collection/${props.collectionSave.data.nft_collection_unique_id}`);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [props.collectionSave.data]);

  useEffect(() => {
    if (
      skipInitalRender &&
      collectionData.name != props.singleCollection.data.nft_collection.name
    ) {
      const delayDebounceFn = setTimeout(() => {
        props.dispatch(
          nftCollectionNameValidationStart({
            name: collectionData.name,
            nft_collection_contract_address: contract_address,
          })
        );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    setSkipInitalRender(true);
  }, [collectionData.name]);

  useEffect(() => {
    if (skipInitalRender && !props.collectionNameValidation.loading) {
      if (props.collectionNameValidation.data.code == 122) {
        setValidCollectionName(true);
      } else {
        setValidCollectionName(false);
      }
    }
  }, [props.collectionNameValidation.data]);

  const handleDeleteModal = (status) => {
    setDeleteModalStatus(status);
  };

  useEffect(() => {
    if (collectionData.royalty_percentage == "") {
      setCollectionData({
        ...collectionData,
        royalty_percentage: "",
        royalty_wallet_address: "",
      });
    }
  }, [collectionData.royalty_percentage]);

  const changeRoyalityPercentage = async (data) => {
    if (
			CHAIN_INFO[auth.chainId].nativeCurrency.symbol !=
			props.singleCollection.data.nft_collection.blockchain_type
		) {
			const notificationMessage = getErrorNotificationMessage(
				`Collection is not deployed in the detected network. Please switch to ${props.singleCollection.data.nft_collection.blockchain_type}`
			);
			props.dispatch(createNotification(notificationMessage));
			return false;
		}
    // NFT contract.
    setLoadingButtonContent("Initiated...");
    const web3 = window.web3;
    let nftContractData;
    let marketPlaceContractData;
    const NFTData = NftToken.networks[auth.chainId];
    if (NFTData) {
      try {
        setLoadingButtonContent((prevState) => "Connecting to Blockchain");
        console.log("Test true");
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          contract_address
        );

        nftContractData.methods
          .setRoyalityPercentage(String(Number(data.royalty_percentage) * 10))
          .send({ from: auth.accounts })
          .on("receipt", async (receipt) => {
            setLoadingButtonContent("");
            props.dispatch(nftCollectionsSaveStart(data));
          })
          .on("error", (error) => {
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage =
                "Unexpected error occuried, Please try again...";
            } else {
              notificationMessage = error.message;
            }
            setLoadingButtonContent("");
            // Send error notification.
          });
      } catch (error) {
        setLoadingButtonContent("");
        // Send fail notification.
        const notificationMessage = getErrorNotificationMessage(
          "Something went wrong. Please refresh the page and try again."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log(error);
      }
    } else {
      setLoadingButtonContent("");
      window.alert("NFT contract not deployed to detected network.");
    }
  };

  const changeRoyaltiesReceiver = async (data) => {
    if (
			CHAIN_INFO[auth.chainId].nativeCurrency.symbol !=
			props.singleCollection.data.nft_collection.blockchain_type
		) {
			const notificationMessage = getErrorNotificationMessage(
				`Collection is not deployed in the detected network. Please switch to ${props.singleCollection.data.nft_collection.blockchain_type}`
			);
			props.dispatch(createNotification(notificationMessage));
			return false;
		}
    // NFT contract.
    setLoadingButtonContent("Initiated...");
    const web3 = window.web3;
    let nftContractData;
    let marketPlaceContractData;
    const NFTData = NftToken.networks[auth.chainId];
    if (NFTData) {
      try {
        setLoadingButtonContent((prevState) => "Connecting to Blockchain");
        console.log("Test true");
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          contract_address
        );

        nftContractData.methods
          .setRoyaltiesReceiver(data.royalty_wallet_address)
          .send({ from: auth.accounts })
          .on("receipt", async (receipt) => {
            setLoadingButtonContent("");
            props.dispatch(nftCollectionsSaveStart(data));
          })
          .on("error", (error) => {
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage =
                "Unexpected error occuried, Please try again...";
            } else {
              notificationMessage = error.message;
            }
            setLoadingButtonContent("");
            // Send error notification.
          });
      } catch (error) {
        setLoadingButtonContent("");
        // Send fail notification.
        const notificationMessage = getErrorNotificationMessage(
          "Something went wrong. Please refresh the page and try again."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log(error);
      }
    } else {
      setLoadingButtonContent("");
      window.alert("NFT contract not deployed to detected network.");
    }
  };

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  return (
    <>
      {props.singleCollection.loading ? (
        <>
          <div className="collection-widget-area pages-padding">
            <div className="container">
              <div className="row g-0">
                <div className="col-lg-9 mx-auto">
                  <div className="collection-form-area">
                    <EditCollectionLoader />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : validUser ? (
        props.singleCollection.data.nft_collection ? (
          <>
            <div className="collection-widget-area pages-padding">
              <div className="container">
                <div className="row g-0">
                  <div className="col-lg-9 mx-auto">
                    <div className="collection-form-area">
                      <div className="collection-form no-shadow">
                        <div className="section-title">
                          <h2>Edit your collection</h2>
                        </div>
                        <Formik
                          initialValues={{
                            ...collectionData,
                            picture: logoImage.file,
                            cover: coverImage.file,
                          }}
                          className="w-100"
                          validationSchema={createNftCollectionchema}
                          onSubmit={(values) => handleSubmit(values)}
                          enableReinitialize
                        >
                          {({ errors, touched, setFieldValue, values }) => (
                            <>
                              <Form>
                                <div className="profile-outer">
                                  <h3>
                                    Logo image{" "}
                                    <span class="text-danger">*</span>
                                  </h3>
                                  <p>
                                    This image will also be used for navigation.
                                    350 x 350 recommended
                                  </p>

                                  <div className="profileButton custom-input">
                                    {logoImage.preview_image != "" && (
                                      <div className="preview-image">
                                        <img
                                          src={logoImage.preview_image}
                                          alt=""
                                        />
                                      </div>
                                    )}
                                    <input
                                      className="profileButton-input "
                                      type="file"
                                      name="logo"
                                      accept="image/*"
                                      id="upload"
                                      onChange={(event) =>
                                        handleAddImageChange(event)
                                      }
                                    />
                                    <label
                                      className="profileButton-button ripple-effect"
                                      htmlFor="upload"
                                    >
                                      <ImageIcon />
                                    </label>
                                  </div>
                                  <ErrorMessage
                                    component="div"
                                    name="picture"
                                    className="invalid-feedback mt-3"
                                  />
                                </div>
                                <div className="preview-box">
                                  <h3>Banner image </h3>
                                  <p>
                                    This image will appear at the top of your
                                    collection page. Aviod including too much
                                    text in this banner image, as the dimensions
                                    change on the devices. 1400 x 400
                                    recommended
                                  </p>

                                  <div className="previewButton custom-input-banner">
                                    {coverImage.preview_image != "" && (
                                      <div className="preview-image">
                                        <img
                                          src={coverImage.preview_image}
                                          alt=""
                                        />
                                      </div>
                                    )}
                                    <input
                                      className="previewButton-input"
                                      type="file"
                                      name="cover"
                                      accept="image/*"
                                      id="uploadCover"
                                      onChange={(event) =>
                                        handleAddCoverImageChange(event)
                                      }
                                    />
                                    <label
                                      className="previewButton-button ripple-effect"
                                      for="uploadCover"
                                    >
                                      <ImageIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div class="form-group ">
                                    <h5>
                                      Name <span class="text-danger">*</span>
                                    </h5>
                                    <div class="controls">
                                      <Field
                                        type="text"
                                        name="name"
                                        placeholder="Item name"
                                        className={`form-control ${
                                          touched.name && errors.name
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        value={collectionData.name}
                                        // onChange={(event) =>
                                        //   handleSocialLinkChanges(event)
                                        // }
                                        disabled
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="name"
                                        className="invalid-feedback mt-3"
                                      />
                                    </div>
                                    {validCollectionName != undefined &&
                                      validCollectionName && (
                                        <p className="text-success mt-3">
                                          Valid collection name
                                        </p>
                                      )}
                                    {validCollectionName != undefined &&
                                      !validCollectionName && (
                                        <p className="invalid-feedback mt-3">
                                          inValid collection name
                                        </p>
                                      )}
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div class="form-group ">
                                    <h5>
                                      Symbol <span class="text-danger">*</span>
                                    </h5>
                                    <div class="controls">
                                      <Field
                                        type="text"
                                        name="symbol"
                                        placeholder="Item symbol"
                                        className={`form-control ${
                                          touched.symbol && errors.symbol
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        value={collectionData.symbol}
                                        // onChange={(event) =>
                                        //   handleSocialLinkChanges(event)
                                        // }
                                        disabled
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="symbol"
                                        className="invalid-feedback mt-3"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div class="form-group ">
                                    <h5>Description</h5>
                                    <div class="controls">
                                      <textarea
                                        type="text"
                                        name="description"
                                        placeholder="Description"
                                        className={`form-control`}
                                        value={collectionData.description}
                                        onChange={(event) =>
                                          handleSocialLinkChanges(event)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <label>External Link </label>
                                    <p>
                                      Marketplace will include a link to this
                                      URL on this item's detial page, so that
                                      users can click to learn more about it.
                                      You are welcome to link to your own
                                      webpage with more details.
                                    </p>
                                    <input
                                      type="text"
                                      name="external_link"
                                      className="form-control"
                                      placeholder="http://yoursite.io/item/123"
                                      value={collectionData.external_link}
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="collection-category">
                                    <h3>Category</h3>
                                    <p>
                                      Adding a category will help make your item
                                      discoverable on Marketplace.
                                    </p>
                                    {categories.loading ? null : categories.data
                                        .categories.length > 0 ? (
                                      <ul>
                                        {categories.data.categories.map(
                                          (category) => (
                                            <li
                                              className={
                                                category.category_id ===
                                                collectionData.category_id
                                                  ? "active"
                                                  : ""
                                              }
                                            >
                                              <p
                                                onClick={() =>
                                                  handleCategorySelect(
                                                    category.category_id
                                                  )
                                                }
                                              >
                                                {category.name}
                                              </p>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : null}
                                  </div>
                                </div>
                                {/* <div className="col-lg-12 col-md-12 mb-4">
                                <div class="collection-details-list">
                                  <div class="collection-details-list-item">
                                    <div class="collection-details-list-content">
                                      <i
                                        class="Iconreact__Icon-sc-1gugx8q-0 irnoQt EventHistory--icon material-icons EventHistory--icon"
                                        value="child_friendly"
                                        size="24"
                                      >
                                        child_friendly
                                      </i>
                                      <div class="collection-details-list-main">
                                        <span class="collection-details-list-label">
                                          Lazy Minting
                                        </span>
                                        <p class="collection-details-list-header">
                                          Buyer will pay gas fees for minting.
                                        </p>
                                      </div>
                                    </div>
                                    <div class="collection-details-list-side">
                                      <Switch
                                        name="collection_type"
                                        value={
                                          collectionData.collection_type == "2"
                                            ? "1"
                                            : "2"
                                        }
                                        checked={
                                          collectionData.collection_type == "2"
                                        }
                                        onChange={(event) =>
                                          handleSocialLinkChanges(event)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                                {props.singleCollection.data.nft_collection
                                  .collection_type != 3 && (
                                  <>
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group">
                                        <label>Royalties</label>
                                        <p>
                                          Collect a fee when a user re-sells an
                                          item you originally created. This is
                                          deducted from the final sale price and
                                          paid monthly to a payout address of
                                          your choosing.
                                        </p>
                                        <input
                                          type="number"
                                          name="royalty_percentage"
                                          className="form-control"
                                          placeholder="E.g 4.5"
                                          min="0"
                                          step="any"
                                          value={
                                            collectionData.royalty_percentage
                                          }
                                          onChange={(event) =>
                                            handleRoyaltyChange(event)
                                          }
                                        />
                                      </div>
                                    </div>
                                    {Number(collectionData.royalty_percentage) >
                                      0 && (
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                          <label>
                                            Royalty Wallet Address{" "}
                                            <span class="text-danger">*</span>{" "}
                                          </label>
                                          <p>
                                            Royalty percentage will be sent to
                                            the provided wallet address.
                                          </p>
                                          <input
                                            type="text"
                                            name="royalty_wallet_address"
                                            className="form-control"
                                            placeholder="Wallet address"
                                            value={
                                              collectionData.royalty_wallet_address
                                            }
                                            onChange={(event) =>
                                              handleSocialLinkChanges(event)
                                            }
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="royalty_wallet_address"
                                            className="invalid-feedback mt-3"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}

                                <div className="collection-links">
                                  <h3>Links</h3>
                                  <InputGroup className="link-input">
                                    <InputGroup.Text
                                      id="basic-addon1 "
                                      className="border-top-left-radius"
                                    >
                                      <i class="fas fa-globe"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder="Yoursite.io"
                                      name="website_link"
                                      value={collectionData.website_link}
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                      className="border-top-right-radius"
                                    />
                                  </InputGroup>
                                  <InputGroup className="link-input">
                                    <InputGroup.Text id="basic-addon1">
                                      <i className="fab fa-discord"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder="https://discord.gg/abcdef"
                                      name="discord_link"
                                      value={collectionData.discord_link}
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                    />
                                  </InputGroup>
                                  <InputGroup className="link-input">
                                    <InputGroup.Text id="basic-addon1">
                                      <i class="fab fa-twitter"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder="https://twitter.com/YourTwitterHandle"
                                      name="twitter_link"
                                      value={collectionData.twitter_link}
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                    />
                                  </InputGroup>
                                  <InputGroup className="link-input">
                                    <InputGroup.Text id="basic-addon1">
                                      <i class="fab fa-instagram"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder="https://www.instagram.com/YourInstagramHandle"
                                      name="instagram_link"
                                      value={collectionData.instagram_link}
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                    />
                                  </InputGroup>
                                  <InputGroup className="link-input">
                                    <InputGroup.Text id="basic-addon1">
                                      <i class="fab fa-medium-m"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder="https://www.medium.com/@YourMediumHandle"
                                      name="medium_link"
                                      value={collectionData.medium_link}
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                    />
                                  </InputGroup>
                                  <InputGroup className="link-input">
                                    <InputGroup.Text id="basic-addon1">
                                      <i class="fab fa-telegram-plane"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder="https://t.me/abcdef"
                                      name="telegram_link"
                                      value={collectionData.telegram_link}
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                    />
                                  </InputGroup>
                                </div>
                                {/* <div className="network-selection">
                                <h3>Blockchain - Network</h3>
                                <CustomSelect
                                  value={networkSelect}
                                  handleChange={handleNetworkSelect}
                                  items={[
                                    { name: "Polygon", id: 1 },
                                    { name: "Eth", id: 2 },
                                    { name: "vtc", id: 3 },
                                  ]}
                                  placeHolder={"Network"}
                                  idBased
                                  nameField={"name"}
                                  valueField={"id"}
                                />
                              </div> */}

                                <div className="col-lg-12 col-md-12 create-btn">
                                  <div className="buttons-wrapper">
                                    <button
                                      type="submit"
                                      className="default-btn border-radius-5 px-5 py-3 mt-4"
                                      disabled={
                                        props.collectionSave.buttonDisable ||
                                        loadingButtonContent != ""
                                      }
                                    >
                                      {props.collectionSave
                                        .loadingButtonContent != ""
                                        ? props.collectionSave
                                            .loadingButtonContent
                                        : loadingButtonContent != ""
                                        ? loadingButtonContent
                                        : "Save Changes"}
                                    </button>
                                    {props.singleCollection.data.nft_collection.delete_status == 1 &&(
                                      <button
                                        type="button"
                                        className="default-btn border-radius-5 px-5 py-3 mt-4"
                                        onClick={() => handleDeleteModal(true)}
                                      >
                                        Delete Collection
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </Form>
                            </>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DeleteModal
              open={deleteModalStatus}
              handleDeleteModal={handleDeleteModal}
              singleCollection={props.singleCollection}
              type="collection"
            />
          </>
        ) : (
          props.singleCollection.error && <PageNotFound />
        )
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
  collectionSave: state.nfts.collectionSave,
  collectionNameValidation: state.nfts.collectionNameValidation,
  singleCollection: state.nfts.singleCollection,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditCollection);
