import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Container, Row, Col } from "react-bootstrap";
import CustomTooltip from "../../customComponents/CustomTooltip";
import configuration from "react-global-configuration";
import { useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";

const ListingItemLoader = (props) => {
  const { classes } = props;

  const theme = useTheme();

  return (
    <>
      <div className={`lisiting-item-loader ${classes ? classes : ""}`}>
        <SkeletonTheme
          baseColor={theme.loader.primary}
          highlightColor={theme.loader.secondary}
        >
          <div className="listing-header-item-sec">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="listing-header-sec">
                    <div className="listing-product-item">
                      <Skeleton count={1} width={48} height={48} />
                      <div className="listing-user-info">
                        <Skeleton count={1} width={40} height={20} />
                        <Skeleton count={1} width={60} height={23} />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="listing-item-sec">
            <Container>
              <Row className="justify-content-md-center">
                <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
                  <h2>List item for sale</h2>
                  <div className="loader-price-section">
                    <h6>Price</h6>
                    <CustomTooltip
                      title={
                        "List price and listing schedule cannot be edited once the item is listed. You will need to cancel your listing and relist the item with the updated price and dates."
                      }
                      placement="top"
                    >
                      <i className="fas fa-info-circle"></i>
                    </CustomTooltip>
                  </div>

                  <div className="listing-item-loader-inputs">
                    <Skeleton
                      count={1}
                      width={"100%"}
                      height={50}
                      borderRadius={5}
                    />
                    <Skeleton
                      count={1}
                      width={"100%"}
                      height={50}
                      borderRadius={5}
                    />
                  </div>
                  <div className="loader-price-section mt-4">
                    <h6>Duration</h6>
                    <CustomTooltip
                      title={
                        "List price and listing schedule cannot be edited once the item is listed. You will need to cancel your listing and relist the item with the updated price and dates."
                      }
                      placement="top"
                    >
                      <i className="fas fa-info-circle"></i>
                    </CustomTooltip>
                  </div>
                  <Skeleton
                    count={1}
                    width={"100%"}
                    height={50}
                    borderRadius={5}
                  />
                  <div className="mt-4">
                    <Skeleton count={1} width={"100%"} height={1} />
                  </div>

                  <div className="listing-fee-sec">
                    <div className="listing-fee-card">
                      <h4>Fees</h4>
                      <CustomTooltip
                        title={
                          "Listing is free. Once sold, the following fees will be deducted."
                        }
                        placement="top"
                      >
                        <i className="fas fa-info-circle"></i>
                      </CustomTooltip>
                    </div>
                    <div className="listing-fee-card">
                      <p>Service Fee</p>
                      <p>{configuration.get("configData.admin_commission")}%</p>
                    </div>
                  </div>
                  <div className="listing-btn-sec">
                    <div className="custom-checkbox mb-4">
                      <div class="check-field">
                        <input id="my-check" type="checkbox" />
                        <label for="my-check">
                          I agree to the following {" "}
                          <Link to="/page/terms" target="_blank">terms & conditions</Link>
                        </label>
                      </div>
                    </div>
                    <Skeleton count={1} width={180} height={47} />
                  </div>
                </Col>
                <Col
                  xs={{ span: 12, order: 1 }}
                  md={{ span: 6, order: 2 }}
                  className="mb-4 mb-lg-0 mt-md-4"
                >
                  <Row className="justify-content-md-center">
                    <Col md={9}>
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={"500px"}
                        borderRadius={"1rem"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </SkeletonTheme>
      </div>
    </>
  );
};

export default ListingItemLoader;
