import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./NewLanding.css";
import { Link } from "react-router-dom";

const DiscoverConnect = (props) => {


    return (
        <>
            <section className="discover-connect-sec">
                <Container>
                    <div className="discover-connect-box">
                        <Row className="align-items-center">
                            <Col md={7} className="resp-mrg-btm-xs">
                                <div className="discover-connect-info">
                                    <div className="discover-connect-logo-sec">
                                        <Image
                                          className="discover-connect-logo"
                                          src={
                                            window.location.origin + "/assets/images/new-landing/efi-logo.png"
                                          }
                                        />
                                        <h2>Discover, Collect and <span>Sell Extraordinary NFT's</span></h2>
                                        <div className="discover-connect-btn">
                                            <Link
                                                to={"/create-new-item"}
                                                className="default-btn"
                                            >
                                                Create Item
                                            </Link>
                                            <Link to={"/explore"} className="explore-btn">
                                                Explore Now
                                            </Link>
                                        </div>
                                    </div>
                                </div> 
                            </Col>
                            <Col md={5}>
                                <div className="discover-connect-img-sec">
                                    <Image
                                        className="discover-connect-img"
                                        src={
                                        window.location.origin + "/assets/images/new-landing/discover-connect.png"
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default DiscoverConnect;
