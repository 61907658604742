import React, { useContext, useState, useEffect } from "react";
import { authContext } from "../authprovider/AuthProvider";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { styled, alpha } from "@material-ui/core/styles";
import { KeyboardArrowDown } from "@material-ui/icons";
import { CHAIN_INFO, SupportedChainId } from "../authprovider/chains";
import { Button, Menu, MenuItem } from "@material-ui/core";

const ChainType = (props) => {

  const { auth, setAuth } = useContext(authContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      top: "50px !important",
      color: "rgb(55, 65, 81)",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNetworkDropClose = () => {
    setAnchorEl(null);
  };

  const handleNetworkChange = (chain) => {
    handleNetworkDropClose()
    setAuth({
      ...auth,
      chainId: chain
    })
  }
  return (
    <>
      <Button
        id="network-change-button"
        aria-controls={open ? "network-change-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        // endIcon={<KeyboardArrowDown />}
      >
        <img src={CHAIN_INFO[auth.chainId].logo} alt="" />
        {CHAIN_INFO[auth.chainId].nativeCurrency.name}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "network-change-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleNetworkDropClose}
      >
        <div className="network-dropdown-title">
          <h5 className="mb-0 text-muted">Select a network</h5>
        </div>
        {Object.entries(SupportedChainId).map((chain, index) => (
          <MenuItem onClick={() => handleNetworkChange(chain[1])} disableRipple key={index}>
            <div className="chains-item">
              <img
                src={
                  CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                    .logo
                }
                alt=""
              />
              <h5>
                {
                  CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                    .nativeCurrency.name
                }
              </h5>
            </div>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

const mapStateToPros = (state) => ({
 
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChainType);
