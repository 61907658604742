import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

const LandingFooter = (props) => {


  return (
    <>
      <footer className="new-footer-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={4}>
              <div className="new-footer-wizard">
                <Image
                  className="new-footer-logo"
                  src={configuration.get("configData.site_logo")}
                />
                <p>{configuration.get("configData.footer_description")}</p>
              </div>
            </Col>
            <Col md={2} className="resp-mrg-btm-xs">
              <div className="footer-wizard">
                <h3>Resources</h3>
                <ul className="list-unstyled footer-list-sec">
                  <li>
                    <Link to="/page/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/page/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={2} className="resp-mrg-btm-xs">
              <div className="footer-wizard">
                <h3>Legal</h3>
                <ul className="list-unstyled footer-list-sec">
                  <li>
                    <Link to="/page/privacy">
                      Privacy
                    </Link>
                  </li>
                  <li>
                    <Link to="/page/terms">
                      Terms
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={2}>
              <div className="footer-wizard">
                <h3>Company</h3>
                <ul className="list-unstyled footer-list-sec">
                  {configuration
                    .get("configData.footer_pages2")
                    .map((static_page, index) => (
                      <li key={index}>
                        <Link to={`/page/${static_page.static_page_unique_id}`}>
                          {static_page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="copyright-text-card">
        <p>{configuration.get("configData.copyright_content")}</p>
      </div>
    </>
  );
};

export default LandingFooter;
