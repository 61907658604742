export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

export const FETCH_COLLECTIONS_LIST_START = "FETCH_COLLECTIONS_LIST_START";
export const FETCH_COLLECTIONS_LIST_SUCCESS = "FETCH_COLLECTIONS_LIST_SUCCESS";
export const FETCH_COLLECTIONS_LIST_FAILURE = "FETCH_COLLECTIONS_LIST_FAILURE";

export const FETCH_SINGLE_COLLECTIONS_START = "FETCH_SINGLE_COLLECTIONS_START";
export const FETCH_SINGLE_COLLECTIONS_SUCCESS = "FETCH_SINGLE_COLLECTIONS_SUCCESS";
export const FETCH_SINGLE_COLLECTIONS_FAILURE = "FETCH_SINGLE_COLLECTIONS_FAILURE";

export const NFT_COLLECTIONS_SAVE_START = "NFT_COLLECTIONS_SAVE_START";
export const NFT_COLLECTIONS_SAVE_SUCCESS = "NFT_COLLECTIONS_SAVE_SUCCESS";
export const NFT_COLLECTIONS_SAVE_FAILURE = "NFT_COLLECTIONS_SAVE_FAILURE";

export const NFT_COLLECTIONS_DELETE_START = "NFT_COLLECTIONS_DELETE_START";
export const NFT_COLLECTIONS_DELETE_SUCCESS = "NFT_COLLECTIONS_DELETE_SUCCESS";
export const NFT_COLLECTIONS_DELETE_FAILURE = "NFT_COLLECTIONS_DELETE_FAILURE";

export const FETCH_COLLECTIONS_LIST_FOR_OTHERS_START = "FETCH_COLLECTIONS_LIST_FOR_OTHERS_START";
export const FETCH_COLLECTIONS_LIST_FOR_OTHERS_SUCCESS = "FETCH_COLLECTIONS_LIST_FOR_OTHERS_SUCCESS";
export const FETCH_COLLECTIONS_LIST_FOR_OTHERS_FAILURE = "FETCH_COLLECTIONS_LIST_FOR_OTHERS_FAILURE";

export const FETCH_NFT_ITEMS_LIST_START = "FETCH_NFT_ITEMS_LIST_START";
export const FETCH_NFT_ITEMS_LIST_SUCCESS = "FETCH_NFT_ITEMS_LIST_SUCCESS";
export const FETCH_NFT_ITEMS_LIST_FAILURE = "FETCH_NFT_ITEMS_LIST_FAILURE";

export const FETCH_SINGLE_NFT_ITEMS_START = "FETCH_SINGLE_NFT_ITEMS_START";
export const FETCH_SINGLE_NFT_ITEMS_SUCCESS = "FETCH_SINGLE_NFT_ITEMS_SUCCESS";
export const FETCH_SINGLE_NFT_ITEMS_FAILURE = "FETCH_SINGLE_NFT_ITEMS_FAILURE";

export const NFT_ITEMS_SAVE_START = "NFT_ITEMS_SAVE_START";
export const NFT_ITEMS_SAVE_SUCCESS = "NFT_ITEMS_SAVE_SUCCESS";
export const NFT_ITEMS_SAVE_FAILURE = "NFT_ITEMS_SAVE_FAILURE";

export const NFT_ITEMS_DELETE_START = "NFT_ITEMS_DELETE_START";
export const NFT_ITEMS_DELETE_SUCCESS = "NFT_ITEMS_DELETE_SUCCESS";
export const NFT_ITEMS_DELETE_FAILURE = "NFT_ITEMS_DELETE_FAILURE";

export const FETCH_NFT_PROPERTIES_LIST_START = "FETCH_NFT_PROPERTIES_LIST_START";
export const FETCH_NFT_PROPERTIES_LIST_SUCCESS = "FETCH_NFT_PROPERTIES_LIST_SUCCESS";
export const FETCH_NFT_PROPERTIES_LIST_FAILURE = "FETCH_NFT_PROPERTIES_LIST_FAILURE";

export const FETCH_NFT_FAVOURITES_LIST_START = "FETCH_NFT_FAVOURITES_LIST_START";
export const FETCH_NFT_FAVOURITES_LIST_SUCCESS = "FETCH_NFT_FAVOURITES_LIST_SUCCESS";
export const FETCH_NFT_FAVOURITES_LIST_FAILURE = "FETCH_NFT_FAVOURITES_LIST_FAILURE";

export const NFT_FAVOURITES_SAVE_START = "NFT_FAVOURITES_SAVE_START";
export const NFT_FAVOURITES_SAVE_SUCCESS = "NFT_FAVOURITES_SAVE_SUCCESS";
export const NFT_FAVOURITES_SAVE_FAILURE = "NFT_FAVOURITES_SAVE_FAILURE";

export const FETCH_NFT_CATEGORIES_LIST_START = "FETCH_NFT_CATEGORIES_LIST_START";
export const FETCH_NFT_CATEGORIES_LIST_SUCCESS = "FETCH_NFT_CATEGORIES_LIST_SUCCESS";
export const FETCH_NFT_CATEGORIES_LIST_FAILURE = "FETCH_NFT_CATEGORIES_LIST_FAILURE";

export const FETCH_REPORT_ITEMS_LIST_START = "FETCH_REPORT_ITEMS_LIST_START";
export const FETCH_REPORT_ITEMS_LIST_SUCCESS = "FETCH_REPORT_ITEMS_LIST_SUCCESS";
export const FETCH_REPORT_ITEMS_LIST_FAILURE = "FETCH_REPORT_ITEMS_LIST_FAILURE";

export const SAVE_REPORT_ITEMS_START = "SAVE_REPORT_ITEMS_START";
export const SAVE_REPORT_ITEMS_SUCCESS = "SAVE_REPORT_ITEMS_SUCCESS";
export const SAVE_REPORT_ITEMS_FAILURE = "SAVE_REPORT_ITEMS_FAILURE";

export const FETCH_NFT_ITEMS_PAYMENT_LIST_START = "FETCH_NFT_ITEMS_PAYMENT_LIST_START";
export const FETCH_NFT_ITEMS_PAYMENT_LIST_SUCCESS = "FETCH_NFT_ITEMS_PAYMENT_LIST_SUCCESS";
export const FETCH_NFT_ITEMS_PAYMENT_LIST_FAILURE = "FETCH_NFT_ITEMS_PAYMENT_LIST_FAILURE";

export const FETCH_TRENDING_NFT_ITEMS_START = "FETCH_TRENDING_NFT_ITEMS_START";
export const FETCH_TRENDING_NFT_ITEMS_SUCCESS = "FETCH_TRENDING_NFT_ITEMS_SUCCESS";
export const FETCH_TRENDING_NFT_ITEMS_FAILURE = "FETCH_TRENDING_NFT_ITEMS_FAILURE";

export const SELL_NFT_ITEMS_START = "SELL_NFT_ITEMS_START";
export const SELL_NFT_ITEMS_SUCCESS = "SELL_NFT_ITEMS_SUCCESS";
export const SELL_NFT_ITEMS_FAILURE = "SELL_NFT_ITEMS_FAILURE";

export const CANCEL_LIST_NFT_ITEMS_START = "CANCEL_LIST_NFT_ITEMS_START";
export const CANCEL_LIST_NFT_ITEMS_SUCCESS = "CANCEL_LIST_NFT_ITEMS_SUCCESS";
export const CANCEL_LIST_NFT_ITEMS_FAILURE = "CANCEL_LIST_NFT_ITEMS_FAILURE";

export const BUY_NFT_ITEMS_START = "BUY_NFT_ITEMS_START";
export const BUY_NFT_ITEMS_SUCCESS = "BUY_NFT_ITEMS_SUCCESS";
export const BUY_NFT_ITEMS_FAILURE = "BUY_NFT_ITEMS_FAILURE";

export const NFT_COLLECTIONS_NAME_VALIDATION_START = "NFT_COLLECTIONS_NAME_VALIDATION_START";
export const NFT_COLLECTIONS_NAME_VALIDATION_SUCCESS = "NFT_COLLECTIONS_NAME_VALIDATION_SUCCESS";
export const NFT_COLLECTIONS_NAME_VALIDATION_FAILURE = "NFT_COLLECTIONS_NAME_VALIDATION_FAILURE";

export const NFT_USER_PROFILE_ITEMS_LIST_START = "NFT_USER_PROFILE_ITEMS_LIST_START";
export const NFT_USER_PROFILE_ITEMS_LIST_SUCCESS = "NFT_USER_PROFILE_ITEMS_LIST_SUCCESS";
export const NFT_USER_PROFILE_ITEMS_LIST_FAILURE = "NFT_USER_PROFILE_ITEMS_LIST_FAILURE";

export const NFT_SEARCH_START = "NFT_SEARCH_START";
export const NFT_SEARCH_SUCCESS = "NFT_SEARCH_SUCCESS";
export const NFT_SEARCH_FAILURE = "NFT_SEARCH_FAILURE";

export const NFT_ITEM_FAVOURITE_USERS_LIST_START = "NFT_ITEM_FAVOURITE_USERS_LIST_START";
export const NFT_ITEM_FAVOURITE_USERS_LIST_SUCCESS = "NFT_ITEM_FAVOURITE_USERS_LIST_SUCCESS";
export const NFT_ITEM_FAVOURITE_USERS_LIST_FAILURE = "NFT_ITEM_FAVOURITE_USERS_LIST_FAILURE";

export const FETCH_OTHER_USER_DETAILS_START = "FETCH_OTHER_USER_DETAILS_START";
export const FETCH_OTHER_USER_DETAILS_SUCCESS = "FETCH_OTHER_USER_DETAILS_SUCCESS";
export const FETCH_OTHER_USER_DETAILS_FAILURE = "FETCH_OTHER_USER_DETAILS_FAILURE";

export const CLEAR_NFT_COLLECTIONS_SAVE = "CLEAR_NFT_COLLECTIONS_SAVE";

export const CLEAR_NFT_COLLECTION_ITEM_SAVE = "CLEAR_NFT_COLLECTION_ITEM_SAVE";

export const LOAD_MORE_FETCH_NFT_ITEMS_LIST_START = "LOAD_MORE_FETCH_NFT_ITEMS_LIST_START"
export const LOAD_MORE_FETCH_NFT_ITEMS_LIST_SUCCESS = "LOAD_MORE_FETCH_NFT_ITEMS_LIST_SUCCESS"
export const LOAD_MORE_FETCH_NFT_ITEMS_LIST_FAILURE = "LOAD_MORE_FETCH_NFT_ITEMS_LIST_FAILURE"

export const LOAD_MORE_USER_PROFILE_ITEMS_LIST_START = "LOAD_MORE_USER_PROFILE_ITEMS_LIST_START"
export const LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS = "LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS"
export const LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE = "LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE"

export const LOAD_MORE_ITEM_ACTIVITY_START = "LOAD_MORE_ITEM_ACTIVITY_START"
export const LOAD_MORE_ITEM_ACTIVITY_SUCCESS = "LOAD_MORE_ITEM_ACTIVITY_SUCCESS"
export const LOAD_MORE_ITEM_ACTIVITY_FAILURE = "LOAD_MORE_ITEM_ACTIVITY_FAILURE"

export const CLEAR_NFT_SINGLE_ITEM_SELL = "CLEAR_NFT_SINGLE_ITEM_SELL";
export const CLEAR_CANCEL_LISTING_DATA = "CLEAR_CANCEL_LISTING_DATA";
export const CLEAR_NFT_BUY_DATA = "CLEAR_NFT_BUY_DATA";
export const CLEAR_COLLECTION_DELETE_DATA = "CLEAR_COLLECTION_DELETE_DATA";
export const CLEAR_NFT_COLLECTION_LIST = "CLEAR_NFT_COLLECTION_LIST";


export const IMPORT_CONTRATCT_ADDRESS_START = "IMPORT_CONTRATCT_ADDRESS_START"
export const IMPORT_CONTRATCT_ADDRESS_SUCCESS = "IMPORT_CONTRATCT_ADDRESS_SUCCESS"
export const IMPORT_CONTRATCT_ADDRESS_FAILURE = "IMPORT_CONTRATCT_ADDRESS_FAILURE"

export const CLEAR_IMPORT_CONTRACT_ADDRESS_DATA = "CLEAR_IMPORT_CONTRACT_ADDRESS_DATA"

export const CLEAR_SEARCH_DATA = "CLEAR_SEARCH_DATA";


export const TRANSFER_NFT_START  = "TRANSFER_NFT_START"
export const TRANSFER_NFT_SUCCESS  = "TRANSFER_NFT_SUCCESS"
export const TRANSFER_NFT_FAILURE  = "TRANSFER_NFT_FAILURE"

export const CLEAR_TRANSFER_NFT_DATA = "CLEAR_TRANSFER_NFT_DATA";

export const CLEAR_SINGLE_NFT_IMAGE_DATA = "CLEAR_SINGLE_NFT_IMAGE_DATA"

export const BID_OFFER_NFT_ITEMS_SAVE_START = "BID_OFFER_NFT_ITEMS_SAVE_START";
export const BID_OFFER_NFT_ITEMS_SAVE_SUCCESS = "BID_OFFER_NFT_ITEMS_SAVE_SUCCESS";
export const BID_OFFER_NFT_ITEMS_SAVE_FAILURE = "BID_OFFER_NFT_ITEMS_SAVE_FAILURE";

export const BID_OFFER_NFT_ITEMS_LIST_START = "BID_OFFER_NFT_ITEMS_LIST_START";
export const BID_OFFER_NFT_ITEMS_LIST_SUCCESS = "BID_OFFER_NFT_ITEMS_LIST_SUCCESS";
export const BID_OFFER_NFT_ITEMS_LIST_FAILURE = "BID_OFFER_NFT_ITEMS_LIST_FAILURE";

export const ACCEPT_OFFER_NFT_ITEMS_START = "ACCEPT_OFFER_NFT_ITEMS_START";
export const ACCEPT_OFFER_NFT_ITEMS_SUCCESS = "ACCEPT_OFFER_NFT_ITEMS_SUCCESS";
export const ACCEPT_OFFER_NFT_ITEMS_FAILURE = "ACCEPT_OFFER_NFT_ITEMS_FAILURE";

export const UPDATE_SIGN_APPROVE_START = "UPDATE_SIGN_APPROVE_START"
export const UPDATE_SIGN_APPROVE_SUCCESS = "UPDATE_SIGN_APPROVE_SUCCESS"
export const UPDATE_SIGN_APPROVE_FAILURE = "UPDATE_SIGN_APPROVE_FAILURE"

export const CANCEL_BID_OFFER_NFT_ITEM_START = "CANCEL_BID_OFFER_NFT_ITEM_START";
export const CANCEL_BID_OFFER_NFT_ITEM_SUCCESS = "CANCEL_BID_OFFER_NFT_ITEM_SUCCESS";
export const CANCEL_BID_OFFER_NFT_ITEM_FAILURE = "CANCEL_BID_OFFER_NFT_ITEM_FAILURE";

export const LOAD_MORE_OFFER_LIST_START = "LOAD_MORE_OFFER_LIST_START"
export const LOAD_MORE_OFFER_LIST_SUCCESS = "LOAD_MORE_OFFER_LIST_SUCCESS"
export const LOAD_MORE_OFFER_LIST_FAILURE = "LOAD_MORE_OFFER_LIST_FAILURE"


export const MORE_FROM_COLLECTION_START = "MORE_FROM_COLLECTION_START"
export const MORE_FROM_COLLECTION_SUCCESS = "MORE_FROM_COLLECTION_SUCCESS"
export const MORE_FROM_COLLECTION_FAILURE = "MORE_FROM_COLLECTION_FAILURE"