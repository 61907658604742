import React, { useEffect , useState} from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const CancelOfferModal = (props) => {
  const { cancelOfferBtnContent, setCancelOfferBtnContent } = props;

	const [termsAgreed , setTermsAgreed] = useState(false)

  useEffect(() => {
    if (
      !props.cancelOffer.loading &&
      props.cancelOffer.data.code == 128 &&
      props.singleNftImage.nft_collection_item
    ) {
      setCancelOfferBtnContent("");
      props.getCurrentOwner();
      props.handleCancelOfferClose();
    }
  }, [props.cancelOffer.data]);

	const handleTermsChange = () => {
    setTermsAgreed(prev => !prev)
  }

  return (
    <>
      <Modal
        show={props.open}
        onHide={props.handleCancelOfferClose}
        animation={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="custom-modal listed-item-modal"
        centered
      >
        <Modal.Header>
          <div className="modal-header-wrapper">
            <h3 className="mb-0 modal-heading-text">
              Are you sure you want to cancel your offer?
            </h3>
            <div
              className="custom-close"
              onClick={() => props.handleCancelOfferClose()}
            >
              <CloseIcon />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-checkbox w-100 mb-3">
            <div class="check-field">
              <input
                id="my-check"
                type="checkbox"
                checked={termsAgreed}
                onChange={() => handleTermsChange()}
              />
              <label for="my-check">
                I agree to the following{" "}
                <Link to="/page/terms" target="_blank">terms & conditions</Link>
              </label>
            </div>
          </div>
          <div className="cancel-listing-btn-sec">
            <button
              className="default-btn border-radius-5"
							disabled={!termsAgreed}
              onClick={() => props.cancelOfferSave(props.cancelOfferInput)}
            >
              {cancelOfferBtnContent != "" ? cancelOfferBtnContent : "Confirm"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  cancelOffer: state.nfts.cancelBidOfferNftItem,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CancelOfferModal);
