import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Modal, Image, Media } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close';
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import Web3 from "web3";
import NftToken from "../../abis/NftToken.json";
import { authContext } from "../authprovider/AuthProvider";
import CustomAccordion from "../../customComponents/CustomAccordion";
import { updateSignAndApproveStart } from "../../store/actions/UserAction";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import { CHAIN_INFO } from "../authprovider/chains";

const AcceptOfferModal = (props) => {

	const { acceptOfferBtnContent, setAcceptOfferBtnContent } = props;

	const [completeListingModal, setCompleteListingModal] = useState(false);

  const [termsAgreed , setTermsAgreed] = useState(false)

	const [approveData, setApproveData] = useState({
    signature: null,
    status: false,
    loading: false,
    loadingContent: null,
		activeAccordion: "1",
  });

	const { auth } = useContext(authContext);

	// We need to get approval for the NFT. NFT contract should get the approval to sell the NFT.
  const getApprovalToSale = async () => {
    if (CHAIN_INFO[auth.chainId].nativeCurrency.symbol != props.singleNftImage.nft_collection.blockchain_type) {
			const notificationMessage = getErrorNotificationMessage(
				`Collection is not deployed in the detected network. Please switch to ${props.singleNftImage.nft_collection.blockchain_type}`
			);
			props.dispatch(createNotification(notificationMessage));
			return false;
		}
    // NFT contract.
    setApproveData({
      ...approveData,
      loading: true,
      loadingContent: "Loading...",
    });
    const web3 = window.web3;
    let nftContractData;
    const NFTData = NftToken.networks[auth.chainId];
    if (NFTData) {
      try {
        console.log("Test true");
        console.log("sellerContractAddress", auth.sellerContractAddress);
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          props.singleNftImage.nft_collection.contract_address
        );
        console.log("Test next");

        nftContractData.methods
          .setApprovalForAll(auth.sellerContractAddress, true)
          .send({ from: auth.accounts })
          .on("receipt", async (receipt) => {
            props.dispatch(
              updateSignAndApproveStart({
                is_approved: 1,
                nft_collection_unique_id: props.singleNftImage.nft_collection.nft_collection_unique_id,
              })
            );
						setApproveData({
							...approveData,
							status: true,
							loading: false,
							loadingContent: null,
							activeAccordion: "2",
						});
						props.setApproveOfferStatus("accept")
          })
          .on("error", (error) => {
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage =
                "Unexpected error occuried, Please try again...";
            } else {
              notificationMessage = error.message;
            }
            setApproveData({
							...approveData,
							status: true,
							loading: false,
							loadingContent: null,
						});
            // Send error notification.
          });
      } catch (error) {
        setApproveData({
					...approveData,
					status: true,
					loading: false,
					loadingContent: null,
				});
        // Send fail notification.
        const notificationMessage = getErrorNotificationMessage(
          "Something went wrong. Please refresh the page and try again."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log(error);
      }
    } else {
      setApproveData({
				...approveData,
				status: true,
				loading: false,
				loadingContent: null,
			});
      window.alert("NFT contract not deployed to detected network.");
    }
  };

	useEffect(() => {
    if (
      !props.acceptOffer.loading &&
      props.acceptOffer.data.code == 125 &&
      props.singleNftImage.nft_collection_item
    ) {
      props.setApproveOfferStatus("completed");
      
    }
		setAcceptOfferBtnContent("");
  }, [props.acceptOffer.data]);

	const closeAcceptOffer = async () => {
    props.handleAcceptOfferClose()
    props.getCurrentOwner()
  };

  const handleTermsChange = () => {
    setTermsAgreed(prev => !prev)
  }

    return (
			<>
				<Modal show={props.open} onHide={props.handleAcceptOfferClose} animation={false}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					className="custom-modal listed-item-modal"
					centered>
					{props.approveOfferStatus == "confirm" ? (
						<>
							<Modal.Header>
									<div className="modal-header-wrapper">
											<h3 className="mb-0 modal-heading-text">Are you sure?</h3>
											<div className="custom-close" onClick={() => props.handleAcceptOfferClose()}>
													<CloseIcon />
											</div>
									</div>
							</Modal.Header>
							<Modal.Body>
									<div className="accept-offer-card">
											<img
													src={
													window.location.origin +
													"/assets/images/icons/warning.png"
													}
													alt="Images"
											/>
											<p>You're about to accept this offer for {props.acceptOfferInput.amount} {props.singleNftImage.nft_collection.blockchain_type}</p>
									</div>
                  <div className="custom-checkbox mb-4 text-center mt-3">
                    <div class="check-field">
                      <input id="my-check" type="checkbox" checked={termsAgreed} onChange={() => handleTermsChange()}/>
                      <label for="my-check">
                        I agree to the following{" "}
                        <Link to="/page/terms" target="_blank">terms & conditions</Link>
                      </label>
                    </div>
                  </div>
							</Modal.Body>
							<Modal.Footer>
									<div className="accept-offer-btn-sec">
											<Link to="#" onClick={() => props.handleAcceptOfferClose()} className="default-btn two border-radius-5 btn btn-primary mr-2">
													Cancel
											</Link>
											<button 
                        type="button"
                        disabled={!termsAgreed}
                        onClick={() => 
                          props.setApproveOfferStatus(
                            props.singleNftImage.nft_collection_item.user_approved_status == 1 ? "accept" : "approve"
                          )
                        } 
                        className="default-btn border-radius-5">
													Accept offer
											</button>
									</div>
							</Modal.Footer>
						</>
					) : props.approveOfferStatus == "approve" ? (
						<>
							<Modal.Header>
								<div className="modal-header-wrapper">
										<i
												className="fas fa-arrow-left" onClick={() => props.setApproveOfferStatus("confirm")}
										></i>
										<h3 className="mb-0 modal-heading-text">Approve this collection</h3>
										<div
												className="custom-close"
												onClick={() => props.handleAcceptOfferClose()}
										>
												<CloseIcon />
										</div>
								</div>
							</Modal.Header>
							<Modal.Body>
								<CustomAccordion
                    activeId="1"
                    accordionHeading={"Approve"}
                    accordionKey={"1"}
                    accordionContent={
                      "Approve the NFT Collection. This only needs to be done once."
                    }
                    accordionButton={true}
                    OnClick={() => getApprovalToSale()}
                    accordionButtonDisable={
                      approveData.loading ||
                      approveData.loadingContent != null
                    }
                    accordionButtonContent={
                      approveData.loadingContent != null
                        ? approveData.loadingContent
                        : "Approve"
                    }
                    spinner
                    spinnerLoading={
                      approveData.loadingContent != null &&
                      approveData.activeAccordion === "1"
                    }
                    activeAccordion={approveData.activeAccordion}
                    loadingComplete={
                      Number(approveData.activeAccordion) > Number("1")
                    }
                  />
							</Modal.Body>
						</>
					) : props.approveOfferStatus == "accept" ? (
						<>
							<Modal.Header>
								<div className="modal-header-wrapper">
										<i
												className="fas fa-arrow-left" onClick={() => props.setApproveOfferStatus("confirm")}
										></i>
										<h3 className="mb-0 modal-heading-text">Accept this offer</h3>
										<div
												className="custom-close"
												onClick={() => props.handleAcceptOfferClose()}
										>
												<CloseIcon />
										</div>
								</div>
							</Modal.Header>
							<Modal.Body>
								<div className="accept-this-offer-sec">
										<div className="accept-this-offer-header">
												<h6>Item</h6>
												<h6>Subtotal</h6>
										</div>
										<div className="accept-this-offer-body">
												<div className="accept-this-offer-left">
														<Image
																src={props.singleNftImage.nft_collection_item.picture}
																alt="Image"
																className="user-profile-img"
														/>
														<div className="complete-listing-info">
																<Link to="#">{props.singleNftImage.nft_collection.name}</Link>
																<h4>{props.singleNftImage.nft_collection_item.name}</h4>
														</div>
												</div>
												<div className="accept-this-offer-right">
														<h4>{props.acceptOfferInput.amount}</h4>
														{/* <p>$0.02 USD</p> */}
												</div>
										</div>
										<div className="accept-this-offer-fee-sec">
												<div className="accept-this-offer-fee-header">
														<h4>Fees</h4>
												</div>
												<div className="accept-this-offer-fee-card">
														<p className="accept-this-border">Service Fee</p>
														<p>
															{configuration.get("configData.admin_commission")} %
														</p>
												</div>
												{props.singleNftImage.nft_collection
													.royalty_percentage > 0 && (
														<div className="accept-this-offer-fee-card">
															<p className="accept-this-border">Royalty Percentage</p>
															<p>
															{
																	props.singleNftImage.nft_collection
																		.royalty_percentage
																}{" "} %
															</p>
													</div>
											)}
										</div>
										<div className="accept-this-offer-bottom">
												<div className="accept-this-offer-left">
														<h4>Total Earnings</h4>
												</div>
												<div className="accept-this-offer-right">
														<h4>{props.acceptOfferInput.amount}</h4>
														{/* <p>($1.95)</p> */}
												</div>
										</div>
								</div>
							</Modal.Body>
							<Modal.Footer>
								<div className="accept-offer-btn-sec">
									<Link to="#" onClick={() => props.approveOfferSave(props.acceptOfferInput)} className="default-btn border-radius-5">
											{acceptOfferBtnContent != "" ? acceptOfferBtnContent : "Accept"}
									</Link>
								</div>
							</Modal.Footer>
						</>
					) : (
						<>
              <Modal.Header>
                <div className="modal-header-wrapper">
                  <h3 className="mb-0 modal-heading-text">
                    Offer Accepted for the item
                  </h3>
                  <div
                    className="custom-close"
                    onClick={() => props.handleAcceptOfferClose()}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="item-listed-sale-sec">
                  <Image
                    src={props.singleNftImage.nft_collection_item.picture}
                    alt="Image"
                    className="user-profile-img"
                  />
                  <div className="item-listed-sale-status-sec">
                    <div className="item-listed-sale-card">
                      <h6>Status</h6>
                    </div>
                    <div className="item-listed-sale-card">
                      <ul className="item-listed-sale-share-sec list-unstyled">
                        {props.singleNftImage.nft_collection
                          .twitter_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.nft_collection
                                  .twitter_link
                              }
                              target="_blank"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </Media>
                        ) : null}

                        {props.singleNftImage.nft_collection
                          .facebook_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.nft_collection
                                  .facebook_link
                              }
                              target="_blank"
                            >
                              <i className="fab fa-facebook"></i>
                            </a>
                          </Media>
                        ) : null}

                        {props.singleNftImage.nft_collection
                          .telegram_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.nft_collection
                                  .telegram_link
                              }
                              target="_blank"
                            >
                              <i className="fas fa-paper-plane"></i>
                            </a>
                          </Media>
                        ) : null}

                        {props.singleNftImage.nft_collection_item
                          .external_link ? (
                          <Media as="li">
                            <a
                              href={
                                props.singleNftImage.nft_collection_item
                                  .external_link
                              }
                              target="_blank"
                            >
                              <i className="fas fa-link"></i>
                            </a>
                          </Media>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="default-btn border-radius-5"
                  onClick={() => closeAcceptOffer()}
                >
                  View Item
                </button>
              </Modal.Footer>
            </>
					)}
				</Modal>
			</>
    )
};

const mapStateToPros = (state) => ({
  acceptOffer: state.nfts.acceptOfferNftItem,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AcceptOfferModal);
