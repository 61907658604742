import React, { useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchNftCollectionsListStart , clearCollectionDeleteData } from "../../store/actions/NftAction";
import { authContext } from "../authprovider/AuthProvider";
import MyCollectionCard from "./MyCollectionCard";
import { Row, Col, Dropdown } from "react-bootstrap";
import ExploreCardLoader from "../loaders/ExploreCardLoader"

const MyCollection = (props) => {
  const { auth } = useContext(authContext);

  useEffect(() => {
    if (!auth.loading && auth.accounts != "" && auth.authStatus) {
      props.dispatch(clearCollectionDeleteData())
      props.dispatch(fetchNftCollectionsListStart());
    }
  }, [auth.accounts , auth.authStatus]);

  return (
    <>
      <div className="my-collection text-center pages-padding">
        <Container className="mt-lg-4">
          <Col xs={12}>
            <h2>My Collections</h2>
            <p>
              Create, curate, and manage collections of unique NFTs to share and
              sell
            </p>
            <div className="button-wrapper">
            <Link to="/create-collection" className="collection-btn p-3 px-5">
              Create a collection
            </Link>
            <Link to="/import-collection" className="collection-btn p-3 px-5">
              Import
            </Link>
            </div>
          </Col>

          {props.collectionList.loading ? (
            <>
              <div className="explore-contents create-card-collection mt-4">
                <Row className="g-0">
                  <ExploreCardLoader count={6}/>
                </Row>
              </div>
            </>
          ) : (
            props.collectionList.data.nft_collections && props.collectionList.data.nft_collections.length > 0 && (
              <div className="explore-contents create-card-collection mt-4">
                <Row className="g-0">
                  {props.collectionList.data.nft_collections.map(
                    (collection, index) => (
                      <>
                        <MyCollectionCard key={index} data={collection} />
                      </>
                    )
                  )}
                </Row>
              </div>
            )
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleItems: state.nfts.singleItems,
  collectionList: state.nfts.collectionList,
  itemSave: state.nfts.itemSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyCollection);
