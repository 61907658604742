import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";

const TopSellersLoader = (props) => {
  const { classes, count } = props;

  const loader = Array.from(Array(count).keys());

  const theme = useTheme()

  return (
    <>
      <SkeletonTheme baseColor={theme.loader.primary} highlightColor={theme.loader.secondary}>
        <div className="col-md-8 mx-auto mb-4">
          <Skeleton
            count={1}
            className="profile-cover-image-loader"
            height={50}
            width={"100%"}
          />
        </div>
        <div className="row">
          {loader.map((index) => (
            <div
              className={`top-sellers-item-loader ${
                props.classes ? props.classes : ""
              }`}
              key={index}
            >
              <Skeleton
                count={1}
                className="profile-cover-image-loader"
                height={50}
                width={50}
                circle={true}
              />
              <div className="top-seller-details">
                <Skeleton
                  count={1}
                  className="profile-cover-image-loader"
                  height={30}
                  width={"100%"}
                />
                <Skeleton
                  count={1}
                  className="profile-cover-image-loader"
                  height={30}
                  width={"25%"}
                />
              </div>
            </div>
          ))}
        </div>
      </SkeletonTheme>
    </>
  );
};

export default TopSellersLoader;
