import React, { createContext, useState, useEffect, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { MetaMask, walletconnect, walletConnectProvider } from "./connectors";
import { useEagerConnect, useInactiveListener } from "./web3Hooks";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { UnsupportedChainIdError } from "@web3-react/core";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import { useDispatch, useSelector } from "react-redux";
import { InjectedConnector } from "@web3-react/injected-connector";
import { userLoginStart } from "../../store/actions/UserAction";
import Token from "../../abis/Token.json";
import Web3 from "web3";
import configuration from "react-global-configuration";
import { formatEther } from "@ethersproject/units";
import axios from "axios";
import {
  All_supported_ChainIds,
  SUPPORTED_RPC_URLS,
  SupportedChainId,
  CHAIN_INFO,
} from "./chains";
import { changeNetwork } from "./changeNetwork";

export const authContext = createContext({});

const AuthProvider = ({ children }, props) => {
  const context = useWeb3React();
  const dispatch = useDispatch();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const { ethereum } = window;

  const [activatingConnector, setActivatingConnector] = useState();

  const [auth, setAuth] = useState({
    loading: false,
    accounts: "",
    connectWalletStatus: false,
    ethBalance: null,
    userId: null,
    userUniqueId: null,
    userPicture: null,
    logoutStatus: localStorage.getItem("inital_connect"),
    chainId: active ? chainId : Object.values(SupportedChainId)[0],
    authStatus: false,
    wrongNetwork: false,
    sellerContractAddress: "",
  });

  const [price, setPrice] = useState({
    usdConvertedPrice: null,
    totalUsdbalane: null,
    denomination: "USD",
    totalUsdbalaneFormatted: null,
  });

  const loginData = useSelector((state) => state.users.loginInputData);

  useEffect(() => {
    getPrice();
  }, [auth.chainId, auth.accounts]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 60000);

    if (auth.ethBalance == null) {
      setPrice({
        ...price,
        totalUsdbalane: null,
      });
    }

    return () => clearInterval(interval);
  }, [auth.ethBalance]);

  const getPrice = async () => {
    const priceData = await axios
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${CHAIN_INFO[auth.chainId].apiId}&vs_currencies=usd`,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data[CHAIN_INFO[auth.chainId].apiId].usd;
        } else {
          return null;
        }
      });
    setPrice({
      ...price,
      usdConvertedPrice: priceData,
      totalUsdbalane: getUserequivalentUSDPrice(auth.ethBalance, priceData),
      totalUsdbalaneFormatted: Number(getUserequivalentUSDPrice(
        auth.ethBalance,
        priceData
      )).toLocaleString(undefined, { maximumFractionDigits: 5 }),
    });
  };

  const getUserequivalentUSDPrice = (balance, usd_price) => {
    if (balance && usd_price != null) {
      const eqvPrice = balance * usd_price;

      return Number(String(eqvPrice).substr(0, 6));
    } else {
      return null;
    }
  };

  const loginConnectors = [
    {
      name: "MetaMask",
      logo: "/assets/images/metamask-fox.svg",
      is_popular: true,
      connectorFunction: MetaMask,
      isAvailable: window.ethereum != undefined,
      installUrl: "https://metamask.io/",
      show: true,
    },
    {
      name: "WalletConnect",
      logo: "/assets/images/wallet-img/wallet-connect.png",
      is_popular: false,
      connectorFunction: walletconnect,
      isAvailable: true,
      installUrl: "https://walletconnect.com/",
      show: false,
    },
  ];

  useEffect(() => {
    if (
      activatingConnector &&
      activatingConnector === connector &&
      !auth.loading
    ) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector, auth.loading]);

  const handleConnector = async (connector) => {
    const network =
      ethereum && ethereum.networkVersion ? ethereum.networkVersion : "";
    // console.log(netID, network);

    setAuth({
      ...auth,
      loading: true,
      connectWalletStatus: true,
    });

    setActivatingConnector(connector);
    // if (connector instanceof WalletConnectConnector) {
    //   connector.walletConnectProvider = undefined;
    // }

    // if (connector instanceof InjectedConnector) {
    //   if (netID == network) {
    //     console.log("same network");
    //     activate(connector);
    //   } else {
    //     console.log("change network");
    //     changeNetwork();
    //   }
    // } else {
    //   activate(connector);
    // }
    if (connector instanceof WalletConnectConnector) {
      try {
        walletConnectProvider.rpcUrl = SUPPORTED_RPC_URLS[auth.chainId];
        walletConnectProvider.chainId = auth.chainId;
        await walletConnectProvider
          .enable()
          .then((account) => saveAccountDetails(account[0]))
          .catch((error) => {
            console.log(error)
            setAuth({
              ...auth,
              loading: false,
              connectWalletStatus: false,
            });
            setActivatingConnector(undefined)
          });
      } catch (error) {
        console.log(error);
        setAuth({
          ...auth,
          loading: false,
          connectWalletStatus: false,
        });
        setActivatingConnector(undefined)
      }
    }

    if (connector instanceof InjectedConnector) {
      connector.walletConnectProvider = undefined;
      if (
        All_supported_ChainIds.includes(Number(auth.chainId)) &&
        Number(network) === Number(auth.chainId)
      ) {
        setAuth({
          ...auth,
          wrongNetwork: false,
        });
        activate(connector);
      } else {
        console.log("change network");
        changeNetwork(Number(auth.chainId))
          .then((response) => {
            if (response == undefined) {
              setAuth({
                ...auth,
                loading: false,
                wrongNetwork: true,
                connectWalletStatus: false,
              });
              setActivatingConnector(undefined)
            }
            console.log("change network res", response)
           })
          .catch((error) => {
            console.error("Failed to switch networks", error);
            const notificationMessage = getErrorNotificationMessage(
              "Failed to switch networks"
            );
            dispatch(createNotification(notificationMessage));
            setAuth({
              ...auth,
              loading: false,
              wrongNetwork: true,
              connectWalletStatus: false,
            });
            setActivatingConnector(undefined)
          });
      }
    } else {
      activate(connector);
    }
  };

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect(auth.logoutStatus);

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !activatingConnector);

  function getErrorMessage(error) {
    setAuth({
      ...auth,
      loading: false,
      connectWalletStatus: false,
    });

    setActivatingConnector(undefined);

    if (error instanceof NoEthereumProviderError) {
      const notificationMessage = getErrorNotificationMessage(
        "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
      );
      dispatch(createNotification(notificationMessage));
    } else if (error instanceof UnsupportedChainIdError) {
      // const notificationMessage = getErrorNotificationMessage(
      //   "You're connected to an unsupported network."
      // );
      // dispatch(createNotification(notificationMessage));
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      const notificationMessage = getErrorNotificationMessage(
        "User rejected the request"
      );
      dispatch(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "An unknown error occurred. Check the console for more details"
      );
      dispatch(createNotification(notificationMessage));
    }
  }

  useEffect(() => {
    if (error) {
      getErrorMessage(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      chainId != undefined &&
      !All_supported_ChainIds.includes(Number(chainId)) &&
      connector instanceof InjectedConnector
    ) {
      deactivate();
    } else if (connector instanceof InjectedConnector) {
      activate(MetaMask);
    }
  }, [chainId]);

  const hanldeLogout = () => {
    // setActivatingConnector(undefined);
    setAuth({
      ...auth,
      loading: false,
      accounts: "",
      authStatus: false,
      wrongNetwork: false,
      connectWalletStatus: false,
      ethBalance: null,
      authStatus: false,
      chainStatus: false,
      userId: null,
      userUniqueId: null,
      userPicture: null,
      logoutStatus: "false",
      wrongNetwork: false,
      sellerContractAddress: "",
    });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userLoginStatus");
    localStorage.removeItem("user_picture");
    localStorage.removeItem("username");
    localStorage.removeItem("wallet_address");
    localStorage.setItem("inital_connect", false);
    deactivate();
  };

  const saveAccountDetails = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }

    const { ethereum } = window;

    try {
      const web3 = window.web3;
      if (account.length > 0) {
        console.log("saving account");

        localStorage.setItem("inital_connect", true);
        // const ethBalance = await web3.eth.getBalance(account);
        // console.log(ethBalance)
        // const ethBalanceFormated = await web3.utils
        //   .fromWei(ethBalance, "Ether")
        //   .substring(0, 8);
        //   console.log(newbalance)
        // const networkId = await web3.eth.net.getId();
        // console.log(networkId)
        // console.log(ethBalanceFormated)
        // const tokenData = Token.networks[networkId];
        // let tokens = null;
        // let token = null;
        // if (tokenData) {
        //   token = new web3.eth.Contract(Token.abi, tokenData.address);
        //   let tokenBalance = await token.methods.balanceOf(account).call();
        //   tokens = window.web3.utils
        //     .fromWei(tokenBalance.toString(), "Ether")
        //     .substring(0, 5);
        // } else {
        //   window.alert("Token contract not deployed to detected network.");
        // }
        await library
          .getBalance(account)
          .then((balance) => {
            if (balance) {
              let sellerAddress = "";
              if (auth.chainId == SupportedChainId.ETHEREUM) {
                sellerAddress = configuration.get("configData.seller_contract_address_ethereum")
                  ? configuration.get("configData.seller_contract_address_ethereum")
                  : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
              } else if (auth.chainId == SupportedChainId.BINANCE) {
                sellerAddress = configuration.get("configData.seller_contract_address_binance")
                  ? configuration.get("configData.seller_contract_address_binance")
                  : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
              } else if (auth.chainId == SupportedChainId.POLYGON) {
                sellerAddress = configuration.get("configData.seller_contract_address_polygon")
                  ? configuration.get("configData.seller_contract_address_polygon")
                  : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
              } else if (auth.chainId == SupportedChainId.VELAS) {
                sellerAddress = configuration.get("configData.seller_contract_address_avax")
                  ? configuration.get("configData.seller_contract_address_binance")
                  : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
              } else if (auth.chainId == SupportedChainId.AVAX) {
                sellerAddress = configuration.get("configData.seller_contract_address_velas")
                  ? configuration.get("configData.seller_contract_address_velas")
                  : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
              }
              setAuth({
                ...auth,
                accounts: account,
                chainStatus: false,
                ethBalance: formatEther(balance),
                logoutStatus: localStorage.getItem("inital_connect"),
                authStatus: true,
                connectWalletStatus: false,
                wrongNetwork: false,
                connectWalletStatus: false,
                sellerContractAddress: sellerAddress,
              });
            }
          })
          .catch((e) => {
            // console.log(e);
            setAuth({
              ...auth,
              wrongNetwork: false,
              ethBalance: null,
              loading: false,
            });
          });

        if (!loginData.loading) {
          dispatch(
            userLoginStart({
              wallet_address: account,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
          );
        }
      } else {
        hanldeLogout();
        const notificationMessage = getSuccessNotificationMessage(
          "Signed out successfully"
        );
        dispatch(createNotification(notificationMessage));
      }
    } catch (error) {
      setAuth({ ...auth, connectWalletStatus: false, authStatus: false });
    }
  };

  const getProviderSigner = async (message_content) => {
    let txhash;

    await library
      .getSigner(account)
      .signMessage(message_content)
      .then((signature) => (txhash = signature))
      .catch((error) => {
        const notificationMessage = getErrorNotificationMessage(error);
        dispatch(createNotification(notificationMessage));
      });
    return txhash;
  };

  const getBalance = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }

    const web3 = window.web3;

    await library
      .getBalance(account)
      .then((balance) => {
        if (balance) {
          let sellerAddress = "";
          if (auth.chainId == SupportedChainId.ETHEREUM) {
            sellerAddress = configuration.get("configData.seller_contract_address_ethereum")
              ? configuration.get("configData.seller_contract_address_ethereum")
              : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
          } else if (auth.chainId == SupportedChainId.BINANCE) {
            sellerAddress = configuration.get("configData.seller_contract_address_binance")
              ? configuration.get("configData.seller_contract_address_binance")
              : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
          } else if (auth.chainId == SupportedChainId.POLYGON) {
            sellerAddress = configuration.get("configData.seller_contract_address_polygon")
              ? configuration.get("configData.seller_contract_address_polygon")
              : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
          } else if (auth.chainId == SupportedChainId.VELAS) {
            sellerAddress = configuration.get("configData.seller_contract_address_avax")
              ? configuration.get("configData.seller_contract_address_binance")
              : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
          } else if (auth.chainId == SupportedChainId.AVAX) {
            sellerAddress = configuration.get("configData.seller_contract_address_velas")
              ? configuration.get("configData.seller_contract_address_velas")
              : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";
          }
          setAuth({
            ...auth,
            accounts: account,
            wrongNetwork: false,
            authStatus: true,
            connectWalletStatus: false,
            ethBalance: formatEther(balance),
            logoutStatus: localStorage.getItem("inital_connect"),
            loading: false,
            chainId: chainId,
            sellerContractAddress: sellerAddress,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setAuth({
          ...auth,
          ethBalance: null,
          connectWalletStatus: false,
          wrongNetwork: false,
        });
      });
  };

  const updateAuthstate = (values) => {
    setAuth({
      ...auth,
      ...values,
    })
  }

  useEffect(() => {
    if (account) {
      saveAccountDetails();
    }
  }, [account]);

  useEffect(() => {
    hanldeLogout();
  }, [auth.chainId])

  useEffect(() => {
    if (!loginData.loading) {
      if (Object.keys(loginData.data).length > 0) {
        if (loginData.data.code == 101) {
          setAuth({
            ...auth,
            loading: false,
            authStatus: true,
            userId: loginData.data.data.user_id,
            userUniqueId: loginData.data.data.user_unique_id,
            userPicture: loginData.data.data.picture,
          });
          localStorage.setItem("userId", loginData.data.data.user_id);
          localStorage.setItem("accessToken", loginData.data.data.token);
          setPrice({
            ...price,
            totalUsdbalane: getUserequivalentUSDPrice(
              auth.ethBalance,
              price.usdConvertedPrice
            ),
            totalUsdbalaneFormatted: Number(getUserequivalentUSDPrice(
              auth.ethBalance,
              price.usdConvertedPrice
            )).toLocaleString(undefined, { maximumFractionDigits: 5 }),
          });
        } else {
          setAuth({
            ...auth,
            loading: false,
            userId: null,
            userUniqueId: null,
            userPicture: null,
            authStatus: false,
          });
        }
      } else {
        setAuth({
          ...auth,
          loading: false,
          userId: null,
          userUniqueId: null,
          userPicture: null,
          authStatus: false,
        });
      }
    }
  }, [loginData.data]);

  useEffect(() => {
    const onbeforeunloadFn = () => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("userLoginStatus");
      localStorage.removeItem("user_picture");
      localStorage.removeItem("username");
      localStorage.removeItem("wallet_address");
    };

    window.addEventListener("beforeunload", onbeforeunloadFn);
    return () => {
      window.removeEventListener("beforeunload", onbeforeunloadFn);
    };
  }, []);

  return (
    <authContext.Provider
      value={{
        auth,
        context,
        handleConnector,
        loginConnectors,
        activatingConnector,
        hanldeLogout,
        getProviderSigner,
        library,
        price,
        updateAuthstate,
        getBalance,
        setAuth
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
