import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <div className="error-area pages-padding">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="error-content">
              <div className="">
                <img src={window.location.origin + "/assets/images/404.png"} alt="" />
              </div>
              <h3>Oops! Page Not Found</h3>
              <p>
                The page you are looking for might have been removed had its
                name changed or is temporarily unavailable.
              </p>
              <Link to="/" className="default-btn">
                Return To Home Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
