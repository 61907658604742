import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Tab,
  Row,
  Nav,
  InputGroup,
  FormControl,
  Container,
} from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ImageIcon from "@material-ui/icons/Image";
import { authContext } from "../authprovider/AuthProvider";
import {
  fetchUserDetailsStart,
  updateUserDetailsStart,
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const EditProfilePanel = (props) => {
  const { auth  , updateAuthstate} = useContext(authContext);

  const [profileData, setProfileData] = useState({
    name: "",
    about: "",
    email: "",
    website_link: "",
    discord_link: "",
    twitter_link: "",
    instagram_link: "",
    medium_link: "",
    telegram_link: "",
  });

  const [logoImage, setLogoImage] = useState({
    file: "",
    preview_image: "",
  });

  const [coverImage, setCoverImage] = useState({
    file: "",
    preview_image: "",
  });

  const [termsAgreed , setTermsAgreed] = useState(false)

  const editProfileSchema = Yup.object().shape({
    picture: Yup.mixed(),
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email"),
  });

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setLogoImage({
        ...logoImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAddCoverImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setCoverImage({
        ...coverImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (values) => {
    const newData = {
      ...values,
      cover: coverImage.file,
    };
    props.dispatch(
      updateUserDetailsStart({ ...newData, user_unique_id: auth.userUniqueId })
    );
  };

  const handleProfileFieldChanges = (event) => {
    setProfileData({
      ...profileData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (
      !props.profile.loading &&
      Object.keys(props.profile.data).length > 0 &&
      props.profile.data.user
    ) {
      setProfileData({
        ...profileData,
        name: props.profile.data.user.name,
        about:
          props.profile.data.user.about != null
            ? props.profile.data.user.about
            : "",
        email: props.profile.data.user.email,
        website_link: props.profile.data.user.website_link,
        discord_link: props.profile.data.user.discord_link,
        twitter_link: props.profile.data.user.twitter_link,
        instagram_link: props.profile.data.user.instagram_link,
        medium_link:  props.profile.data.user.medium_link,
        telegram_link: props.profile.data.user.telegram_link,
      });
      setLogoImage({
        ...logoImage,
        preview_image: props.profile.data.user.picture,
      });
      setCoverImage({
        ...coverImage,
        preview_image: props.profile.data.user.cover,
      });
    }
  }, [props.profile.data]);

  useEffect(() => {
    if(!props.updateUserDetails.loading && Object.keys(props.updateUserDetails.data).length > 0 ){
      updateAuthstate({userUniqueId :  props.updateUserDetails.data.user_unique_id})
      props.dispatch(fetchUserDetailsStart())
    }
  },[props.updateUserDetails.loading])

  const handleTermsChange = () => {
    setTermsAgreed(prev => !prev)
  }

  return (
    <>
      <Tab.Pane eventKey="first">
        <div className="edit-profile-wrapper">
          <div className="section-title">
            <h2 className="mb-3">Profile Settings</h2>
          </div>
          <div className="collection-form-area">
            <div className="collection-form no-shadow p-0">
              <Formik
                initialValues={{
                  ...profileData,
                  picture: logoImage.file,
                  cover: coverImage.file,
                }}
                className="w-100"
                validationSchema={editProfileSchema}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <Form>
                    <div className="profile-outer">
                      <h3>
                        Logo image <span class="text-danger">*</span>
                      </h3>
                      <p>
                        This image will also be used for navigation. 350 x 350
                        recommended
                      </p>

                      <div className="profileButton custom-input">
                        {logoImage.preview_image != "" && (
                          <div className="preview-image">
                            <img src={logoImage.preview_image} alt="" />
                          </div>
                        )}
                        <input
                          className="profileButton-input "
                          type="file"
                          name="logo"
                          accept="image/*"
                          id="upload"
                          onChange={(event) => handleAddImageChange(event)}
                        />
                        <label
                          className="profileButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          <ImageIcon />
                        </label>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="picture"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                    <div className="preview-box">
                      <h3>Banner image </h3>
                      <p>
                        This image will appear at the top of your collection
                        page. Aviod including too much text in this banner
                        image, as the dimensions change on the devices. 1400 x
                        400 recommended
                      </p>

                      <div className="previewButton custom-input-banner">
                        {coverImage.preview_image != "" && (
                          <div className="preview-image">
                            <img src={coverImage.preview_image} alt="" />
                          </div>
                        )}
                        <input
                          className="previewButton-input"
                          type="file"
                          name="cover"
                          accept="image/*"
                          id="uploadCover"
                          onChange={(event) => handleAddCoverImageChange(event)}
                        />
                        <label
                          className="previewButton-button ripple-effect"
                          for="uploadCover"
                        >
                          <ImageIcon />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div class="form-group ">
                        <h5>
                          Name <span class="text-danger">*</span>
                        </h5>
                        <div class="controls">
                          <Field
                            type="text"
                            name="name"
                            placeholder="Item name"
                            className={`form-control ${
                              touched.name && errors.name ? "is-invalid" : ""
                            }`}
                            value={profileData.name}
                            onChange={(event) =>
                              handleProfileFieldChanges(event)
                            }
                          />
                          <ErrorMessage
                            component="div"
                            name="name"
                            className="invalid-feedback mt-3"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div class="form-group ">
                        <h5>Description</h5>
                        <div class="controls">
                          <textarea
                            type="text"
                            name="about"
                            placeholder="Description"
                            className={`form-control`}
                            value={profileData.description}
                            onChange={(event) =>
                              handleProfileFieldChanges(event)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <h5>E-Mail </h5>
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="email id"
                          onChange={(event) => handleProfileFieldChanges(event)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <h5>Links</h5>
                      <div className="collection-links">
                        <InputGroup className="link-input">
                          <InputGroup.Text id="basic-addon1">
                            <i class="fas fa-globe"></i>
                          </InputGroup.Text>
                          <FormControl
                            placeholder="Your site link"
                            name="website_link"
                            value={profileData.website_link}
                            onChange={(event) =>
                              handleProfileFieldChanges(event)
                            }
                          />
                        </InputGroup>
                        <InputGroup className="link-input">
                          <InputGroup.Text id="basic-addon1">
                            <i class="fab fa-twitter"></i>
                          </InputGroup.Text>
                          <FormControl
                            placeholder="your twitter link"
                            name="twitter_link"
                            value={profileData.twitter_link}
                            onChange={(event) =>
                              handleProfileFieldChanges(event)
                            }
                          />
                        </InputGroup>
                        <InputGroup className=" link-input">
                          <InputGroup.Text id="basic-addon1">
                            <i class="fab fa-instagram"></i>
                          </InputGroup.Text>
                          <FormControl
                            placeholder="yout instagram link"
                            name="instagram_link"
                            value={profileData.instagram_link}
                            onChange={(event) =>
                              handleProfileFieldChanges(event)
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 mt-3">
                      <div class="form-group ">
                        <h5>Wallet Address</h5>
                        <div class="controls">
                          <input
                            type="text"
                            className={`form-control`}
                            value={auth.accounts}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="custom-checkbox mb-4 mt-4">
                      <div class="check-field">
                        <input id="my-check" type="checkbox" checked={termsAgreed} onChange={() => handleTermsChange()}/>
                        <label for="my-check">
                          I agree to the following{" "}
                          <Link to="/page/terms" target="_blank">terms & conditions</Link>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 create-btn">
                      <button
                        type="submit"
                        className="default-btn border-radius-5 px-5 py-3 mt-4"
                        disabled={props.updateUserDetails.buttonDisable || !termsAgreed}
                      >
                        {props.updateUserDetails.loadingButtonContent != ""
                          ? props.updateUserDetails.loadingButtonContent
                          : "Update Profile"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Tab.Pane>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  updateUserDetails: state.users.updateUserDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditProfilePanel);
