import React from 'react'

const GridChange = (props) => {
  return (
    <>
      <div className="grid-change-collection">
          <button className={`btn no-color primaryicon icon ${props.value == 1 ? "active" : ""}`} onClick={() => props.onChange(1)}>
            <i className="fas fa-th-large"></i>
          </button>
          <button className={`btn no-color primaryicon icon ${props.value == 2 ? "active" : ""}`} onClick={() => props.onChange(2)}>
          <i className="fas fa-th"></i>
          </button>
      </div>
    </>
  )
}

export default GridChange
