import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";
import SinglePageActivityTableLoader from "./SinglePageActivityTableLoader";

const SingleImageLoader = () => {

  const theme = useTheme()

  return (
    <>
      <SkeletonTheme baseColor={theme.loader.primary} highlightColor={theme.loader.secondary}>
        <div className="pages-padding">
          <div className="item-details-area">
            <div className="container mt-4">
              <div className="single-image-loader">
                <div className="row">
                  <div className="col-12 col-lg-6 single-details">
                    <div className="single-details-info-loader hide-loader-desktop">
                      <div className="item-Details-loader">
                        <Skeleton
                          count={1}
                          width={"70%"}
                          height={30}
                          borderRadius={"1rem"}
                          className="mb-2"
                        />
                        <Skeleton
                          count={1}
                          width={"100%"}
                          height={22}
                          borderRadius={"1rem"}
                        />
                      </div>
                      <div className="item-links-loader">
                        <Skeleton
                          count={4}
                          width={"58px"}
                          height={45}
                          borderRadius={"0.25rem"}
                        />
                      </div>
                    </div>
                    <Skeleton
                      count={1}
                      width={"100%"}
                      height={500}
                      borderRadius={"1rem"}
                    />
                    <div className="owner-details-loader hide-loader-desktop  mt-3">
                      <Skeleton
                        count={1}
                        width={"150px"}
                        height={25}
                        borderRadius={"0.25rem"}
                      />
                      <Skeleton
                        count={1}
                        width={"120px"}
                        height={25}
                        borderRadius={"0.25rem"}
                      />
                    </div>
                    <div className="single-properties-loader">
                      <Skeleton
                        count={4}
                        width={"100%"}
                        height={73}
                        borderRadius={"1rem"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-details-info-loader hide-loader-mobile">
                      <div className="item-Details-loader">
                        <Skeleton
                          count={1}
                          width={"70%"}
                          height={30}
                          borderRadius={"1rem"}
                          className="mb-2"
                        />
                        <Skeleton
                          count={1}
                          width={"100%"}
                          height={22}
                          borderRadius={"1rem"}
                        />
                      </div>
                      <div className="item-links-loader">
                        <Skeleton
                          count={4}
                          width={"58px"}
                          height={45}
                          borderRadius={"0.25rem"}
                        />
                      </div>
                    </div>
                    <div className="owner-details-loader hide-loader-mobile">
                      <Skeleton
                        count={1}
                        width={"150px"}
                        height={25}
                        borderRadius={"0.25rem"}
                      />
                      <Skeleton
                        count={1}
                        width={"120px"}
                        height={25}
                        borderRadius={"0.25rem"}
                      />
                    </div>
                    <div className="buy-box-loader my-3 mt-lg-0">
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={"150px"}
                        borderRadius={"1rem"}
                      />
                    </div>
                    <SinglePageActivityTableLoader />
                  </div>
                  <div className="col-12">
                    <div className="transaction-table-loader">
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={73}
                        borderRadius={"1rem"}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="other-items-loader">
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={570}
                        borderRadius={"1rem"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </>
  );
};

export default SingleImageLoader;
