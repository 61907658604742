import configuration from "react-global-configuration";

export const polygon = `${ window.location.origin +
  "/assets/images/priceImages/polygon.svg"}`

export const polygonDark = `${ window.location.origin +
    "/assets/images/priceImages/polygon-dark.svg"}`

export const velasImage = `${ window.location.origin +
    "/assets/images/velas-logo.svg"}`

export const velasImageDark = `${ window.location.origin +
  "/assets/images/priceImages/Vector-dark.svg"}`

export const binanceImage = `${ window.location.origin +
  "/assets/images/bnb-logo.png"}`

export const stableTokenLight = `${ window.location.origin +
  "/assets/images/priceImages/stable-token-logo-light.png"}`

export const stableTokenDark = `${ window.location.origin +
    "/assets/images/priceImages/stable-token-logo-dark.png"}`

export const networkLogo = velasImage


const usePriceImage  = (theme) => {

  const lightLogo = configuration.get("configData.chain_logo_light")
  ? configuration.get("configData.chain_logo_light")
  : polygon;

  const darkLogo = configuration.get("configData.native_currency_name")
  ? configuration.get("configData.chain_logo_dark")
  : polygonDark;


  if(theme === "light"){
    return lightLogo
  }else{
    return darkLogo
  }
}

export default usePriceImage