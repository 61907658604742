import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchSinglePageStart,
} from "../../store/actions/PageAction";
import { useHistory, useParams } from "react-router";
import StaticPageLoader from "../loaders/StaticPageLoader"

const StaticPage = (props) => {

  const { static_page_unique_id } = useParams();

  useEffect(() => {
    if(static_page_unique_id){
      props.dispatch(
        fetchSinglePageStart({ unique_id: static_page_unique_id })
      );
    }
  }, [static_page_unique_id]);

  return (
    <>
      {props.page.loading ? (
        <StaticPageLoader />
      ) : props.page.data && (
        <>
          <div className="inner-banner pages-padding static-page-bg">
            <div className="container">
              <div className="inner-title">
                <h3>{props.page.data.title}</h3>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Pages</li>
                  <li>{props.page.data.title}</li>
                </ul>

                <div className="inner-shape">
                  <img
                    src={
                      window.location.origin +
                      "/assets/images/inner-banner/inner-shape1.png"
                    }
                    alt="Images"
                  />
                  <img
                    src={
                      window.location.origin +
                      "/assets/images/inner-banner/inner-shape2.png"
                    }
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="terms-conditions-area pt-100 pb-70">
            <div className="container">
              <div className="section-title text-center">
                {/* <span className="sp-title">{props.page.data.title}</span> */}
                <h2 className="m-auto">{props.page.data.title}</h2>
              </div>
              <div className="row pt-45">
                <div className="col-lg-12 static-contents">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.page.data.description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StaticPage);
