import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  FETCH_OTHER_USER_DETAILS_START,
  FETCH_OTHER_USER_DETAILS_SUCCESS,
  FETCH_OTHER_USER_DETAILS_FAILURE,
  UPDATE_SIGN_APPROVE_START,
  UPDATE_SIGN_APPROVE_SUCCESS,
  UPDATE_SIGN_APPROVE_FAILURE
} from "./ActionConstant";

// User login actions.

export function userLoginStart(data) {
  return {
    type: LOGIN_START,
    data,
  };
}

export function userLoginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
}

export function userLoginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error,
  };
}

// Get user details actions.

export function fetchUserDetailsStart(data) {
  return {
    type: FETCH_USER_DETAILS_START,
    data,
  };
}

export function fetchUserDetailsSuccess(data) {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    data,
  };
}

export function fetchUserDetailsFailure(error) {
  return {
    type: FETCH_USER_DETAILS_FAILURE,
    error,
  };
}

export function updateUserDetailsStart(data) {
  return {
    type: UPDATE_USER_DETAILS_START,
    data,
  };
}

export function updateUserDetailsSuccess(data) {
  return {
    type: UPDATE_USER_DETAILS_SUCCESS,
    data,
  };
}

export function updateUserDetailsFailure(error) {
  return {
    type: UPDATE_USER_DETAILS_FAILURE,
    error,
  };
}

export function fetchOtherUserDetailsStart(data) {
  return {
    type: FETCH_OTHER_USER_DETAILS_START,
    data,
  };
}

export function fetchOtherUserDetailsSuccess(data) {
  return {
    type: FETCH_OTHER_USER_DETAILS_SUCCESS,
    data,
  };
}

export function fetchOtherUserDetailsFailure(error) {
  return {
    type: FETCH_OTHER_USER_DETAILS_FAILURE,
    error,
  };
}

export function updateSignAndApproveStart(data) {
  return {
    type: UPDATE_SIGN_APPROVE_START,
    data,
  };
}

export function updateSignAndApproveSuccess(data) {
  return {
    type: UPDATE_SIGN_APPROVE_SUCCESS,
    data,
  };
}

export function updateSignAndApproveFailure(error) {
  return {
    type: UPDATE_SIGN_APPROVE_FAILURE,
    error,
  };
}