import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";

const SingleImageItemListLoader = () => {
  const theme = useTheme();

  return (
    <>
      <SkeletonTheme
        baseColor={theme.loader.primary}
        highlightColor={theme.loader.secondary}
      >
        <div className="item-details-area">
          <div className="container mt-4">
            <div className="single-image-loader">
              <div className="row">
                <div className="col-12 p-0">
                  <div className="other-items-loader">
                    <Skeleton
                      count={1}
                      width={"100%"}
                      height={570}
                      borderRadius={"1rem"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </>
  );
};

export default SingleImageItemListLoader;
