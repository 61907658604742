import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";
import { Container } from "react-bootstrap";

const StaticPageLoader = (props) => {
  const { classes } = props;

  const theme = useTheme();

  return (
    <>
      <div className={`static-page-loader ${classes ? classes : ""}`}>
        <SkeletonTheme
          baseColor={theme.loader.primary}
          highlightColor={theme.loader.secondary}
        >
          <div className="inner-banner pages-padding static-page-bg">
            <div className="container">
              <div className="inner-title">
                <Skeleton count={1} height={54} width={160} />
                <ul>
                  <li>
                    <Skeleton count={1} height={28} width={40} />
                  </li>
                  <li>
                    <Skeleton count={1} height={28} width={150} />
                  </li>
                  <li>
                    <Skeleton count={1} height={28} width={80} />
                  </li>
                </ul>

                <div className="inner-shape">
                  <img
                    src={
                      window.location.origin +
                      "/assets/images/inner-banner/inner-shape1.png"
                    }
                    alt="Images"
                  />
                  <img
                    src={
                      window.location.origin +
                      "/assets/images/inner-banner/inner-shape2.png"
                    }
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
          <Container>
            <div className="terms-conditions-area pt-100 pb-70">
              <div className="text-center">
                <Skeleton count={1} height={48} width={200} />
              </div>
              <div className="pt-45">
                <div className="sections mb-5">
                  <Skeleton count={1} height={48} width={"25%"} />
                  <Skeleton count={1} height={28} width={"100%"} />
                  <Skeleton count={1} height={35} width={"100%"} />
                </div>
                <div className="sections mb-5">
                  <Skeleton count={1} height={48} width={"50%"} />
                  <Skeleton count={1} height={100} width={"100%"} />
                  <Skeleton count={1} height={78} width={"100%"} />
                </div>
                <div className="sections mb-5">
                  <Skeleton count={1} height={48} width={"30%"} />
                  <Skeleton count={1} height={20} width={"100%"} />
                  <Skeleton count={1} height={100} width={"100%"} />
                </div>
                <div className="sections mb-3">
                  <Skeleton count={1} height={48} width={"15%"} />
                  <Skeleton count={1} height={52} width={"100%"} />
                  <Skeleton count={1} height={18} width={"100%"} />
                </div>
              </div>
            </div>
          </Container>
        </SkeletonTheme>
      </div>
    </>
  );
};

export default StaticPageLoader;
