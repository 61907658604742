import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";

const tabBarStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",


    "& .MuiButtonBase-root" : {
      "& svg" : {
        fill : theme.palette.text.primary,
      },
    },

  },
  scroller: {
    flexGrow: "0",
  },
  tabBar: {

    "& .MuiTabs-scroller" : {
      margin: "0 1.5rem",

      "& .MuiTabs-indicator" : {
        backgroundColor : theme.themeColor.primary
      }
    },

    "& .MuiTabs-flexContainer": {
      paddingBottom: "0.5em",

      "& .MuiButtonBase-root ": {
        "& .MuiBox-root ": {
          display: "flex",
          gap: "1em",

          "& .MuiSvgIcon-root" : {
            fill : theme.palette.text.primary,
          },

          "& .MuiTypography-root": {
            color: theme.palette.text.primary,
            fontSize: "1.15em",
            fontWeight: 700,
          },
        },
      },
    },

    "& span": {
      height: "5px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
    },
  },
}));

const CustomTabs = (props) => {
  const classes = tabBarStyles();

  return (
    <>
      <Tabs
        value={props.activeTab}
        onChange={props.handleTabChange}
        variant="scrollable"
        scrollButtons={"on"}
        aria-label="scrollable auto tabs example"
        className={classes.tabBar}
        classes={{root: classes.root, scroller: classes.scroller}}
      >
        {props.children}
      </Tabs>
    </>
  );
};

export default CustomTabs;
