import React from "react";
import { Modal, Button } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";

const AddPropertiesModal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal"
        onHide={props.onHide}
      >
        <Modal.Header>
          <div className="modal-header-wrapper">
            <h3 className="mb-0 modal-heading">Add Properties</h3>
            <div className="custom-close" onClick={() => props.onHide()} >
              <CloseIcon />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>
            Properties show up underneath your item, are clickable, and can be
            filtered in your collection's sidebar.
          </p>
          <div className="add-properties-wrapper ">
            <Row className="no-wrap g-0">
              <Col xs={11} md={6}>
                <h5 className="mr-2">Property</h5>
              </Col>
              <Col xs={11} md={6}>
                <h5>Value</h5>
              </Col>
            </Row>
            <div className="collection-form no-shadow properties-layers">
              <div className="new-properties-wrapper">
                {props.properties.length > 0 && (
                  <>
                    {props.properties.map((properties, index) => (
                      <>
                        <div className="properties-layer">
                          <Row className="g-1">
                            <Col xs={11} md={6}>
                              <InputGroup className="custom-input-group">
                                <Button
                                  variant="outline-secondary"
                                  id="button-addon1"
                                  onClick={() => props.handlePropertyDelete(properties)}
                                >
                                  <CloseIcon/>
                                </Button>
                                <FormControl 
                                  placeholder="" 
                                  name="name" 
                                  value={properties.name} 
                                  onChange={(event) => props.handlePropertyNameChange(index , event.currentTarget.value)}
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={11} md={6}>
                              <div className="form-group ">
                                <div className="controls">
                                  <FormControl
                                    type="text"
                                    name="name"
                                    placeholder=""
                                    className="form-control"
                                    value={properties.value} 
                                    onChange={(event) => props.handlePropertyValueChange(index , event.currentTarget.value)}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="add-more-btn px-2">
            <button
              className="default-btn two border-radius-5"
              onClick={() => props.handleNewProperty()}
            >
              Add More
            </button>
          </div>
          </div>
          <div className="add-more-btn text-center pt-3">
            <button
              className="default-btn two border-radius-5"
              onClick={() => props.handlePropertySave()}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPropertiesModal;
