import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

const EmptyFooter = () => {
  return (
    <>
      <footer className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-12 footer-align logo">
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/">
                    <img
                      src={configuration.get("configData.site_logo")}
                      alt="Footer Logo"
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 footer-align">
              <div className="footer-widget ps-5">
                <h3>Resources</h3>
                <ul className="footer-list text-capitalize">
                  {configuration
                    .get("configData.footer_pages1")
                    .map((static_page, index) => (
                      <li key={index}>
                        <Link to={`/page/${static_page.static_page_unique_id}`} >
                          {static_page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 footer-align">
              <div className="footer-widget ps-5">
                <h3>Company</h3>
                <ul className="footer-list text-capitalize">
                  {configuration
                    .get("configData.footer_pages2")
                    .map((static_page, index) => (
                      <li key={index}>
                        <Link to={`/page/${static_page.static_page_unique_id}`}>
                          {static_page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copyright-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="copy-right-text">
                <p>{configuration.get("configData.copyright_content")}</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="copy-right-social">
                <ul className="social-link">
                  {configuration.get("configData.facebook_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.facebook_link")}
                        target="_blank"
                      >
                        <i className="ri-facebook-fill"></i>
                      </a>
                    </li>
                  ) : null}
                  {configuration.get("configData.instagram_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.instagram_link")}
                        target="_blank"
                      >
                        <i className="ri-instagram-fill"></i>
                      </a>
                    </li>
                  ) : null}
                  {configuration.get("configData.twitter_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.twitter_link")}
                        target="_blank"
                      >
                        <i className="ri-twitter-fill"></i>
                      </a>
                    </li>
                  ) : null}
                  {configuration.get("configData.linkedin_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.linkedin_link")}
                        target="_blank"
                      >
                        <i className="ri-linkedin-fill"></i>
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(EmptyFooter);
