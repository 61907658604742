import React, { useEffect, useState, useContext, useCallback } from "react";
import CustomTooltip from "../../customComponents/CustomTooltip";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/core/Tab";
import CustomTabs from "../../customComponents/CustomTabs";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import GridOnOutlinedIcon from "@material-ui/icons/GridOnOutlined";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormatPaintIcon from "@material-ui/icons/FormatPaint";
import CollectionCard from "../../customComponents/CollectionCard";
import { useHistory, useLocation, useParams } from "react-router";
import CustomSelect from "../../customComponents/CustomSelect";
import GridChange from "../../customComponents/GridChange";
import CustomSearch from "../../customComponents/CustomSearch";
import { connect } from "react-redux";
import { authContext } from "../authprovider/AuthProvider";
import { fetchOtherUserDetailsStart } from "../../store/actions/UserAction";
import { getSuccessNotificationMessage } from "../../components/helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import CustomCollectionFilter from "../../customComponents/CustomCollectionFilter";
import {
  fetchNftPropertiesListStart,
  nftUserProfileItemsListStart,
  loadMoreUserProfileItemsStart,
} from "../../store/actions/NftAction";
import CustomTabPanel from "../../customComponents/CustomTabPanel";
import CustomInfiniteScroll from "../../customComponents/CustomInfiniteScroll";
import NoDataFound from "../NoDataFound/NoDataFound";
import CollectionCardLoader from "../loaders/CollectionCardLoader";
import PageNotFound from "../../components/404/PageNotFound";
import CustomCollectionSearch from "../../customComponents/CustomCollectionSearch";
import CloseIcon from "@material-ui/icons/Close";
import useValidation from "../../customHooks/useValidation";
import ProfilerLoader from "../loaders/ProfilerLoader";

const queryString = require("query-string");

const customTabLabel = (props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 0.5,
          pr: 0,
        }}
      >
        <Box component={props.icon} sx={{ mr: 1 }} />
        <Typography variant="body2">{props.text}</Typography>
        <Typography variant="body2">{props.count}</Typography>
      </Box>
    </>
  );
};

const OtherProfile = (props) => {
  const { user_unique_id } = useParams();

  const location = useLocation();

  const history = useHistory();

  const parsedTab = queryString.parse(props.location.search);

  const [activeTab, setActiveTab] = useState(
    Object.keys(parsedTab).length > 0 ? parsedTab.tab : "collected"
  );

  const [skipInitalRender, setSkipIntialRender] = useState(false);

  const [priceSort, setPriceSort] = useState([]);

  const [gridView, setGridView] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");

  const { auth } = useContext(authContext);

  const [filterStatus, setFilterStatus] = useState(true);

  const [mobileFilterStatus, setMobileFilterStatus] = useState(false);

  //get filter propetries

  const [filterDataContents, setFilterDataContents] = useState({
    sort_by: [],
    min_amount: "",
    max_amount: "",
    categories: [],
    properties: [],
    propertyRemoved: {
      count: 0,
      data: [],
    },
    categoryRemoved: {
      count: 0,
      data: [],
    },
    clearAll: false,
    search_key: "",
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const { validation } = useValidation(props.profile);

  useEffect(() => {
    props.dispatch(
      fetchOtherUserDetailsStart({ user_unique_id: user_unique_id })
    );
  }, [location]);

  useEffect(() => {
    if (!validation.loading && !validation.status) {
      props.dispatch(
        fetchNftPropertiesListStart({ user_unique_id: user_unique_id })
      );
      props.dispatch(
        nftUserProfileItemsListStart({
          type: activeTab,
          user_unique_id: user_unique_id,
        })
      );
    }
  }, [validation.loading]);

  useEffect(() => {
    if (skipInitalRender) {
      const query = queryString.stringify(
        { tab: activeTab },
        { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
      );

      history.push({
        pathname: props.location.pathname,
        search: query,
      });

      props.dispatch(
        nftUserProfileItemsListStart({
          type: activeTab,
          user_unique_id: user_unique_id,
        })
      );
    }
    setSkipIntialRender(true);
  }, [activeTab]);

  const handlePriceSortChange = (value) => {
    setPriceSort([value]);
    setFilterDataContents({
      ...filterDataContents,
      sort_by: [value],
    });
    if (priceSort.length > 0) {
      props.dispatch(
        nftUserProfileItemsListStart({
          ...filterDataContents,
          type: activeTab,
          sort_by: priceSort[0].id,
          user_unique_id: props.profile.data.user.user_unique_id,
        })
      );
    } else {
      props.dispatch(
        nftUserProfileItemsListStart({
          ...filterDataContents,
          type: activeTab,
          sort_by: value.id,
          user_unique_id: props.profile.data.user.user_unique_id,
        })
      );
    }
  };

  const handleGridViewChange = (value) => {
    setGridView(value);
  };

  const handleSearchQueryChange = (value) => {
    setSearchQuery(value);
  };

  const copyToClipboard = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    const notificationMessage = getSuccessNotificationMessage(
      "text copied to clipboard"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const handleFilterDrawerStatus = (status) => {
    setFilterStatus(status);
  };

  const handleMobileFilterDrawerStatus = (status) => {
    setMobileFilterStatus(status);
  };

  useEffect(() => {
    if (Object.keys(filterDataContents).length > 0) {
      if (filterDataContents.sort_by) {
        setPriceSort(filterDataContents.sort_by);
      }
    }
  }, [filterDataContents]);

  const clearFilter = () => {
    setFilterDataContents({
      sort_by: [],
      min_amount: "",
      max_amount: "",
      categories: [],
      properties: [],
      propertyRemoved: {
        count: 0,
        data: [],
      },
      categoryRemoved: {
        count: 0,
        data: [],
      },
      clearAll: true,
      search_key : ""
    });
    setPriceSort([]);
    props.dispatch(
      nftUserProfileItemsListStart({
        type: activeTab,
        user_unique_id: user_unique_id,
      })
    );
  };

  const removeSingleProperty = (removedPropertyName , removedPropertyValue) => {
    setFilterDataContents({
      ...filterDataContents,
      propertyRemoved: {
        count: filterDataContents.propertyRemoved.count + 1,
        data: {name : removedPropertyName , value : [removedPropertyValue]},
      },
    });
  };

  const removeSortby = () => {
    setPriceSort([]);
    setFilterDataContents({
      ...filterDataContents,
      sort_by: [],
    });
    props.dispatch(
      nftUserProfileItemsListStart({
        type: activeTab,
        user_unique_id: user_unique_id,
        sort_by: "",
        min_amount:
          filterDataContents.min_amount > 0
            ? filterDataContents.min_amount
            : "",
        max_amount:
          filterDataContents.max_amount > 0
            ? filterDataContents.max_amount
            : "",
        categories:
          filterDataContents.categories.length > 0
            ? JSON.stringify(filterDataContents.categories)
            : "",
        properties:
          filterDataContents.properties.length > 0
            ? JSON.stringify(filterDataContents.properties)
            : "",
        search_key: filterDataContents.search_key,
      })
    );
  };

  const clearPriceRange = (value) => {
    if (value === "min") {
      setFilterDataContents({
        ...filterDataContents,
        min_amount: "",
      });
      props.dispatch(
        nftUserProfileItemsListStart({
          type: activeTab,
          user_unique_id: user_unique_id,
          sort_by: priceSort.length > 0 ? priceSort[0].id : "",
          min_amount: "",
          max_amount:
            filterDataContents.max_amount > 0
              ? filterDataContents.max_amount
              : "",
          categories:
            filterDataContents.categories.length > 0
              ? JSON.stringify(filterDataContents.categories)
              : "",
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
          search_key: filterDataContents.search_key,
        })
      );
    } else if (value === "max") {
      setFilterDataContents({
        ...filterDataContents,
        max_amount: "",
      });
      props.dispatch(
        nftUserProfileItemsListStart({
          type: activeTab,
          user_unique_id: user_unique_id,
          sort_by: priceSort.length > 0 ? priceSort[0].id : "",
          min_amount:
            filterDataContents.min_amount > 0
              ? filterDataContents.min_amount
              : "",
          max_amount: "",
          categories:
            filterDataContents.categories.length > 0
              ? JSON.stringify(filterDataContents.categories)
              : "",
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
          search_key: filterDataContents.search_key,
        })
      );
    } else if (value === "minmax") {
      setFilterDataContents({
        ...filterDataContents,
        max_amount: "",
        min_amount: "",
      });
      props.dispatch(
        nftUserProfileItemsListStart({
          type: activeTab,
          user_unique_id: user_unique_id,
          sort_by: priceSort.length > 0 ? priceSort[0].id : "",
          min_amount: "",
          max_amount: "",
          categories:
            filterDataContents.categories.length > 0
              ? JSON.stringify(filterDataContents.categories)
              : "",
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
          search_key: filterDataContents.search_key,
        })
      );
    }
  };

  const clearSearchKey = () => {
    setFilterDataContents({
      ...filterDataContents,
      search_key: "",
    });
    props.dispatch(
      nftUserProfileItemsListStart({
        type: activeTab,
        user_unique_id: user_unique_id,
        sort_by: priceSort.length > 0 ? priceSort[0].id : "",
        min_amount:
          filterDataContents.min_amount > 0
            ? filterDataContents.min_amount
            : "",
        max_amount:
          filterDataContents.max_amount > 0
            ? filterDataContents.max_amount
            : "",
        categories:
          filterDataContents.categories.length > 0
            ? JSON.stringify(filterDataContents.categories)
            : "",
        properties:
          filterDataContents.properties.length > 0
            ? JSON.stringify(filterDataContents.properties)
            : "",
        search_key: "",
      })
    );
  };

  return (
    <>
      <div className="profile">
        {validation.loading ? (
          <>
            <ProfilerLoader />
          </>
        ) : validation.status ? (
          <>
            <PageNotFound />
          </>
        ) : (
          <>
            {props.profile.data.user && (
              <div className="profile-banner-section">
                <div className="profile-cover-image ">
                  <img src={props.profile.data.user.cover} alt="" />
                </div>
                <div className="col-6 col-md-6 col-lg-4 mx-auto">
                  <div className="user-details">
                    <div className="profile-image">
                      <img src={props.profile.data.user.picture} alt="" />
                    </div>
                    <h4>{props.profile.data.user.name}</h4>
                    <div
                      className="wallet-address"
                      onClick={() =>
                        copyToClipboard(props.profile.data.user.wallet_address)
                      }
                    >
                      <CustomTooltip title="copy" placement="top">
                        <a href="#">
                          <h5>
                            {props.profile.data.user.wallet_address.substr(
                              0,
                              5
                            )}
                            ...
                            {props.profile.data.user.wallet_address.substr(
                              props.profile.data.user.wallet_address.length - 4
                            )}
                          </h5>
                        </a>
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {!validation.status && (
          <>
            <div className="user-collections">
              <div className="collection-tabs">
                <CustomTabs
                  activeTab={activeTab}
                  handleTabChange={handleTabChange}
                >
                  <Tab
                    aria-label="phone"
                    label={customTabLabel({
                      icon: GridOnOutlinedIcon,
                      text: "Collected",
                      count: `${
                        !props.profile.loading
                          ? props.profile.data.collected_count
                          : 0
                      }`,
                    })}
                    value={"collected"}
                  />
                  <Tab
                    aria-label="phone"
                    label={customTabLabel({
                      icon: FormatPaintIcon,
                      text: "Created",
                      count: `${
                        !props.profile.loading
                          ? props.profile.data.created_count
                          : 0
                      }`,
                    })}
                    value={"created"}
                  />
                  <Tab
                    aria-label="phone"
                    label={customTabLabel({
                      icon: FavoriteBorderOutlinedIcon,
                      text: "Favourited",
                      count: `${
                        !props.profile.loading
                          ? props.profile.data.favorites_count
                          : 0
                      }`,
                    })}
                    value={"favorites"}
                  />
                </CustomTabs>
              </div>
              <div className="collection-items">
                <div className="collection-widget-area">
                  <div className="pr-3">
                    <div className="filter-drawer-wrapper">
                      <CustomCollectionFilter
                        filterStatus={filterStatus}
                        handleFilterDrawerStatus={handleFilterDrawerStatus}
                        mobileFilterStatus={mobileFilterStatus}
                        handleMobileFilterDrawerStatus={
                          handleMobileFilterDrawerStatus
                        }
                        showproperties={true}
                        propertiesList={
                          props.propertiesList.loading
                            ? []
                            : props.propertiesList.data.nft_properties
                        }
                        fetchAction={nftUserProfileItemsListStart}
                        dispatchData={{
                          type: activeTab,
                          user_unique_id: props.profile.data.user
                            ? props.profile.data.user.user_unique_id
                            : undefined,
                        }}
                        setFilterDataContents={setFilterDataContents}
                        filterDataContents={filterDataContents}
                        priceSort={priceSort}
                      />
                      <div className="p-4">
                        <div className="col-12">
                          <div className="collection-actions">
                            <CustomCollectionSearch
                              value={searchQuery}
                              onChange={handleSearchQueryChange}
                              classes="collection-search"
                              dispatchAction={nftUserProfileItemsListStart}
                              dispatchData={{
                                min_amount: filterDataContents.min_amount,
                                max_amount: filterDataContents.max_amount,
                                categories:
                                  filterDataContents.categories.length > 0
                                    ? JSON.stringify(
                                        filterDataContents.categories
                                      )
                                    : "",
                                properties:
                                  filterDataContents.properties.length > 0
                                    ? JSON.stringify(
                                        filterDataContents.properties
                                      )
                                    : "",
                                sort_by:
                                  priceSort.length > 0 ? priceSort[0].id : "",
                                type: activeTab,
                                user_unique_id: props.profile.data.user
                                  ? props.profile.data.user.user_unique_id
                                  : undefined,
                              }}
                              clearData={setSearchQuery}
                              setFilterDataContents={setFilterDataContents}
                              filterDataContents={filterDataContents}
                            />
                            <div className="price-select-wrapper">
                              <CustomSelect
                                value={priceSort}
                                handleChange={handlePriceSortChange}
                                items={[
                                  { name: "Newly Added", id: "new" },
                                  { name: "Low to high", id: "price_lh" },
                                  { name: "High to low", id: "price_hl" },
                                  { name: "Buy Now", id: "buy_now" },
                                ]}
                                placeHolder={"Sort by"}
                                idBased
                                nameField={"name"}
                                valueField={"id"}
                              />
                            </div>
                            <div className="grid-view-change">
                              <GridChange
                                value={gridView}
                                onChange={handleGridViewChange}
                              />
                            </div>
                          </div>
                          {Object.keys(filterDataContents).length > 0 && (
                            <>
                              <div className="selected-filters">
                                {filterDataContents.properties && (
                                  <>
                                    {filterDataContents.properties.map(
                                      (selected) => (
                                        <>
                                          {selected.value.map(
                                            (selectedvalue) => (
                                              <div className="properties-panel text-center ">
                                                <p className="mb-0">
                                                  {selectedvalue}
                                                  <CloseIcon
                                                    onClick={() =>
                                                      removeSingleProperty(
                                                        selected.name , selectedvalue
                                                      )
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </>
                                      )
                                    )}
                                  </>
                                )}
                                {filterDataContents.sort_by && (
                                  <>
                                    {filterDataContents.sort_by.map(
                                      (selected) => (
                                        <>
                                          <div className="properties-panel text-center ">
                                            <p className="mb-0">
                                              {selected.name}
                                              <CloseIcon
                                                onClick={() => removeSortby()}
                                              />
                                            </p>
                                          </div>
                                        </>
                                      )
                                    )}
                                  </>
                                )}
                                {filterDataContents.min_amount && (
                                  <>
                                    {Number(filterDataContents.min_amount) >
                                      0 &&
                                      Number(filterDataContents.max_amount) ==
                                        0 && (
                                        <div className="properties-panel text-center ">
                                          <p className="mb-0">
                                            {`Price > ${filterDataContents.min_amount}`}
                                            <CloseIcon
                                              onClick={() =>
                                                clearPriceRange("min")
                                              }
                                            />
                                          </p>
                                        </div>
                                      )}
                                  </>
                                )}
                                {filterDataContents.max_amount && (
                                  <>
                                    {Number(filterDataContents.max_amount) >
                                      0 &&
                                      Number(filterDataContents.min_amount) ==
                                        0 ? (
                                        <div className="properties-panel text-center ">
                                          <p className="mb-0">
                                            {`Price < ${filterDataContents.max_amount}`}
                                            <CloseIcon
                                              onClick={() =>
                                                clearPriceRange("max")
                                              }
                                            />
                                          </p>
                                        </div>
                                      ) : null}
                                  </>
                                )}
                                {filterDataContents.max_amount &&
                                  filterDataContents.min_amount && (
                                    <>
                                      {Number(filterDataContents.max_amount) >
                                        0 &&
                                        Number(
                                          filterDataContents.min_amount
                                        ) ? (
                                          <div className="properties-panel text-center ">
                                            <p className="mb-0">
                                              {`Price ${filterDataContents.min_amount} - ${filterDataContents.max_amount}`}
                                              <CloseIcon
                                                onClick={() =>
                                                  clearPriceRange("minmax")
                                                }
                                              />
                                            </p>
                                          </div>
                                        ) : null}
                                    </>
                                  )}
                                {filterDataContents.search_key != "" && (
                                  <div className="properties-panel text-center ">
                                    <p className="mb-0">
                                      {filterDataContents.search_key}
                                      <CloseIcon
                                        onClick={() => clearSearchKey()}
                                      />
                                    </p>
                                  </div>
                                )}
                                {filterDataContents.isActive && (
                                  <button
                                    className="mb-0 link-btn"
                                    onClick={() => clearFilter()}
                                  >
                                    Clear All
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                          <div className="collection-result-count mb-3">
                            {props.userProfileItems.loading ? (
                              <p>Loading...</p>
                            ) : (
                              <>
                              {props.userProfileItems.data.filter_total <
                              1 ? (
                                <p>
                                  {props.userProfileItems.data.filter_total}{" "}
                                  result
                                </p>
                              ) : (
                                <p>
                                  {props.userProfileItems.data.filter_total}{" "}
                                  results
                                </p>
                              )}
                            </>
                            )}
                          </div>
                        </div>
                        {props.userProfileItems.loading ? (
                          <>
                            <div
                              className={`collection-grid no-margin ${
                                gridView != 1 ? "small" : ""
                              }`}
                            >
                              <CollectionCardLoader
                                count={12}
                                classes={"mt-3"}
                              />
                            </div>
                          </>
                        ) : props.userProfileItems.data.nft_collection_items
                            .length > 0 ? (
                          <div>
                            <CustomInfiniteScroll
                              fetchAction={loadMoreUserProfileItemsStart}
                              fetchData={props.userProfileItems}
                              fetchedData={
                                props.userProfileItems.data.nft_collection_items
                              }
                              skip={
                                props.userProfileItems.data.nft_collection_items
                                  .length
                              }
                              take={12}
                              skippedFetch={true}
                              total={props.userProfileItems.data.filter_total}
                              dispatchData={{
                                type: activeTab,
                                user_unique_id: user_unique_id,
                                min_amount: filterDataContents.min_amount,
                                max_amount: filterDataContents.max_amount,
                                categories:
                                  filterDataContents.categories.length > 0
                                    ? JSON.stringify(
                                        filterDataContents.categories
                                      )
                                    : "",
                                properties:
                                  filterDataContents.properties.length > 0
                                    ? JSON.stringify(
                                        filterDataContents.properties
                                      )
                                    : "",
                                search_key: filterDataContents.search_key,
                                sort_by:
                                  priceSort.length > 0 ? priceSort[0].id : "",
                              }}
                              useLoader={true}
                              loaderComponent={
                                <CollectionCardLoader
                                  count={12}
                                  classes={`mt-3 ${
                                    gridView != 1 ? "small" : ""
                                  }`}
                                />
                              }
                              loaderclasses={`collection-grid no-margin ${
                                gridView != 1 ? "small" : ""
                              }`}
                            >
                              <div
                                className={`collection-grid no-margin ${
                                  gridView != 1 ? "small" : ""
                                }`}
                              >
                                {props.userProfileItems.data.nft_collection_items.map(
                                  (item, index) => (
                                    <CollectionCard
                                      data={item}
                                      useFlex={false}
                                      key={index}
                                      gridView={gridView}
                                    />
                                  )
                                )}
                              </div>
                            </CustomInfiniteScroll>
                          </div>
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.otherProfile,
  userProfileItems: state.nfts.userProfileItems,
  propertiesList: state.nfts.propertiesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(React.memo(OtherProfile));
