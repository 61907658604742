import React, { useState, useContext } from "react";
import configuration from "react-global-configuration";
import {
  importContractAddressStart,
  clearImportContractAddressData,
} from "../../store/actions/NftAction";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router";
import NftToken from "../../abis/NftToken.json";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import CustomSelect from "../../customComponents/CustomSelect";
import {
  CHAIN_INFO,
} from "../authprovider/chains";
import { authContext } from "../authprovider/AuthProvider";

const ImportCollection = (props) => {
  const history = useHistory();

  const [importAddress, setImportAddress] = useState("");

  const [importLoading, serImportLoading] = useState("");

  const { auth } = useContext(authContext);

  const handleImporAddressChange = (value) => {
    setImportAddress(value);
  };

  const handleImport = async () => {
    const web3 = window.web3;

    let nftContractData;
    // const NFTData = NftToken.networks[auth.chainId];

    // if (NFTData) {
      try {
        serImportLoading("Importing please wait....");
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          importAddress
        );
        const ownerAddress = await nftContractData.methods.owner().call();
        props.dispatch(
          importContractAddressStart({
            contract_address: importAddress,
            owner_wallet_address: ownerAddress,
            blockchain_type: CHAIN_INFO[auth.chainId].nativeCurrency.symbol,
          })
        );
      } catch (error) {
        serImportLoading("");
        const notificationMessage = getErrorNotificationMessage(
          `Contract not deployed in ${CHAIN_INFO[auth.chainId].nativeCurrency.symbol}`
        );
        props.dispatch(createNotification(notificationMessage));
      }
    // } else {
    //   const notificationMessage = getErrorNotificationMessage(
    //     `Contract not deployed in ${CHAIN_INFO[auth.chainId].nativeCurrency.symbol}`
    //   );
    //   props.dispatch(createNotification(notificationMessage));
    // }
  };

  useEffect(() => {
    if (
      !props.importContract.loading &&
      Object.keys(props.importContract.data).length > 0
    ) {
      const delayDebounceFn = setTimeout(() => {
        serImportLoading("");
        history.replace(`/profile`);
        props.dispatch(clearImportContractAddressData());
      }, 5000);

      return () => clearTimeout(delayDebounceFn);
    }
    if ( !props.importContract.loading && props.importContract.error){
      serImportLoading("");
    }
  }, [props.importContract.loading]);

  return (
    <>
      <div className="pages-padding">
        <div className="import-collection">
          <div className="container">
            <div className="heading">
              <h2>Enter your contract address</h2>
              <p>
                What is the address of your ERC721 contract on the{" "}
                {CHAIN_INFO[auth.chainId].label} Network.
              </p>
            </div>
            <div className="input-button new-input row">
            {/* <div className="col-md-2">
                <CustomSelect
                  value={chainSelect}
                  handleChange={(value) => setChainSelect([value])}
                  items={chains}
                  idBased
                  placeHolder={configuration.get("configData.binance_native_currency_symbol")}
                  nameField={"name"}
                  valueField={"id"}
                />
              </div> */}
              <div className="col-md-8">
              <input
                value={importAddress}
                type="input"
                className="form-control"
                placeholder="Enter contract address"
                onChange={(event) =>
                  handleImporAddressChange(event.target.value)
                }
              />
              </div>
              <div className="col-md-3">
              <button
                className="default-btn border-radius-5"
                onClick={() => handleImport()}
                disabled={importAddress == "" || importLoading != ""}
              >
                {importLoading != "" ? importLoading : "Import"}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  importContract: state.nfts.importContract,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ImportCollection);
