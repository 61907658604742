import React, { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";

const CollectionActivityLoader = (props) => {
  const { count, classes } = props;

  const loader = Array.from(Array(count).keys());

  const theme = useTheme();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <>
      <div className={`activityLoader ${classes ? classes : ""}`}>
        <SkeletonTheme
          baseColor={theme.loader.primary}
          highlightColor={theme.loader.secondary}
        >
          {windowWidth < 768 ? (
            <>
              <div className="p-3 mobile-activityLoader">
                {loader.map((value, index) => (
                  <Skeleton
                    count={1}
                    height={120}
                    width="100%"
                    key={index}
                    className="mb-3"
                  />
                ))}
              </div>
            </>
          ) : (
            <>
              <div>
                <div className="p-3 desktop-activityLoader">
                  <div className="activity-table-wrapper">
                    <div className="table-header">
                      <div className="table-header-item">
                        <h5 className="table-heading"></h5>
                      </div>
                      <div className="table-header-item">
                        <h5 className="table-heading">Item</h5>
                      </div>
                      <div className="table-header-item">
                        <h5 className="table-heading">Price</h5>
                      </div>

                      <div className="table-header-item">
                        <h5 className="table-heading">From</h5>
                      </div>
                      <div className="table-header-item">
                        <h5 className="table-heading">To</h5>
                      </div>
                      <div className="table-header-item">
                        <h5 className="table-heading">Date</h5>
                      </div>
                    </div>
                    <div className="table-body-wrapper">
                      {loader.map((value, index) => (
                        <div className="desktop-activityLoader-wrapper table-body" key={index + count}>
                          <div className="table-body-item">
                            <Skeleton count={1} height={35} width="100%" />
                          </div>
                          <div className="table-body-item">
                            <Skeleton count={1} height={60} width={60} />
                            <Skeleton count={1} height={35} width="100%" />
                          </div>
                          <div className="table-body-item">
                            <Skeleton count={1} height={35} width="100%" />
                          </div>

                          <div className="table-body-item">
                            <Skeleton
                              count={1}
                              height={35}
                              width="100%"
                              key={index + count}
                            />
                          </div>
                          <div className="table-body-item">
                            <Skeleton
                              count={1}
                              height={35}
                              width="100%"
                              key={index + count}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </SkeletonTheme>
      </div>
    </>
  );
};

export default CollectionActivityLoader;
