import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Accordion from "react-bootstrap/Accordion";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CustomSelect from "./CustomSelect";
import CustomCheckbox from "./CustomCheckbox";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";

const ActivityFilterContents = (props) => {
  const {
    handleFilterDrawerStatus,
    ActiveFilter,
    filterData,
    activityFilteritems,
    toggleActiveFilter,
    handlePaymentTypeChange,
    selectedPaymentFilter,
    handleActivityPaymentFilterChange
  } = props;

  return (
    <>
      <div
        className={`filter-drawer ${
          props.mobile
            ? `mobile ${props.mobileFilterStatus ? "active" : ""}`
            : ""
        }`}
      >
        <div
          className="filter-drawer-heading"
          onClick={() => handleFilterDrawerStatus(false)}
        >
          <div className="heading">
            <FilterListIcon />
            <h6 className="mb-0">Filter</h6>
          </div>
          {props.mobile ? <CloseIcon /> : <ArrowBackIcon />}
        </div>
        <div className="categories-filter">
          <Accordion
            defaultActiveKey={ActiveFilter}
            className="filter-properties"
          >
            <div className="rounded-lg  border-0 accordion-style filter-drawer-heading collapse-filter">
              <Accordion.Toggle
                as={"div"}
                eventKey="categories"
                onClick={() => toggleActiveFilter("payment_type")}
                className={`heading ${
                  ActiveFilter == "payment_type" ? "active" : ""
                }`}
              >
                <FormatListBulletedOutlinedIcon />
                <h6 className="mb-0">Event Type</h6>
                <div className="right-infos">
                  <KeyboardArrowDownIcon />
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="categories">
                <div className="filter-collapse-content">
                  <div className="activity-filter-payment">
                    {activityFilteritems.map((activity) => (
                      <>
                        <div
                          className={`payment-types ${
                            selectedPaymentFilter.filter(
                              (type) => type == activity
                            ).length > 0
                              ? "active"
                              : ""
                          }`}
                          onClick={() => handleActivityPaymentFilterChange(activity)}
                        >
                          <h6 className="mb-0">{activity}</h6>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default ActivityFilterContents;
