import React, { useState, useEffect } from "react";
import { Link, Redirect} from "react-router-dom";
import { Modal, Image, Table } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import {
  nftItemsDeleteStart,
  nftCollectionsDeleteStart,
} from "../../store/actions/NftAction";
import { useHistory } from "react-router";

const DeleteModal = (props) => {

  const history = useHistory();

  const location = {
    pathname: props.singleItem ? `/assets/collection/${props.singleItem.data.nft_collection.nft_collection_unique_id}` : '/my-collection',
  }
  
  useEffect(() => {
    if (
      !props.itemDelete.loading &&
      props.itemDelete.data.code == 113
    ) {
      setTimeout(() => {
        props.handleDeleteModal();
        history.replace(location)
      }, 1000);
    }
  }, [props.itemDelete.data]);

  useEffect(() => {
    if (
      !props.collectionDelete.loading &&
      props.collectionDelete.data.code == 110
    ) {
      setTimeout(() => {
        props.handleDeleteModal();
        history.replace('/my-collection')
      }, 1000);
    }
  }, [props.collectionDelete.data]);

  return (
    <>
      <Modal
        show={props.open}
        onHide={props.handleDeleteModal}
        animation={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="custom-modal cancel-listing-modal"
        centered
      >
        {props.type == "item" ? (
          <>
            <Modal.Header>
              <div className="modal-header-wrapper">
                <h3 className="mb-0 modal-heading-text">Delete your Item</h3>
                <div
                  className="custom-close"
                  onClick={() => props.handleDeleteModal()}
                >
                  <CloseIcon />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="cancel-order-listing-sec">
                <div className="cancel-order-listing-card">
                  <p>Are you sure delete?</p>
                  <button className="default-btn border-radius-5"
                  onClick={() => props.dispatch(nftItemsDeleteStart({nft_collection_item_unique_id : props.singleItem.data.nft_collection_item.nft_collection_item_unique_id}))}>
                    {props.itemDelete.loadingButtonContent == ""
                      ? "Delete"
                      : props.itemDelete.loadingButtonContent}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header>
              <div className="modal-header-wrapper">
                <h3 className="mb-0 modal-heading-text">Delete your listing</h3>
                <div
                  className="custom-close"
                  onClick={() => props.handleDeleteModal()}
                >
                  <CloseIcon />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="cancel-order-listing-sec">
                <div className="cancel-order-listing-card">
                  <div className="cancel-order-listing-flex">
                    <h3>1</h3>
                    <h4> Delete Collection</h4>
                  </div>
                </div>
                <div className="cancel-order-listing-card">
                  <p>Are you sure delete?</p>
                  <button className="default-btn border-radius-5"
                  onClick={() => props.dispatch(nftCollectionsDeleteStart({nft_collection_unique_id : props.singleCollection.data.nft_collection.nft_collection_unique_id}))}>
                    {props.collectionDelete.loadingButtonContent == ""
                      ? "Delete"
                      : props.collectionDelete.loadingButtonContent}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  itemDelete: state.nfts.itemDelete,
  collectionDelete: state.nfts.collectionDelete,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeleteModal);
