import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Image, Form, Button } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import CustomSelect from "../../customComponents/CustomSelect";
import { reportItemsSaveStart } from "../../store/actions/NftAction";
const ReportModal = (props) => {
  const [reasonInput, setReasonInput] = useState("");

  const handlePriceSelect = (event) => {
    const {
      target: { value },
    } = event;
    setReasonInput(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(
      reportItemsSaveStart({
        nft_collection_contract_address: props.contract_address,
        nft_collection_item_token_id: props.token_id,
        reason: reasonInput,
      })
    );
  };

  useEffect(() => {
    if (!props.reportItemsSave.loading && props.reportItemsSave.data) {
      props.handleReportClose();
    }
  }, [props.reportItemsSave.data]);

  return (
    <>
      <Modal
        show={props.open}
        onHide={props.handleReportClose}
        animation={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="custom-modal report-modal"
        centered
      >
        <Form className="report-form" onSubmit={handleSubmit}>
          <Modal.Header>
            <div className="modal-header-wrapper">
              <h3 className="mb-0 modal-heading-text">
                Report this collection
              </h3>
              <div
                className="custom-close"
                onClick={() => props.handleReportClose()}
              >
                <CloseIcon />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="report-modal-info-sec">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>I think this collection is..</Form.Label>
                <CustomSelect
                  value={reasonInput}
                  handleChange={handlePriceSelect}
                  items={[
                    "Fake collection or possible scam",
                    "Explicit and sensitive content",
                    "Spam",
                    "In the wrong category",
                    "Others",
                  ]}
                  placeHolder={"Select a reason"}
                  multiple={false}
                />
              </Form.Group>
              <div className="text-center">
                <Button
                  type="button"
                  className="default-btn border-radius-5"
                  data-dismiss="modal"
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  reportItemsSave: state.nfts.reportItemsSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ReportModal);
