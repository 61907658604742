import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./NewLanding.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import { fetchTrendingNftItemsStart, nftFavouritesSaveStart } from "../../store/actions/NftAction";
import { connect, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "@material-ui/styles";
import { authContext } from "../authprovider/AuthProvider";
import configuration from "react-global-configuration";
import CustomLazyLoad from '../../customComponents/CustomLazyLoad';
import { CHAIN_INFO, SupportedChainIdBySymbol } from "../authprovider/chains";

const ItemCard = ({ item, gridView, ...props }) => {
  const [skipRender, setSkipRender] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useTheme();

  const { auth, loginConnectors, handleConnector, price } =
    useContext(authContext);

  const [favouritesCount, setFavouritesCount] = useState(item.favourites_count);

  const [favouriteStatus, setFavouriteStatus] = useState(item.is_favourite_item);

  const chainName = configuration.get("configData.native_currency_name")
    ? configuration.get("configData.native_currency_name")
    : "collection";

  useEffect(() => {
    if (
      !props.favouriteSave.loading &&
      Object.keys(props.favouriteSave.data).length > 0
    ) {
      if (
        props.favouriteSave.data.data.token_id == item.token_id &&
        props.favouriteSave.data.data.nft_collection_id ==
        item.nft_collection_id
      ) {
        if (props.favouriteSave.data.code == "114") {
          setFavouritesCount(favouritesCount + 1);
          setFavouriteStatus(true);
        } else {
          setFavouritesCount(favouritesCount - 1 < 0 ? 0 : favouritesCount - 1);
          setFavouriteStatus(false);
        }
      }
    }
  }, [props.favouriteSave]);

  const handleLike = (
    contract_address,
    token_id,
    recent_owner_wallet_address
  ) => {
    dispatch(
      nftFavouritesSaveStart({
        nft_collection_contract_address: contract_address,
        nft_collection_item_token_id: token_id,
        recent_owner_wallet_address: recent_owner_wallet_address,
      })
    );
  };

  useEffect(() => {
    setFavouritesCount(item.favourites_count);
  }, [location]);

  const cardRef = useRef(null);

  const [imageHeight, setImageHeight] = useState(null);

  useLayoutEffect(() => {
    setImageHeight(cardRef.current.offsetWidth);
  }, [gridView]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const delayDebounceFn = setTimeout(() => {
      setImageHeight(cardRef.current.offsetWidth);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  };

  return (
    <div className="trending-artwork-card" ref={cardRef}>
      <Link
          to={`/assets/${item.blockchain_type}/${item.nft_collection.nft_collection_unique_id}/${item.nft_collection.contract_address}/${item.token_id}`}
        >
      <div className="trending-artwork-img-sec">
        <CustomLazyLoad
          src={item.picture}
          classes={"trending-artwork-img"}
          placeholderSrc={
            window.location.origin +
            "/assets/images/loader.svg"
          }
        />
      </div>
      <div className="trending-artwork-favorites-sec">
        <Image
          className="trending-artwork-favorites-icon"
          src={favouriteStatus ?
            window.location.origin + "/assets/images/new-landing/fill-heart-icon.svg"
            : window.location.origin + "/assets/images/new-landing/heart-icon.svg"
          }
          onClick={
            auth.authStatus
              ? () =>
                handleLike(
                  item.nft_collection.contract_address,
                  item.token_id,
                  item.owner_of
                    ? item.owner_of
                    : item.user
                      ? item.user.wallet_address
                      : ""
                )
              : () =>
                handleConnector(
                  loginConnectors.find(
                    (connectors) => connectors.name == "MetaMask"
                  ).connectorFunction
                )
          }
        />
        <span>{favouritesCount}</span>
      </div>
      <div className="trending-artwork-middle-sec">
        <h4>{item.name}</h4>
        <div className="trending-artwork-info">
          <p>{item.nft_collection.name}</p>
          <div className="trending-artwork-chain-icon-sec">
            <Image
              className="trending-artwork-chain-icon"
              src={
                CHAIN_INFO[
                  SupportedChainIdBySymbol[
                    item.nft_collection.blockchain_type
                  ]
                ].logo
              }
            />
            {/* <span>Binance</span> */}
          </div>
        </div>
      </div>
      {Number(item.amount) > 0 &&
        item.is_listed == 1 &&
        item.is_listing_started == 1 &&
        <div className="trending-artwork-footer-sec">
          <div className="trending-artwork-price-details"
            onClick={() =>
              history.push(`/assets/${chainName}/${item.nft_collection.nft_collection_unique_id}/${item.nft_collection.contract_address}/${item.token_id}`)
            }>
            <div className="trending-artwork-price-label">
              Price
            </div>
            <p>{Number(
              item.amount
            ).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })} BNB</p>
          </div>
          <div className="trending-artwork-time-sec">
            <Image
              className="trending-artwork-time-icon"
              src={
                window.location.origin + "/assets/images/new-landing/timer-icon.svg"
              }
            />
            <span>{item.list_expired}</span>
          </div>
        </div>
      }
      </Link>
    </div>
  );
}

const TrendingArtwork = (props) => {
  const { auth, loginConnectors, handleConnector, price } =
    useContext(authContext);

  const [showContent, setShowContent] = useState(true);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    props.dispatch(fetchTrendingNftItemsStart());
  }, [auth.authStatus]);

  useEffect(() => {
    if (!skipRender && !props.trendingItems.loading) {
      if (Object.keys(props.trendingItems.data).length == 0 ||
        props.trendingItems.data.nft_collection_items.length == 0) {
        setShowContent(false);
      }
    }
    setSkipRender(false);
  }, [props.trendingItems])


  return (
    <>
      {showContent ?
        <section className="trending-artwork-sec">
          <Container>
            <div className="section-title text-center">
              <h2>Trending Art Works</h2>
              <p>Our unique NFT platform empowers creators with accessible and safe tools. Turn your ideas into NFT’s! We are using a decentralized network </p>
            </div>
            {props.trendingItems.loading ?
              <Row className="margin-top-xl">
                {[...Array(6)].map((val, i) =>
                  <Col md={6} lg={4} xl={4} key={i}>
                    <Skeleton className="top-list-collection-card-loader" />
                  </Col>
                )}
              </Row>
              : <Row className="margin-top-xl">
                {props.trendingItems.data.nft_collection_items &&
                  props.trendingItems.data.nft_collection_items.map((item, index) =>
                    <Col md={6} lg={4} xl={4} key={index}>
                      <ItemCard item={item} favouriteSave={props.favouriteSave} />
                    </Col>
                  )}
              </Row>
            }
          </Container>
        </section>
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  trendingItems: state.nfts.trendingItems,
  favouriteSave: state.nfts.favouriteSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TrendingArtwork);
