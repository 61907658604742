import ethLogo from "./chainLogos/eth.png"
import BinanceLogo from "./chainLogos/binance.png"
import PolygonLogo from "./chainLogos/ploygon.png"
import VelasLogo from "./chainLogos/velas.png"
import AvaxLogo from "./chainLogos/avax.png"

export let SupportedChainId = {
  BINANCE: 56,
  ETHEREUM: 1,
  POLYGON: 137,
  VELAS: 106,
  AVAX: 43114,
};

export const All_supported_ChainIds = Object.values(SupportedChainId).filter(
  (chains) => typeof chains === "number"
);

export const SUPPORTED_RPC_URLS = {
  [SupportedChainId.ETHEREUM]: "https://mainnet.infura.io/v3/",
  [SupportedChainId.POLYGON]: `https://polygon-mainnet.infura.io/v3/295cce92179b4be498665b1b16dfee34`,
  [SupportedChainId.BINANCE]: "https://bsc-dataseed1.ninicoin.io",
  [SupportedChainId.VELAS]: `https://evmexplorer.velas.com/rpc`,
  [SupportedChainId.AVAX]: `https://api.avax.network/ext/bc/C/rpc`,
};

export const CHAIN_INFO = {
  [SupportedChainId.ETHEREUM]: {
    explorer: "https://etherscan.io",
    label: "Ethereum",
    nativeCurrency: { name: "Ethereum", symbol: "ETH", decimals: 18 },
    logo : ethLogo,
    apiId: "ethereum"
  },
  [SupportedChainId.POLYGON]: {
    explorer: "https://polygonscan.com",
    label: "Polygon",
    nativeCurrency: { name: "Polygon Matic", symbol: "MATIC", decimals: 18 },
    logo : PolygonLogo,
    apiId: "matic-network"
  },
  [SupportedChainId.BINANCE]: {
    explorer: "https://bscscan.com",
    label: "Binance",
    nativeCurrency: { name: "Binance", symbol: "BNB", decimals: 18 },
    logo : BinanceLogo,
    apiId: "binancecoin"
  },
  [SupportedChainId.VELAS]: {
    explorer: "https://evmexplorer.velas.com",
    label: "Velas",
    nativeCurrency: { name: "Velas", symbol: "VLX", decimals: 18 },
    logo : VelasLogo,
    apiId: "velas"
  },
  [SupportedChainId.AVAX]: {
    explorer: "https://snowtrace.io",
    label: "Avax",
    nativeCurrency: { name: "Avax", symbol: "AVAX", decimals: 18 },
    logo : AvaxLogo,
    apiId: "avalanche-2"
  },
};

export let SupportedChainIdBySymbol = {
  ETH: 1,
  MATIC: 137,
  BNB: 56,
  VLX: 106,
  AVAX: 43114,
};

export const SELECTED_CHAIN_INFO = (chainId) => CHAIN_INFO[chainId];
