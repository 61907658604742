import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Col } from "react-bootstrap";
import { useTheme } from "@material-ui/core";

const ExploreCardLoader = (props) => {
  const { count, explore } = props;

  const loader = Array.from(Array(count).keys());

  const theme = useTheme();

  return (
    <>
      <SkeletonTheme
        baseColor={theme.loader.primary}
        highlightColor={theme.loader.secondary}
      >
        {loader.map((value, index) => (
          <Col
            md={6}
            lg={4}
            className={`explore-card-loader ${
              props.classes ? props.classes : ""
            }`}
            key={index}
          >
            <div className="explore-card-wrapper">
              <Skeleton
                count={1}
                className="explore-cover-image-loader"
                height={250}
              />

              <div className="explore-user-details">
                <div className="explore-user-image-loader">
                  <Skeleton
                    count={1}
                    circle={true}
                    className="explore-user-image"
                  />
                </div>
                <div className="explore-user-info-loader">
                  <Skeleton
                    count={1}
                    width={"80%"}
                    height={31}
                    className="user-name"
                  />
                  <Skeleton count={1} width={40} height={33} />
                  <Skeleton count={1} width={100} height={21} />
                </div>
                {explore && (
                  <div className="explore-card-description">
                    <Skeleton
                      count={1}
                      width={"100%"}
                      height={50}
                      borderRadius={"0.5rem"}
                    />
                  </div>
                )}
              </div>
            </div>
          </Col>
        ))}
      </SkeletonTheme>
    </>
  );
};

export default ExploreCardLoader;
