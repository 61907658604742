import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    // top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "1px solid #e5e8eb",
    borderRadius: "5px",
    backgroundColor: theme.palette.background.paper,
  },
}));

const CustomCollectionSearch = (props) => {
  const classes = useStyles();

  const {setFilterDataContents , filterDataContents} = props;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.value.length > 0) {
      props.dispatch(
        props.dispatchAction({ ...props.dispatchData, search_key: props.value })
      );

      if(props.clearData){
        props.clearData("")
        setFilterDataContents({
          ...filterDataContents,
          search_key : props.value,
          isActive :  filterDataContents.max_amount > 0 ||  filterDataContents.min_amount > 0 || filterDataContents.properties.length > 0 ||  filterDataContents.sort_by.length > 0 ||  filterDataContents.categories.length > 0  || props.value != "",
        })
      }
    }
  };

  return (
    <>
      <div className={`nav-widget-form ${props.classes}`}>
        <form className="search-form" onSubmit={(event) => handleSubmit(event)}>
          <div className="search-icon">
            <SearchIcon />
          </div>
          {/* <input
              value={searchKey}
              type="input"
              className="form-control"
              placeholder="Search items, Creators and Accounts"
              onChange={(event) => onChange(event.target.value)}
            /> */}
          <InputGroup className="mb-0">
            <FormControl
              value={props.value}
              placeholder="Search items"
              aria-label="Search items"
              aria-describedby="basic-addon2"
              onChange={(event) => props.onChange(event.target.value)}
            />
            <Button variant="primary" type="submit">
              <SearchIcon />
            </Button>
          </InputGroup>

        </form>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
  nftSearch: state.nfts.nftSearch,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CustomCollectionSearch);
