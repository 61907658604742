import React, { useState, useEffect } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./NewLanding.css";
import { Link } from "react-router-dom";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWallet";
import {
  fetchNftCollectionsListForOthersStart
} from "../../store/actions/NftAction";
import { connect } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../../customComponents/CustomLazyLoad";
import NoDataFound from '../NoDataFound/NoDataFound';

const TopListCollection = (props) => {
  const [sortByDays, setSortByDays] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const [showContent, setShowContent] = useState(true);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    props.dispatch(
      fetchNftCollectionsListForOthersStart({ sort_by_days: sortByDays })
    );
  }, [sortByDays]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!skipRender && !props.collections.loading) {
      if (Object.keys(props.collections.data).length > 0) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    }
    setSkipRender(false);
  }, [props.collections]);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <>
      {showContent
        ? <section className="top-list-sec">
          <Container>
            <div className="section-title text-center">
              <h2>Top List Collections</h2>
              <p>Our unique NFT platform empowers creators with accessible and safe tools. Turn your ideas into NFT’s! We are using a decentralized network </p>
            </div>
            <Row className="margin-top-xl">
              <Col md={12}>
                <Tab.Container id="left-tabs-example">
                  <div className="top-list-nav-sec text-center">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link
                          className={sortByDays === 1 ? "active" : ""}
                          onClick={() => setSortByDays(1)}>
                          Last 24 Hours <AccountBalanceWalletOutlinedIcon />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className={sortByDays === 7 ? "active" : ""}
                          onClick={() => setSortByDays(7)}>
                          Last 7 Days <AccountBalanceWalletOutlinedIcon />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className={sortByDays === 30 ? "active" : ""}
                          onClick={() => setSortByDays(30)}>
                          Last 30 Days <AccountBalanceWalletOutlinedIcon />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  {props.collections.loading
                    ? <div className="top-list-collection-content">
                      <div className="top-list-collection-box">
                        <Row className="margin-top-xl">
                          {[...Array(6)].map((val, i) =>
                            <Col md={6} lg={4} xl={4} key={i}>
                              <Skeleton className="top-list-collection-card-loader" />
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                    : props.collections.data.nft_collections ?
                      <div className="top-list-collection-content">
                        <div className="top-list-collection-box">
                          <Row className="margin-top-xl">
                            {props.collections.data.nft_collections.length > 0 ?
                              props.collections.data.nft_collections.map((collection, index) =>
                                <Col md={6} lg={4} xl={4} key={index}>
                                  <div className="top-list-collection-card">
                                    <Link to={`/collection/${collection.nft_collection_unique_id}`}>
                                      <div className="top-list-collection-img-sec">
                                        <CustomLazyLoad
                                          src={collection.cover}
                                          classes={"top-list-collection-img"}
                                          placeholderSrc={
                                            window.location.origin +
                                            "/assets/images/loader.svg"
                                          }
                                        />
                                      </div>
                                      <div className="top-list-collection-thumbnail-card">
                                        {collection.nft_items.map((nftItem, j) =>
                                          <CustomLazyLoad
                                            src={nftItem.picture}
                                            classes={"top-list-collection-thumbnail-img"}
                                            placeholderSrc={
                                              window.location.origin +
                                              "/assets/images/loader.svg"
                                            }
                                          />
                                        )}
                                      </div>
                                      <div className="top-list-collection-info">
                                        {/* <h4>Otherdeed for Otherside</h4> */}
                                        <div className="top-list-collection-user-details">
                                          <div className="top-list-collection-user-info">
                                            <div className="top-list-collection-user-img-sec">
                                              <Image
                                                className="top-list-collection-user-img"
                                                src={collection.picture}
                                              />
                                              <span>{collection.name}</span>
                                              {collection.is_verified == 1 &&
                                                <Image
                                                  className="top-list-collection-verified-icon"
                                                  src={
                                                    window.location.origin + "/assets/images/new-landing/verified-badge.svg"
                                                  }
                                                />
                                              }
                                            </div>
                                            <div className="top-list-collection-total-items">
                                              {collection.total_items} Items
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </Col>
                              )
                              : <NoDataFound />
                            }
                          </Row>
                        </div>
                      </div>
                      : null
                  }
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </section>
        : null
      }
    </>
  );
};


const mapStateToPros = (state) => ({
  collections: state.nfts.collectionListForOthers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TopListCollection);
