import React from "react";
import { useLayoutEffect, useState, useEffect } from "react";
import { Calendar } from "react-date-range";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";

const styles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },

  calenderWrapper: {},

  timePicker: {
    width: "100%",
  },
}));

const CustomCalander = (props) => {
  const {
    handleEndTimeChange,
    value,
    handleDateSelect,
    withTime,
    useTimeValue,
    isTimeError,
    timeValue,
    showMonthArrow
  } = props;

  const [width, setWidth] = useState(window.innerWidth);

  const classes = styles({ classes: props.className, width: width });

  var maxDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 10
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <>
      <Box className={classes.calenderWrapper}>
        <div
          className={`range-calender ${width < 1200 ? "mobile" : "desktop"}`}
        >
          <Calendar
            onChange={handleDateSelect}
            date={new Date(value)}
            minDate={new Date()}
            maxDate={maxDate}
            showMonthAndYearPickers={false}
            showDateDisplay={false}
            showMonthArrow={true}
          />
          {withTime && (
            <div className="time-picker">
              <div
                className={`custom-timeinput ${
                  isTimeError ? (isTimeError ? "invalid-field" : "") : ""
                }`}
              >
                <TextField
                  id="time"
                  label="End time"
                  type="time"
                  value={ useTimeValue ? timeValue == "" ? "--:--" : timeValue : "00:00"}
                  className={classes.timePicker}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  onChange={(event) => handleEndTimeChange(event.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export default React.memo(CustomCalander);
