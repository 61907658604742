import { useTheme } from "@material-ui/core";
import React, { useState, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import NoDataFound from "../components/NoDataFound/NoDataFound";
import AcceptOfferModal from "../components/Modals/AcceptOfferModal";
import CancelOfferModal from "../components/Modals/CancelOfferModal";
import { authContext } from "../components/authprovider/AuthProvider";
import CustomInfiniteScroll from "../customComponents/CustomInfiniteScroll";
import { loadMoreOfferListStart } from "../store/actions/NftAction";
import { connect } from "react-redux";
import { CHAIN_INFO, SupportedChainIdBySymbol } from "../components/authprovider/chains";

const OfferListTable = (props) => {
  const { auth } = useContext(authContext);

  const theme = useTheme();

  const [acceptOfferModal, setAcceptOfferModal] = useState(false);

  const [cancelOfferModal, setCancelOfferModal] = useState(false);

  const [approveOfferStatus, setApproveOfferStatus] = useState("confirm");

  const handleAcceptOfferClose = () => setAcceptOfferModal(false);

  const handleCancelOfferClose = () => setCancelOfferModal(false);

  const [acceptOfferInput, setAcceptOfferInput] = useState({
    nft_collection_item_unique_id: "",
    buyer_id: 0,
    amount: 0,
  });

  const [cancelOfferInput, setCancelOfferInput] = useState({
    nft_item_offer_id: 0,
  });

  const handleAcceptOfferModalOpen = () => {
    setAcceptOfferModal(true);
  };

  const handleCancelOfferModalOpen = () => {
    setCancelOfferModal(true);
  };

  const acceptOffer = (event, offer) => {
    event.preventDefault();
    setAcceptOfferInput({
      nft_collection_item_unique_id: props.singleNftImage.nft_collection_item
        ? props.singleNftImage.nft_collection_item.nft_collection_item_unique_id
        : "",
      buyer_id: offer.user_id,
      amount: offer.amount,
    });
    setApproveOfferStatus("confirm");
    setAcceptOfferModal(true);
  };

  const cancelOffer = (event, offer) => {
    event.preventDefault();
    setCancelOfferInput({
      nft_item_offer_id: offer.nft_item_offer_id,
    });
    setCancelOfferModal(true);
  };

  return props.bidOfferNftItemList.data.nft_item_offers &&
  props.bidOfferNftItemList.data.nft_item_offers.length > 0 ? (
    <>
      <CustomInfiniteScroll
        fetchAction={loadMoreOfferListStart}
        fetchData={props.bidOfferNftItemList}
        fetchedData={props.bidOfferNftItemList.data.nft_item_offers}
        skip={props.bidOfferNftItemList.data.nft_item_offers.length}
        take={6}
        skippedFetch={true}
        total={props.bidOfferNftItemList.data.total}
        dispatchData={{
          nft_collection_item_token_id:
            props.singleNftImage.nft_collection_item.token_id,
          nft_collection_contract_address:
            props.singleNftImage.nft_collection.contract_address,
        }}
        useOffset={50}
        parentOverflow={true}
        //overflowClassName="offer-table-scroll"
      >
        <TableContainer
          className="offer-table-scroll"
          style={{
            backgroundColor: "rgb(251,253,255)",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Expiration</TableCell>
                <TableCell align="center">From</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.bidOfferNftItemList.data.nft_item_offers.map((offer, index) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    <div className="etherum-img offertable">
                      <img src={
                CHAIN_INFO[
                  SupportedChainIdBySymbol[
                    props.singleNftImage.nft_collection.blockchain_type
                  ]
                ].logo
              } />
                      <span>{offer.amount}</span>
                      <span>{offer.bid_status == 2 ? "(Expired)" : offer.bid_status == 3 ? "(Cancelled)" :  ""}</span>
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {offer.expired_at_formatter}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link to={
                              props.singleNftImage.nft_collection_item.recent_buyer_id ===
                              auth.userId
                                ? `/profile`
                                : `/account/${offer.user ? offer.user.user_unique_id : "" }`
                            }>
                      {offer.user ? offer.user_id === auth.userId ? "You" : offer.user.wallet_address_formatted : "N/A"}
                    </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {props.singleNftImage.nft_collection_item
                      .recent_buyer_id === auth.userId &&
                    index == 0 &&
                    offer.bid_status == 1 ? (
                      <button
                        className="default-btn two border-radius-5"
                        onClick={(event) => {
                          handleAcceptOfferModalOpen();
                          acceptOffer(event, offer);
                        }}
                      >
                        Accept
                      </button>
                    ) : offer.is_cancel_access == 1 && index == 0 && offer.bid_status != 3 ? (
                      <button
                        className="default-btn two border-radius-5"
                        onClick={(event) => {
                          handleCancelOfferModalOpen();
                          cancelOffer(event, offer);
                        }}
                      >
                        Cancel
                      </button>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomInfiniteScroll>
      <AcceptOfferModal
        open={acceptOfferModal}
        handleAcceptOfferClose={handleAcceptOfferClose}
        singleNftImage={props.singleNftImage}
        acceptOfferInput={acceptOfferInput}
        setApproveOfferStatus={setApproveOfferStatus}
        approveOfferStatus={approveOfferStatus}
        getCurrentOwner={props.getCurrentOwner}
        approveOfferSave={props.approveOfferSave}
        setAcceptOfferBtnContent={props.setAcceptOfferBtnContent}
        acceptOfferBtnContent={props.acceptOfferBtnContent}
      />
      <CancelOfferModal
        open={cancelOfferModal}
        handleCancelOfferClose={handleCancelOfferClose}
        singleNftImage={props.singleNftImage}
        cancelOfferInput={cancelOfferInput}
        getCurrentOwner={props.getCurrentOwner}
        cancelOfferSave={props.cancelOfferSave}
        cancelOfferBtnContent={props.cancelOfferBtnContent}
        setCancelOfferBtnContent={props.setCancelOfferBtnContent}
      />
    </>
  ) : (
    <NoDataFound />
  );
};

const mapStateToPros = (state) => ({
  bidOfferNftItemList: state.nfts.bidOfferNftItemList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros , mapDispatchToProps)(OfferListTable);
