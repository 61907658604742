import React, { useEffect, useState } from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { connect } from "react-redux";
import FilterContents from "./FilterContents";
import CustomDrawer from "./CustomDrawer";
import { getErrorNotificationMessage } from "../components/helper/ToastNotification";
import { createNotification } from "react-redux-notify";

const CustomCollectionFilter = (props) => {
  const { propertiesList, categories , activityTable , filterDataContents } = props;

  const [ActiveFilter, setActiveFilter] = useState("");

  const [mobileStatus, setMobileStatus] = useState(window.innerWidth);

  const [skipIntialRender, setSkipInitalRender] = useState(false);

  const toggleActiveFilter = (id) => {
    if (ActiveFilter === id) {
      setActiveFilter(null);
    } else {
      setActiveFilter(id);
    }
  };

  const [filterData, setFilterData] = useState({
    sort_by:  [],
    categories: [],
    properties: [],
  });

  const [priceRangeFilter, setPriceRangeFilter] = useState({
    min_amount:  "",
    max_amount:  "",
  });

  const handlePriceSortChange = (value) => {
    setFilterData({
      ...filterData,
      sort_by: [value],
    });
  };

  const handlePriceRangeFilterChange = (event) => {

    if(event.target.name == "min_amount"){

      if(priceRangeFilter.max_amount == "" ){
        setPriceRangeFilter({
          ...priceRangeFilter,
          [event.target.name]: event.target.value,
        });
      } else if(event.target.value < priceRangeFilter.max_amount) {

        setPriceRangeFilter({
          ...priceRangeFilter,
          [event.target.name]: event.target.value,
        });
      }
    }

    if(event.target.name == "max_amount"){
      if(event.target.value == ""){
        setPriceRangeFilter({
          ...priceRangeFilter,
          [event.target.name]: event.target.value,
        });
      }else{
        setPriceRangeFilter({
          ...priceRangeFilter,
          [event.target.name]: event.target.value,
        });
      }
    }



  };

  const handleCategorySelect = (category) => {

    const itemExist = filterData.categories.filter(
      (selected) => selected == category
    );

    if (itemExist.length == 0) {
      setFilterData({
        ...filterData,
        categories: [...filterData.categories, category],
      });
    } else {
      setFilterData({
        ...filterData,
        categories: filterData.categories.filter(
          (cat) => cat != category
        ),
      });
    }
  };

  const handlePropertySelect = (value, propertyName) => {

    const itemExist = filterData.properties.filter(
      (selected) => selected.name == propertyName
    );

    if (itemExist.length > 0) {
      const isValueExist = itemExist[0].value.includes(value);

      const remainging_count = itemExist[0].value.filter(
        (existedValue) => existedValue != value
      ).length;
      if (remainging_count == 0) {
        const newData = filterData.properties.filter(
          (properties) => properties.name != propertyName
        );
        setFilterData({
          ...filterData,
          properties: newData,
        });
      } else {
        if (isValueExist) {
          const newData = filterData.properties.map((properties, index) => {
            if (properties.name === propertyName) {
              return {
                ...properties,
                value: properties.value.filter(
                  (existedValue) => existedValue != value
                ),
              };
            }
            return properties;
          });
          setFilterData({
            ...filterData,
            properties: newData,
          });
        } else {
          const newData = filterData.properties.map((properties, index) => {
            if (properties.name === propertyName) {
              return { ...properties, value: [...properties.value, value] };
            }
            return properties;
          });
          setFilterData({
            ...filterData,
            properties: newData,
          });
        }
      }
    } else {
      let newSelected = { name: propertyName, value: [value] };
      setFilterData({
        ...filterData,
        properties: [...filterData.properties, newSelected],
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setMobileStatus(window.innerWidth);
  };

  useEffect(() => {
    if (skipIntialRender) {
      props.setFilterDataContents({
        ...props.filterDataContents,
        properties: filterData.properties.length > 0 ? filterData.properties : [],
        isActive :  filterData.properties.length > 0 ||  filterData.sort_by.length > 0 ||  filterData.categories.length > 0 || priceRangeFilter.min_amount > 0 || priceRangeFilter.max_amount > 0 || props.filterDataContents.search_key != "",
        clearAll : false
      })
    }
    setSkipInitalRender(true);
  }, [filterData.properties]);

  useEffect(() => {
    if (skipIntialRender) {
      props.setFilterDataContents({
        ...props.filterDataContents,
        categories: filterData.categories.length > 0 ? filterData.categories : [],
        isActive :  filterData.properties.length > 0 ||  filterData.sort_by.length > 0 ||  filterData.categories.length > 0 || priceRangeFilter.min_amount > 0 || priceRangeFilter.max_amount > 0 || props.filterDataContents.search_key != "",
        clearAll : false
      })
    }
  }, [filterData.categories]);

  useEffect(() => {
    if (skipIntialRender) {
        props.dispatch(
          props.fetchAction({
            ...props.dispatchData,
            properties: filterData.properties.length > 0 ? JSON.stringify(filterData.properties) : "",
            min_amount: priceRangeFilter.min_amount,
            max_amount: priceRangeFilter.max_amount,
            sort_by:
              filterData.sort_by.length > 0 ? filterData.sort_by[0].id : "",
            search_key : props.filterDataContents.search_key,
            categories: filterData.categories.length > 0 ? JSON.stringify(filterData.categories.map(category => category.category_id)) : "",
          })
        );
      }
  },[filterData.properties , filterData.categories])

  const handlePriceSortyby = () => {
    props.setFilterDataContents({
      ...props.filterDataContents,
      sort_by: filterData.sort_by.length > 0 ? filterData.sort_by: [],
      min_amount: priceRangeFilter.min_amount,
      max_amount: priceRangeFilter.max_amount,
      isActive :  filterData.properties.length > 0 ||  filterData.sort_by.length > 0 ||  filterData.categories.length > 0 || priceRangeFilter.min_amount > 0 || priceRangeFilter.max_amount > 0 || props.filterDataContents.search_key != "",
      clearAll : false
    })
    if(filterData.sort_by.length > 0 && (priceRangeFilter.max_amount != "" || Number(priceRangeFilter.max_amount) > 0)){
      props.dispatch(
        props.fetchAction({
          ...props.dispatchData,
          sort_by: filterData.sort_by.length > 0 ? filterData.sort_by[0].id : "",
          min_amount: priceRangeFilter.min_amount,
          max_amount: priceRangeFilter.max_amount,
          categories: filterData.categories.length > 0 ? JSON.stringify(filterData.categories.map(category => category.category_id)) : "",
          properties: filterData.properties.length > 0 ? JSON.stringify(filterData.properties) : "",
          search_key : props.filterDataContents.search_key
        })
      );
    }
    else if(priceRangeFilter.max_amount != "" && Number(priceRangeFilter.max_amount) <= 0){
      const notificationMessage = getErrorNotificationMessage(
        "Maximum amount should be greater than 0"
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  useEffect(() => {
    setFilterData({
      ...filterData,
      sort_by : props.priceSort,
    });
    props.setFilterDataContents({
      ...props.filterDataContents,
      isActive :  filterData.properties.length > 0 ||  props.priceSort.length > 0 ||  filterData.categories.length > 0 || priceRangeFilter.min_amount > 0 || priceRangeFilter.max_amount > 0 || props.filterDataContents.search_key != "",
      clearAll : false
    })
  },[props.priceSort])

  useEffect(() => {
  
    props.setFilterDataContents({
      ...props.filterDataContents,
      isActive :  filterDataContents.max_amount > 0 ||  filterDataContents.min_amount > 0 || filterData.properties.length > 0 ||  props.priceSort.length > 0 ||  filterData.categories.length > 0  || props.filterDataContents.search_key != "",
    })

    setPriceRangeFilter({
      ...priceRangeFilter,
      min_amount : filterDataContents.min_amount,
      max_amount : filterDataContents.max_amount
    })

    return () => filterDataContents

  },[filterDataContents.min_amount , filterDataContents.max_amount])

  useEffect(() => {
    if(filterDataContents.propertyRemoved.count > 0){
      handlePropertySelect(filterDataContents.propertyRemoved.data.value.toString() , filterDataContents.propertyRemoved.data.name)
    }
  },[filterDataContents.propertyRemoved.count])

  useEffect(() => {
    if(filterDataContents.categoryRemoved.count > 0){
      handleCategorySelect(filterDataContents.categoryRemoved.data)
    }

  },[filterDataContents.categoryRemoved.count])

  useEffect(() => {
    if(filterDataContents.clearAll){
      setFilterData({
        ...filterData,
        properties: [],
        categories : []
      });
    }
  },[filterDataContents.clearAll])

  useEffect(() => {
    props.setFilterDataContents({
      ...props.filterDataContents,
      isActive :  filterDataContents.max_amount > 0 ||  filterDataContents.min_amount > 0 || filterData.properties.length > 0 ||  props.priceSort.length > 0 ||  filterData.categories.length > 0  || props.filterDataContents.search_key != "",
    })
  },[filterDataContents.search_key])

  useEffect(() => {
    if(filterDataContents.queryParsed){
      setFilterData(filterDataContents)
    }
  },[filterDataContents.queryParsed])

  return (
    <>
      <div className="desktop-filter-wrapper">
        {props.filterStatus ? (
          <>
            <FilterContents
              handleFilterDrawerStatus={props.handleFilterDrawerStatus}
              ActiveFilter={ActiveFilter}
              filterData={filterData}
              handlePriceSortChange={handlePriceSortChange}
              priceRangeFilter={priceRangeFilter}
              handlePriceRangeFilterChange={handlePriceRangeFilterChange}
              categories={categories}
              propertiesList={propertiesList}
              handleCategorySelect={handleCategorySelect}
              handlePropertySelect={handlePropertySelect}
              toggleActiveFilter={toggleActiveFilter}
              mobileFilterStatus={props.mobileFilterStatus}
              showproperties={props.showproperties}
              handlePriceSortyby={handlePriceSortyby}
              activityTable={activityTable}
            />
          </>
        ) : (
          <>
            <div
              className="filter-opener"
              onClick={() => props.handleFilterDrawerStatus(true)}
            >
              <ArrowForwardIcon />
            </div>
          </>
        )}
      </div>

      <div className="filter-drawer mobile">
        {mobileStatus < 768 && (
          <div className="mobile-drawer">
            <CustomDrawer sidebarState={props.mobileFilterStatus}>
              <FilterContents
                handleFilterDrawerStatus={props.handleMobileFilterDrawerStatus}
                ActiveFilter={ActiveFilter}
                filterData={filterData}
                handlePriceSortChange={handlePriceSortChange}
                priceRangeFilter={priceRangeFilter}
                handlePriceRangeFilterChange={handlePriceRangeFilterChange}
                categories={categories}
                propertiesList={propertiesList}
                handleCategorySelect={handleCategorySelect}
                handlePropertySelect={handlePropertySelect}
                toggleActiveFilter={toggleActiveFilter}
                mobile={mobileStatus < 768 ? true : false}
                mobileFilterStatus={props.mobileFilterStatus}
                showproperties={props.showproperties}
                handlePriceSortyby={handlePriceSortyby}
                activityTable={activityTable}
                
              />
            </CustomDrawer>
            <button
              className="default-btn two border-radius-5 mobile-drawer-toggler"
              onClick={() =>
                props.handleMobileFilterDrawerStatus(!props.mobileFilterStatus)
              }
            >
              Filter <FilterListIcon />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CustomCollectionFilter);
