import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const selectStyles = makeStyles((theme) => ({
  drawerPaper: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,

    "& .MuiMenu-list": {
      padding: 0,

      "& .MuiMenuItem-root": {
        padding: "1rem",
        color: theme.palette.text.primary,
        fontSize: "0.85rem",
        fontWeight: "700 !important",
        lineHeight : "1",
        borderBottom : `1px solid ${theme.palette.border.primary}`,
        backgroundColor: "transparent",

        "&:hover" :{
          boxShadow : "rgba(4 ,17, 29 , 0.25) 0px 0px 8px 0px",
          backgroundColor: "transparent",
        }

      },
    },
  },

  formcontrol: {
    width: "100%",
    backgroundColor : "transparent",
    height: "fit-content",
    fontFamily: "Poppins, sans-serif",

    "& .MuiOutlinedInput-notchedOutline": {
      top: "0",
      borderColor: theme.palette.divider,
    },

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline" : {
      borderColor: theme.palette.divider
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" : {
      borderColor: theme.palette.divider
    },

    "& .MuiSelect-selectMenu" : {
      fontFamily: "Poppins, sans-serif",

      "& em" : {
        fontStyle : "normal !important"
      }

    },

    "& .MuiSelect-select:focus" : {
      backgroundColor: "transparent"
    }
  },
  select: {
    width: "100%",
    maxHeight: "50px",
  },
}));

function getStyles(name, value, theme) {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const CustomSelect = (props) => {
  const theme = useTheme();

  const classes = selectStyles();

  return (
    <>
      <FormControl sx={{ m: 1, width: 300 }} className={classes.formcontrol}>
        {props.idBased ? (
          <Select
            displayEmpty
            value={props.value}
            onChange={(e) => props.handleChange(e.target.value)}
            id="select"
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>{props.placeHolder}</em>;
              }

              return selected.map((selected) => selected.name);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: classes.drawerPaper },
            }}
            inputProps={{ "aria-label": "Without label" }}
            className={classes.select}
            multiple={props.multiple ? props.multiple : false}
            defaultValue=""
          >
            {props.items.length > 0 ? (
              props.items.map((item) => [
                <MenuItem
                  key={item[`${props.nameField}`]}
                  value={item}
                  name="hello"
                  style={getStyles(item[`${props.valueField}`], props.value, theme)}
                >
                  {item.name}
                </MenuItem>,
              ])
            ) : (
              <MenuItem value={props.noDataMessage} disabled>
                {props.noDataMessage}
              </MenuItem>
            )}
          </Select>
        ) : (
          <Select
            displayEmpty
            value={props.value}
            onChange={props.handleChange}
            id="demo-multiple-checkbox"
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>{props.placeHolder}</em>;
              }
              return selected.join(", ");
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: classes.drawerPaper },
            }}
            inputProps={{ "aria-label": "Without label" }}
            className={classes.select}
            multiple={props.multiple ? props.multiple : false}
          >
            {props.items.length > 0 ? (
              props.items.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, props.value, theme)}
                >
                  {name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={props.noDataMessage} disabled>
                {props.noDataMessage}
              </MenuItem>
            )}
          </Select>
        )}
      </FormControl>
    </>
  );
};

export default CustomSelect;
