import React from 'react'
import { Link , useHistory} from "react-router-dom";

const TooManyRequest = () => {

  const history = useHistory()

  const handleRetry = () => {
    history.go(0)
  }

  return (
    <>
    <div className="error-area pages-padding">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="error-content">
              <div className="">
                <img src={window.location.origin + "/assets/images/went-wrong.png"} alt="" />
              </div>
              <h3 className='m-0'>Something went wrong.</h3>
              <p>
                Please try again after some time.
              </p>
              <button type="button" className="default-btn" onClick={handleRetry}>
                Retry
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TooManyRequest