import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageIcon from "@material-ui/icons/Image";
import ListIcon from "@material-ui/icons/List";
import AddIcon from "@material-ui/icons/Add";
import CustomSelect from "../../customComponents/CustomSelect";
import AddPropertiesModal from "./AddPropertiesModal";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  fetchNftCollectionsListStart,
  nftItemsSaveStart,
  clearNftCollectionList,
} from "../../store/actions/NftAction";
import { authContext } from "../authprovider/AuthProvider";
import { useHistory } from "react-router";
import NftToken from "../../abis/NftToken.json";
import Web3 from "web3";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import { Switch } from "@material-ui/core";
import { CHAIN_INFO } from "../authprovider/chains";

const queryString = require("query-string");

//Declare IPFS
const auth =
  "Basic " +
  Buffer.from(
    apiConstants.ipfs_project_id + ":" + apiConstants.ipfs_project_secret
  ).toString("base64");
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
}); // leaving out the arguments will default to these values

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");

const CreateNewItem = (props) => {

  const { auth } = useContext(authContext);

  const history = useHistory();

  const selectedCollection = queryString.parse(props.location.search);

  const [collectionSelect, setCollectionSelect] = useState([]);

  const [collectionContractAddress, setCollectionContractAddress] =
    useState("");

  const [propertiesModal, setPropertiesModal] = useState({
    status: false,
    data: [],
  });

  const [collectionItemData, setCollectionItemData] = useState({
    name: "",
    description: "",
    properties: [],
    external_link: "",
    nft_collection_id: "",
    is_property_save: false,
    item_type: "1",
    is_minted: 0,
    collection_type: 1,
    deployed_wallet_address: "",
  });

  const [image, setImage] = useState({
    file: "",
    preview_image: "",
  });

  const [propertiesSwapper, setPropertiesSwapper] = useState([]);

  const [nftContractData, setNftContractData] = useState("");

  const [img, setImg] = useState("");

  const [mintButtonContent, setMintButtonContent] = useState("");

  const [initialLoading, setInitialLoading] = useState(false);

  const [termsAgreed, setTermsAgreed] = useState(false)

  useEffect(() => {
    props.dispatch(clearNftCollectionList());
    setInitialLoading(true);
    if (!auth.loading && auth.accounts != "" && auth.authStatus) {
      props.dispatch(fetchNftCollectionsListStart());
    }
  }, [auth.authStatus]);

  useEffect(() => {
    if (
      !auth.loading &&
      auth.accounts != "" &&
      auth.authStatus &&
      collectionContractAddress
    ) {
      connectBlockchain();
    }
  }, [auth.authStatus, collectionContractAddress]);

  useEffect(() => {
    if (Object.keys(selectedCollection).length > 0 && initialLoading) {
      if (!props.collectionList.loading) {
        let collectionType = collectionItemData.collection_type;
        let deployedWalletAddress = collectionItemData.deployed_wallet_address;
        const selectedCollectionId =
          props.collectionList.data.nft_collections.find(
            (data) =>
              data.nft_collection_id ===
              Number(selectedCollection.nft_collection_unique_id)
          );

        setCollectionSelect([selectedCollectionId]);
        if (selectedCollectionId && selectedCollectionId.contract_address) {
          setCollectionContractAddress(selectedCollectionId.contract_address);
          collectionType = selectedCollectionId.collection_type;
          deployedWalletAddress = selectedCollectionId.deployed_wallet_address;
        }

        setCollectionItemData({
          ...collectionItemData,
          nft_collection_id: Number(
            selectedCollection.nft_collection_unique_id
          ),
          collection_type: collectionType,
          deployed_wallet_address: deployedWalletAddress,
        });
      }
    }
  }, [props.collectionList.data]);

  const createNftItemschema = Yup.object().shape({
    picture: Yup.mixed().required("Image is required"),
    name: Yup.string().required("Collection name is required"),
    external_link: Yup.string().required("Link is required"),
  });

  const handleNetworkSelect = (value) => {
    setCollectionSelect([value]);
    setCollectionItemData({
      ...collectionItemData,
      nft_collection_id: value.nft_collection_id ? value.nft_collection_id : "",
      collection_type: value.collection_type ? value.collection_type : 1,
    });
    setCollectionContractAddress(value.contract_address);
    connectBlockchain();
  };

  const handlePropertiesModalOpen = () => {
    setPropertiesSwapper(collectionItemData.properties);
    if (!collectionItemData.is_property_save) {
      handleNewProperty();
    }
    setPropertiesModal({
      ...propertiesModal,
      status: true,
    });
  };

  const handlePropertiesModalClose = () => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.filter(
    //     (collection) => collection.name != "" && collection.value != ""
    //   ),
    // });

    setPropertiesSwapper([]);

    setPropertiesModal({
      ...propertiesModal,
      status: false,
    });
  };

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      setCollectionItemData({
        ...collectionItemData,
        picture: file,
      });
      reader.readAsDataURL(file);
    }
  };

  // const handleAddImageRemove = () => {
  //   setImage({
  //     ...image,
  //     file: "",
  //     preview_image: "",
  //   });
  // };

  const handleSubmit = (values) => {
    const newData = {
      ...values,
      properties: JSON.stringify(values.properties),
      picture: image.file,
      nft_collection_id:
        collectionSelect.length > 0
          ? collectionSelect[0].nft_collection_id
          : "",
      item_type: collectionSelect.length > 0
        ? collectionSelect[0].collection_type
        : collectionItemData.item_type,
      blockchain_type:
        collectionSelect.length > 0 ? collectionSelect[0].blockchain_type : "",
      deployed_wallet_address:
        collectionSelect.length > 0
          ? collectionSelect[0].deployed_wallet_address
          : "",
    };

    if (collectionItemData.item_type == "1") {
      mintYourNFT(newData);
    } else {
      props.dispatch(nftItemsSaveStart(newData));
    }
  };

  const handleNewProperty = () => {
    let newPropety = {
      name: "",
      value: "",
      nft_value_id: "",
    };
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: [...collectionItemData.properties, newPropety],
    // });

    setPropertiesSwapper([...propertiesSwapper, newPropety]);
  };

  const handlePropertyNameChange = (index, value) => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.map(
    //     (collection, collectionIndex) => {
    //       if (index === collectionIndex) {
    //         return {
    //           ...collection,
    //           name: value,
    //         };
    //       }
    //       return collection;
    //     }
    //   ),
    // });
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            name: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyValueChange = (index, value) => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.map(
    //     (collection, collectionIndex) => {
    //       if (index === collectionIndex) {
    //         return {
    //           ...collection,
    //           value: value,
    //         };
    //       }
    //       return collection;
    //     }
    //   ),
    // });
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            value: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyDelete = (removeCollection) => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.filter(
    //     (collection) => collection != removeCollection
    //   ),
    // });
    setPropertiesSwapper(
      propertiesSwapper.filter((collection) => collection != removeCollection)
    );
  };

  const handlePropertySave = () => {

    const saveProperties = propertiesSwapper.filter(
      (collection) => collection.name.trim() != "" && collection.value.trim() != ""
    )

    setCollectionItemData({
      ...collectionItemData,
      properties: saveProperties,
      is_property_save: saveProperties.length > 0,
    });
    handlePropertiesModalClose();
  };

  const handleSocialLinkChanges = (event) => {
    setCollectionItemData({
      ...collectionItemData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (
      !props.itemSave.loading &&
      Object.keys(props.itemSave.data).length > 0
    ) {
      const delayDebounceFn = setTimeout(() => {
        history.push(
          `/assets/${props.itemSave.data.nft_collection.blockchain_type}/${props.itemSave.data.nft_collection.nft_collection_unique_id}/${props.itemSave.data.nft_collection.contract_address}/${props.itemSave.data.nft_collection_item.token_id}`
        );
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [props.itemSave.data]);

  // Blockchain code minting.

  // Connect blockchain.
  const connectBlockchain = async () => {
    // NFT contract.
    const web3 = window.web3;
    let nftContractData;
    const NFTData = NftToken.networks[auth.chainId];
    if (NFTData) {
      nftContractData = await new web3.eth.Contract(
        NftToken.abi,
        collectionContractAddress
      );
      setNftContractData(nftContractData);
    } else {
      window.alert("NFT contract not deployed to detected network.");
    }
  };

  const convertDataURIToBinaryFF = (dataURI) => {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var raw = window.atob(dataURI.substring(base64Index));
    return Uint8Array.from(
      Array.prototype.map.call(raw, function (x) {
        return x.charCodeAt(0);
      })
    );
  };

  const mintYourNFT = async (newData) => {
    if (
      CHAIN_INFO[auth.chainId].nativeCurrency.symbol !=
      newData.blockchain_type
    ) {
      const notificationMessage = getErrorNotificationMessage(
        `Collection is not deployed in the detected network. Please switch to ${newData.blockchain_type}`
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }
    console.log("current data", collectionItemData);
    setMintButtonContent("Initiated...");
    if (nftContractData != null) {
      // fetch data from api.
      try {
        setMintButtonContent((prevState) => "Connecting to Blockchain");

        let imageData = await convertDataURIToBinaryFF(image.preview_image);

        imageData = Buffer(imageData);

        let allWords = "";
        collectionItemData.properties.map((property) => {
          allWords = allWords + " " + property.value;
        });
        console.log("Allwords", allWords);

        //adding file to the IPFS

        ipfs.add(imageData, async (error, result) => {
          console.log("Ipfs result", result);
          if (error) {
            console.error(error);
            return;
          }
          setImg(result[0].hash);

          const json = generateJson({
            name: collectionItemData.name,
            description: collectionItemData.description,
            imageHash: result[0].hash,
            properties: collectionItemData.properties,
            allWords: allWords,
          }).then(async (val) => {
            try {
              // const cid = await ipfsJson.add(val);
              // const tokenURIHash = await ipfsJson.cat(cid);
              val = Buffer(val);
              const cidArr = await ipfs.add(val);
              const cid = cidArr[0].hash;
              console.log("cid", cid);
              console.log("minter address", auth.accounts);
              console.log("all words", allWords);
              if (collectionItemData.collection_type == 1) {
                nftContractData.methods
                  .mint(
                    auth.accounts,
                    "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
                    allWords
                  )
                  .send({ from: auth.accounts })
                  .on("error", (error) => {
                    let notificationMessage;
                    if (error.message == undefined) {
                      notificationMessage =
                        "Same Wallet can't have more than 2 NFT! Use different wallet address";
                    } else {
                      notificationMessage = error.message;
                    }
                    setMintButtonContent("");
                    console.log("Send notification.");
                    // Send fail notification.
                  })
                  .once("receipt", (receipt) => {
                    // sendTransactionApi(
                    //   receipt.transactionHash,
                    // );
                    console.log(
                      "Receipt",
                      receipt.events.Transfer.returnValues.tokenId
                    );
                    const updateData = {
                      ...newData,
                      transaction_hash: receipt.transactionHash,
                      token_id: receipt.events.Transfer.returnValues.tokenId,
                      is_minted: 1,
                      token_uri: "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
                    };
                    props.dispatch(nftItemsSaveStart(updateData));
                  });
              } else {
                const updateData = {
                  ...newData,
                  token_uri: "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
                  is_minted: 0,
                  wallet_address: collectionItemData.deployed_wallet_address,
                };
                props.dispatch(nftItemsSaveStart(updateData));
              }
            } catch (error) {
              setMintButtonContent("");
              // Send fail notification.
              const notificationMessage = getErrorNotificationMessage(
                "Something went wrong. Please refresh the page and try again."
              );
              props.dispatch(createNotification(notificationMessage));
              console.log(error);
            }
          });
        });
      } catch (error) {
        setMintButtonContent("");
        console.log("Error", error);
        const notificationMessage =
          "Something went wrong. Please refresh the page and try again.";
        console.log("Send notification.");
        // Send fail notification.
      }
    } else {
      setMintButtonContent("");
      // Send fail notification.
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const sendTransactionApi = (transactionHash) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        transaction_id: transactionHash,
        wallet_address: auth.accounts,
      }),
    };
    try {
      fetch(
        "https://api.nft-generator.online/api/user/nft_images_complete",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            const notificationMessage = "Minted Successfully";
            // window.location.reload();
          } else {
            const notificationMessage = "Saving data error..";
          }
        });
    } catch (error) {
      const notificationMessage =
        "Something went wrong. Please refresh the page and try again.";
    }
  };

  // Generate metadata json file.
  const generateJson = async (data) => {
    let attributes = [];

    data.properties.map((property) =>
      attributes.push({ trait_type: property.name, value: property.value })
    );
    console.log("Attributes", attributes);
    const metadata = JSON.stringify({
      description: data.description,
      external_url: data.external_link,
      image: "https://nftmeta.infura-ipfs.io/ipfs/" + data.imageHash,
      name: data.name,
      text: data.allWords,
      attributes,
    });
    console.log("Json", metadata);
    return metadata;
  };

  //prevent reload

  useEffect(() => {
    if (mintButtonContent != "") {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [mintButtonContent]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleTermsChange = () => {
    setTermsAgreed(prev => !prev)
  }

  return (
    <>
      <div className="create-new-item pages-padding">
        <div className="collection-widget-area">
          <div className="container">
            <div className="row g-0">
              <div className="col-lg-9 mx-auto">
                <div className="collection-form-area">
                  <div className="collection-form no-shadow">
                    <div className="section-title">
                      <h2>Create new item</h2>
                    </div>
                    <Formik
                      initialValues={{
                        ...collectionItemData,
                        picture: image.file,
                      }}
                      className="w-100"
                      validationSchema={createNftItemschema}
                      onSubmit={(values) => handleSubmit(values)}
                      enableReinitialize
                    >
                      {({
                        errors,
                        touched,
                        setFieldValue,
                        values,
                        isSubmitting,
                      }) => (
                        <Form>
                          <div className="profile-outer">
                            <h3>
                              Image <span class="text-danger">*</span>{" "}
                            </h3>
                            <p>
                              File types supported: jpg, png, jpeg, bmp, gif. Max size:5
                              MB
                            </p>

                            <div className="profileButton custom-input">
                              {image.preview_image != "" && (
                                <div className="preview-image">
                                  <img src={image.preview_image} alt="" />
                                </div>
                              )}
                              <input
                                className="profileButton-input "
                                type="file"
                                name="picture"
                                accept="image/*"
                                id="upload"
                                onChange={(event) =>
                                  handleAddImageChange(event)
                                }
                              />
                              <label
                                className="profileButton-button ripple-effect"
                                htmlFor="upload"
                              >
                                <ImageIcon />
                              </label>
                            </div>
                            <ErrorMessage
                              component="div"
                              name="picture"
                              className="invalid-feedback mt-3"
                            />
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div class="form-group ">
                              <h5>
                                Name <span class="text-danger">*</span>
                              </h5>
                              <div class="controls">
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Item name"
                                  className={`form-control ${touched.name && errors.name
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div class="form-group ">
                              <h5>Description</h5>
                              <div class="controls">
                                <textarea
                                  type="text"
                                  name="description"
                                  placeholder="Description"
                                  className={`form-control`}
                                  value={collectionItemData.description}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <label>
                                External Link <span class="text-danger">*</span>{" "}
                              </label>
                              <p>
                                Marketplace will include a link to this URL on
                                this item's detial page, so that users can click
                                to learn more about it. You are welcome to link
                                to your own webpage with more details.
                              </p>
                              <Field
                                type="text"
                                name="external_link"
                                placeholder="http://yoursite.io/item/123"
                                className={`form-control ${touched.external_link && errors.external_link
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={(event) =>
                                  handleSocialLinkChanges(event)
                                }
                              />
                              <ErrorMessage
                                component="div"
                                name="external_link"
                                className="invalid-feedback mt-3"
                              />
                            </div>
                          </div>
                          <div className="select-collection">
                            <h3>Collection </h3>
                            {props.collectionList.loading ? null : (
                              <CustomSelect
                                value={collectionSelect}
                                handleChange={handleNetworkSelect}
                                items={
                                  props.collectionList.data.nft_collections
                                }
                                placeHolder={"Select Collection"}
                                idBased
                                nameField={"name"}
                                valueField={"contract_address"}
                                noDataMessage={"No Collection Found"}
                              />
                            )}
                          </div>
                          <div className="create-properties-wrapper">
                            <div className="create-properties">
                              <div className="properties-para">
                                <div className="properties-icon">
                                  <ListIcon />
                                </div>
                                <div className="properties-text">
                                  <h3>Properties</h3>
                                  <p>
                                    textual traits that show up as rectangles
                                  </p>
                                </div>
                              </div>
                              <div className="properties-btn">
                                <button
                                  className="btn no-color primaryicon icon create-btn"
                                  type="button"
                                  onClick={() => handlePropertiesModalOpen()}
                                >
                                  <AddIcon />
                                </button>
                              </div>
                            </div>
                            {collectionItemData.properties.length > 0 && (
                              <>
                                <div className="properties-list single-page-properties">
                                  {collectionItemData.properties.map(
                                    (collectionProperty, index) => (
                                      <>
                                        {collectionProperty.name != "" &&
                                          collectionProperty.value != "" && (
                                            <div className="properties properties-panel text-center ">
                                              {" "}
                                              <small>
                                                {collectionProperty.name}
                                              </small>{" "}
                                              <h6>
                                                {collectionProperty.value}
                                              </h6>
                                            </div>
                                          )}
                                      </>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {/* <div className="col-lg-12 col-md-12 mb-4">
                            <div class="collection-details-list">
                              <div class="collection-details-list-item">
                                <div class="collection-details-list-content">
                                  <i class="Iconreact__Icon-sc-1gugx8q-0 irnoQt EventHistory--icon material-icons EventHistory--icon" value="child_friendly" size="24">child_friendly</i>
                                  <div class="collection-details-list-main">
                                    <span class="collection-details-list-label">Lazy Minting</span>
                                    <p class="collection-details-list-header">Buyer will pay gas fees for minting.</p>
                                  </div>
                                </div>
                                <div class="collection-details-list-side">
                                  <Switch
                                    name="item_type"
                                    value={collectionItemData.item_type == "2" ? "1" : "2"}
                                    checked={collectionItemData.item_type == "2"}
                                    onChange={(event) =>
                                      handleSocialLinkChanges(event)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div className="custom-checkbox mb-4 mt-5">
                            <div class="check-field">
                              <input id="my-check" type="checkbox" checked={termsAgreed} onChange={() => handleTermsChange()} />
                              <label for="my-check">
                                I agree to the following{" "}
                                <Link to="/page/terms" target="_blank">terms & conditions</Link>
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12 create-btn">
                            <button
                              type="submit"
                              className="default-btn border-radius-5 mt-4"
                              disabled={
                                props.itemSave.buttonDisable ||
                                mintButtonContent != "" ||
                                !termsAgreed
                              }
                            >
                              {mintButtonContent != ""
                                ? "Minting please wait..."
                                : "Create Item"}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddPropertiesModal
        show={propertiesModal.status}
        onHide={handlePropertiesModalClose}
        properties={propertiesSwapper}
        handleNewProperty={handleNewProperty}
        handlePropertyNameChange={handlePropertyNameChange}
        handlePropertyValueChange={handlePropertyValueChange}
        handlePropertyDelete={handlePropertyDelete}
        handlePropertySave={handlePropertySave}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  collectionList: state.nfts.collectionList,
  itemSave: state.nfts.itemSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateNewItem);
