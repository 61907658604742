import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import WalletConnectProvider from "@walletconnect/web3-provider";
import configuration from "react-global-configuration";
import {
  All_supported_ChainIds,
  SUPPORTED_RPC_URLS,
  CHAIN_INFO,
  SELECTED_CHAIN_INFO,
} from "./chains";
import Web3 from "web3";

const POLLING_INTERVAL = 12000

// export const MetaMask = new InjectedConnector({ supportedChainIds : [4]})

export const useMetaMaskConnector = () => {

  const MetaMask =  new InjectedConnector({ supportedChainIds : All_supported_ChainIds})

  return {MetaMask}

}

// export const walletconnect = new WalletConnectConnector({
//   rpc: { 1: process.env.RPC_URL_4 },
//   qrcode: true,
//   bridge: "https://bridge.walletconnect.org",
//   pollingInterval: POLLING_INTERVAL
// })


//velas mainnet

export const MetaMask = new InjectedConnector({
  supportedChainIds: All_supported_ChainIds,
});

export const walletConnectProvider = new WalletConnectProvider({
  rpc: SUPPORTED_RPC_URLS,
  chainId: 1,
  qrcode: true,
});

export const walletconnect = new WalletConnectConnector({
  rpc: SUPPORTED_RPC_URLS,
  supportedChainIds: All_supported_ChainIds,
  qrcode: false,
  
});
// walletconnect.networkId = 56;