import React,{useState , useEffect , useRef} from "react";
import ActivityFilterContents from "./ActivityFilterContents";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CustomDrawer from "./CustomDrawer";

const CollectionActivityFilter = (props) => {

  const {setFilterWidth , getFilterWidth}  = props;

  const [ActiveFilter, setActiveFilter] = useState("");

  const [mobileStatus, setMobileStatus] = useState(window.innerWidth);

  const [filterData, setFilterData] = useState({
    
  });

  const filterRef = useRef(null);

  const toggleActiveFilter = (id) => {
    if (ActiveFilter === id) {
      setActiveFilter(null);
    } else {
      setActiveFilter(id);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setMobileStatus(window.innerWidth);
    if( getFilterWidth && window.innerWidth > 768){
      setFilterWidth(filterRef.current.offsetWidth)
     }else{
       setFilterWidth(null)
     }
  };

  useEffect(() => {
    if( getFilterWidth && window.innerWidth > 768){
     setFilterWidth(filterRef.current.offsetWidth)
    }else{
      setFilterWidth(null)
    }
  },[props.filterStatus])

  return (
    <>
      <div className="desktop-filter-wrapper" ref={filterRef}>
        {props.filterStatus ? (
          <>
            <ActivityFilterContents
              handleFilterDrawerStatus={props.handleFilterDrawerStatus}
              filterData={filterData}
              ActiveFilter={ActiveFilter}
              toggleActiveFilter={toggleActiveFilter}
              mobileFilterStatus={props.mobileFilterStatus}
              activityFilteritems={[
                "List",
                "Sale",
                "Cancel",
                "Transfer",
              ]}
              selectedPaymentFilter={props.selectedPaymentFilter}
              handleActivityPaymentFilterChange={props.handleActivityPaymentFilterChange}

            />
          </>
        ) : (
          <>
            <div
              className="filter-opener"
              onClick={() => props.handleFilterDrawerStatus(true)}
            >
              <ArrowForwardIcon />
            </div>
          </>
        )}

        <div className="filter-drawer mobile">
          {mobileStatus < 768 && (
            <div className="mobile-drawer">
              <CustomDrawer sidebarState={props.mobileFilterStatus}>
                <ActivityFilterContents
                  handleFilterDrawerStatus={props.handleMobileFilterDrawerStatus}
                  filterData={filterData}
                  ActiveFilter={ActiveFilter}
                  toggleActiveFilter={toggleActiveFilter}
                  mobile={mobileStatus < 768 ? true : false}
                  mobileFilterStatus={props.mobileFilterStatus}
                  activityFilteritems={[
                    "List",
                    "Sale",
                    "Cancel",
                    "Transfer",
                  ]}
                  selectedPaymentFilter={props.selectedPaymentFilter}
                  handleActivityPaymentFilterChange={props.handleActivityPaymentFilterChange}
                />
              </CustomDrawer>
              <button
                className="default-btn two border-radius-5 mobile-drawer-toggler"
                onClick={() =>
                  props.handleMobileFilterDrawerStatus(
                    !props.mobileFilterStatus
                  )
                }
              >
                Filter <FilterListIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CollectionActivityFilter;
