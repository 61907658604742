import React , {useState , useRef , useEffect, useLayoutEffect} from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";

const CollectionCardLoader = (props) => {
  const { count , classes , gridView } = props;

  const loader = Array.from(Array(count).keys());

  const theme = useTheme();

  const cardRef = useRef(null);

  const [imageHeight, setImageHeight] = useState(null);

  useLayoutEffect(() => {
    setImageHeight(cardRef.current.offsetWidth);
  }, [gridView]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const delayDebounceFn = setTimeout(() => {
      setImageHeight(cardRef.current.offsetWidth);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  };


  return (
    <>
      {loader.map((value, index) => (
        <div className={`collection-card-loader ${classes ? classes : ""}`} key={index} ref={cardRef}>
          <SkeletonTheme baseColor={theme.loader.primary} highlightColor={theme.loader.secondary}>
            <Skeleton count={1} className="collection-img-loader" width={imageHeight} height={imageHeight} />
            <div className="collection-details-loader">
              <Skeleton count={1} height={20} />
              <Skeleton count={1} height={20} />
              <Skeleton count={1} height={20} className="full-width" />
            </div>
            <div className="collection-card-footer-loader">
              <Skeleton
                count={1}
                height={23}
                width={30}
                borderRadius={"0.5em"}
              />
              <Skeleton
                count={1}
                height={23}
                width={30}
                borderRadius={"0.5em"}
              />
            </div>
          </SkeletonTheme>
        </div>
      ))}
    </>
  );
};

export default CollectionCardLoader;
