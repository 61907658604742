import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";

const CustomInfiniteScroll = (props) => {
  const [isFetching, setIsFetching] = useState(false);

  const [initialRender, seTinitialRender] = useState(false);

  const [loadMore, setLoadMore] = useState(props.skip != props.total);

  const initalRenderRef = useRef();

  initalRenderRef.current = initialRender;

  const setScrollHeight = () => {
    const targetHeight =
      document.getElementById("InfiniteScroller").clientHeight;
    const difference = document.scrollingElement.scrollHeight - targetHeight;

    const offsetHeight = props.useOffset ? props.useOffset : 300;

    // dont delete this is for reference
    // window.scrollY + window.innerHeight > (document.scrollingElement.scrollHeight - 200) for full page scroll length
    //window.scrollY + window.innerHeight - difference > targetHeight - 200 for element target height based

    if (
      window.scrollY + window.innerHeight - difference >
      targetHeight - offsetHeight
    ) {
      if (initalRenderRef && !props.parentOverflow) {
        if (props.skippedFetch) {
          if (loadMore) {
            setIsFetching(true);
          }
        } else {
          setIsFetching(true);
        }
      }
    }
  };

  useEffect(() => {
    seTinitialRender(true);

    window.addEventListener("scroll", setScrollHeight);

    return () => {
      window.removeEventListener("scroll", setScrollHeight);
    };
  }, []);

  useEffect(() => {
    if (!props.fetchData.loading) {
      setIsFetching(false);
      if (props.fetchedData.length == props.total) {
        setLoadMore(false);
      }
    }
  }, [props.fetchData.data]);

  const overflowedParentHeight = () => {
    const targetHeight =
      document.getElementById("InfiniteScroller").scrollHeight;
    const difference = document.getElementById("InfiniteScroller").clientHeight;
    const scrolledheight =
      document.getElementById("InfiniteScroller").scrollTop + difference;

    const offsetHeight = props.useOffset ? props.useOffset : 50;

    if (scrolledheight >= targetHeight - offsetHeight) {
      if (initalRenderRef) {
        if (props.skippedFetch) {
          if (loadMore) {
            setIsFetching(true);
          }
        } else {
          setIsFetching(true);
        }
      }
    }
  };

  useEffect(() => {
    if (isFetching) {
      if (initialRender) {
        if (props.skippedFetch) {
          if (loadMore) {
            if (props.dispatchData) {
              console.log("overflow", "dispatch");
              props.dispatch(
                props.fetchAction({
                  skip: props.skip,
                  take: props.take,
                  ...props.dispatchData,
                })
              );
            } else {
              props.dispatch(
                props.fetchAction({ skip: props.skip, take: props.take })
              );
            }
          }
        } else {
          props.dispatch(props.fetchAction());
        }
      }
    }
  }, [isFetching]);

  return (
    <>
      <div
        id="InfiniteScroller"
        className={`${props.overflowClassName ? props.overflowClassName : ""}`}
        onScroll={props.parentOverflow ? overflowedParentHeight : null}
      >
        {props.children}
        {isFetching && loadMore && (
          <>
            {props.useLoader ? (
              <div className={props.loaderclasses}>{props.loaderComponent}</div>
            ) : (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  padding: "2em 0",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(CustomInfiniteScroll);
