import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";

const EditItemLoader = () => {

  const theme = useTheme()

  return (
    <>
      <div className="collection-form no-shadow">
        <div className="section-title">
          <h2>Edit item</h2>
        </div>
        <div className="edit-collection-loader">
          <SkeletonTheme baseColor={theme.loader.primary} highlightColor={theme.loader.secondary}>
            <div className="logo-loader mb-3">
              <h3>
                Logo image <span class="text-danger">*</span>
              </h3>
              <p>
                This image will also be used for navigation. 350 x 350
                recommended
              </p>
              <div className="logo-image-loader">
                <Skeleton count={1} height={250} width={250} circle={true} />
              </div>
            </div>
            <div className="edit-items-details-loader mb-3">
              <h5>
                Name <span class="text-danger">*</span>
              </h5>
              <Skeleton
                count={1}
                height={50}
                width={"100%"}
                borderRadius={"0.5em"}
              />
            </div>
            <div className="edit-items-details-loader mb-3">
              <h5>External Link </h5>
              <p>
                Marketplace will include a link to this URL on this item's
                detial page, so that users can click to learn more about it. You
                are welcome to link to your own webpage with more details.
              </p>
              <Skeleton
                count={1}
                height={50}
                width={"100%"}
                borderRadius={"0.5em"}
              />
            </div>
            <div className="edit-items-details-loader mb-3">
              <h5>Collection </h5>
              <div className="category-loader">
                <Skeleton
                  count={1}
                  height={44}
                  width={"100px"}
                  borderRadius={"0.5em"}
                />
              </div>
            </div>
            <div className="edit-items-details-loader mb-3">
              <div className="links-loader">
                <Skeleton
                  count={1}
                  height={48}
                  width={"100%"}
                  borderRadius={"0.5em"}
                />
              </div>
            </div>
            <div className="edit-items-details-loader mb-3 mt-4">
              <div className="buttons-loader">
              <Skeleton
                  count={2}
                  height={50}
                  width={"200px"}
                  borderRadius={"0.5em"}
                />
                
              </div>
            </div>
          </SkeletonTheme>
        </div>
      </div>
    </>
  )
}

export default EditItemLoader
