import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import ImageIcon from "@material-ui/icons/Image";
import ListIcon from "@material-ui/icons/List";
import AddIcon from "@material-ui/icons/Add";
import CustomSelect from "../../customComponents/CustomSelect";
import AddPropertiesModal from "./AddPropertiesModal";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  fetchSingleNftItemsStart,
  fetchNftCollectionsListStart,
  nftItemsSaveStart,
  fetchNftItemsListStart,
} from "../../store/actions/NftAction";
import { authContext } from "../authprovider/AuthProvider";
import { useHistory, useParams } from "react-router";
import DeleteModal from "../Modals/DeleteModal";
import EditItemLoader from "../loaders/EditItemLoader";
import configuration from "react-global-configuration";

const EditNewItem = (props) => {

  const chainName = configuration.get("configData.native_currency_name")
  ? configuration.get("configData.native_currency_name")
  : "collection";

  const { auth } = useContext(authContext);

  const history = useHistory();

  const { nft_collection_unique_id, nft_collection_item_unique_id } =
    useParams();

  const [collectionSelect, setCollectionSelect] = useState([]);

  const [propertiesModal, setPropertiesModal] = useState({
    status: false,
    data: [],
  });

  const [collectionItemData, setCollectionItemData] = useState({
    name: "",
    properties: [],
    external_link: "",
    nft_collection_id: "",
    is_property_save: false,
  });

  const [image, setImage] = useState({
    file: "",
    preview_image: "",
  });

  const [propertiesSwapper, setPropertiesSwapper] = useState([]);

  useEffect(() => {
    props.dispatch(
      fetchSingleNftItemsStart({
        nft_collection_item_unique_id: nft_collection_item_unique_id,
      })
    );
  }, []);

  useEffect(() => {
    if (!auth.loading && auth.accounts != "" && auth.authStatus) {
      props.dispatch(fetchNftCollectionsListStart());
    }
  }, [auth.authStatus]);

  const createNftItemschema = Yup.object().shape({
    // picture: Yup.mixed().required("Image is required"),
    name: Yup.string().required("Collection name is required"),
  });

  const handleNetworkSelect = (value) => {
    setCollectionSelect([value]);
    setCollectionItemData({
      ...collectionItemData,
      nft_collection_id: value.nft_collection_id ? value.nft_collection_id : "",
    });
  };

  const handlePropertiesModalOpen = () => {
    setPropertiesSwapper(collectionItemData.properties);
    if (!collectionItemData.is_property_save) {
      handleNewProperty();
    }
    setPropertiesModal({
      ...propertiesModal,
      status: true,
    });
  };

  const handlePropertiesModalClose = () => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.filter(
    //     (collection) => collection.name != "" && collection.value != ""
    //   ),
    // });

    setPropertiesSwapper([]);

    setPropertiesModal({
      ...propertiesModal,
      status: false,
    });
  };

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      setCollectionItemData({
        ...collectionItemData,
        picture: file,
      });
      reader.readAsDataURL(file);
    }
  };

  // const handleAddImageRemove = () => {
  //   setImage({
  //     ...image,
  //     file: "",
  //     preview_image: "",
  //   });
  // };

  const handleSubmit = (values) => {
    const newData = {
      ...values,
      properties: JSON.stringify(values.properties),
      picture: image.file,
      nft_collection_id:
        collectionSelect.length > 0
          ? collectionSelect[0].nft_collection_id
          : "",
    };
    props.dispatch(nftItemsSaveStart(newData));
  };

  const handleNewProperty = () => {
    let newPropety = {
      name: "",
      value: "",
      nft_value_id: "",
    };
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: [...collectionItemData.properties, newPropety],
    // });

    setPropertiesSwapper([...propertiesSwapper, newPropety]);
  };

  const handlePropertyNameChange = (index, value) => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.map(
    //     (collection, collectionIndex) => {
    //       if (index === collectionIndex) {
    //         return {
    //           ...collection,
    //           name: value,
    //         };
    //       }
    //       return collection;
    //     }
    //   ),
    // });
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            name: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyValueChange = (index, value) => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.map(
    //     (collection, collectionIndex) => {
    //       if (index === collectionIndex) {
    //         return {
    //           ...collection,
    //           value: value,
    //         };
    //       }
    //       return collection;
    //     }
    //   ),
    // });
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            value: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyDelete = (removeCollection) => {
    // setCollectionItemData({
    //   ...collectionItemData,
    //   properties: collectionItemData.properties.filter(
    //     (collection) => collection != removeCollection
    //   ),
    // });
    setPropertiesSwapper(
      propertiesSwapper.filter((collection) => collection != removeCollection)
    );
  };

  const handlePropertySave = () => {
    setCollectionItemData({
      ...collectionItemData,
      properties: propertiesSwapper.filter(
        (collection) => collection.name != "" && collection.value != ""
      ),
      is_property_save: true,
    });
    handlePropertiesModalClose();
  };

  const handleSocialLinkChanges = (event) => {
    setCollectionItemData({
      ...collectionItemData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (
      !props.itemSave.loading &&
      Object.keys(props.itemSave.data).length > 0
    ) {
      history.push(
        `/assets/${props.itemSave.data.nft_collection.blockchain_type}/${props.itemSave.data.nft_collection.nft_collection_unique_id}/${props.itemSave.data.nft_collection.contract_address}/${props.itemSave.data.nft_collection_item.token_id}`
      );
    }
  }, [props.itemSave.data]);

  useEffect(() => {
    if (!props.singleItems.loading) {
      if (
        Object.keys(props.singleItems.data.nft_collection).length > 0 &&
        Object.keys(props.singleItems.data.nft_collection_item).length > 0
      ) {

        setCollectionItemData({
          ...collectionItemData,
          name: props.singleItems.data.nft_collection_item.name,
          properties:
            props.singleItems.data.nft_collection_item.properties_formatted,
          nft_collection_id:
            props.singleItems.data.nft_collection_item.nft_collection_id,
          token_id:
            props.singleItems.data.nft_collection_item.token_id,
          external_link:
            props.singleItems.data.nft_collection_item.external_link,
          nft_collection_item_id:
            props.singleItems.data.nft_collection_item.nft_collection_item_id,
          is_property_save:
            props.singleItems.data.nft_collection_item.properties_formatted
              .length > 0,
        });
        setImage({
          ...image,
          file: "",
          preview_image: props.singleItems.data.nft_collection_item.picture,
        });
        if (
          props.singleItems.data.nft_collection_item.nft_collection_id != ""
        ) {
          setCollectionSelect([props.singleItems.data.nft_collection]);
        }
      }
    }
  }, [props.singleItems.data]);

  const [deleteModalStatus, setDeleteModalStatus] = useState(false);

  const handleDeleteModal = (status) => {
    setDeleteModalStatus(status);
  };

  return (
    <>
      {props.singleItems.loading ? (
        <>
          <div className="collection-widget-area pages-padding">
            <div className="container">
              <div className="row g-0">
                <div className="col-lg-9 mx-auto">
                  <div className="collection-form-area">
                    <EditItemLoader />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="create-new-item pages-padding">
            <div className="collection-widget-area">
              <div className="container">
                <div className="row g-0">
                  <div className="col-lg-9 mx-auto">
                    <div className="collection-form-area">
                      <div className="collection-form no-shadow">
                        <div className="section-title">
                          <h2>Edit item</h2>
                        </div>
                        <Formik
                          initialValues={{
                            ...collectionItemData,
                            picture: image.file,
                          }}
                          className="w-100"
                          validationSchema={createNftItemschema}
                          onSubmit={(values) => handleSubmit(values)}
                          enableReinitialize
                        >
                          {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                              <div className="profile-outer">
                                <h3>
                                  Image,Video,Audio, or 3D Model{" "}
                                  <span class="text-danger">*</span>{" "}
                                </h3>
                                <p>
                                  File types supported:
                                  JPG,PNG,JPEG,bmp.Max
                                  size:100 MB
                                </p>

                                <div className="profileButton custom-input">
                                  {image.preview_image != "" && (
                                    <div className="preview-image">
                                      <img src={image.preview_image} alt="" />
                                    </div>
                                  )}
                                  <input
                                    className="profileButton-input "
                                    type="file"
                                    name="picture"
                                    accept="image/*"
                                    id="upload"
                                    onChange={(event) =>
                                      handleAddImageChange(event)
                                    }
                                  />
                                  <label
                                    className="profileButton-button ripple-effect"
                                    htmlFor="upload"
                                  >
                                    <ImageIcon />
                                  </label>
                                </div>
                                <ErrorMessage
                                  component="div"
                                  name="picture"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div class="form-group ">
                                  <h5>
                                    Name <span class="text-danger">*</span>
                                  </h5>
                                  <div class="controls">
                                    <Field
                                      type="text"
                                      name="name"
                                      placeholder="Item name"
                                      className={`form-control ${
                                        touched.name && errors.name
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      value={collectionItemData.name}
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="name"
                                      className="invalid-feedback mt-3"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>External Link </label>
                                  <p>
                                    Marketplace will include a link to this URL
                                    on this item's detial page, so that users
                                    can click to learn more about it. You are
                                    welcome to link to your own webpage with
                                    more details.
                                  </p>
                                  <input
                                    type="text"
                                    name="external_link"
                                    id="external_link"
                                    value={collectionItemData.external_link}
                                    className="form-control"
                                    placeholder="http://yoursite.io/item/123"
                                    onChange={(event) =>
                                      handleSocialLinkChanges(event)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="select-collection">
                                <h3>Collection </h3>
                                {props.collectionList.loading ? null : (
                                  <CustomSelect
                                    value={collectionSelect}
                                    handleChange={handleNetworkSelect}
                                    items={
                                      props.collectionList.data.nft_collections
                                    }
                                    placeHolder={"Select Collection"}
                                    idBased
                                    nameField={"name"}
                                    valueField={"nft_collection_id"}
                                    noDataMessage={"No Collection Found"}
                                  />
                                )}
                              </div>
                              <div className="create-properties-wrapper">
                                <div className="create-properties">
                                  <div className="properties-para">
                                    <div className="properties-icon">
                                      <ListIcon />
                                    </div>
                                    <div className="properties-text">
                                      <h3>Properties</h3>
                                      <p>
                                        textual traits that show up as
                                        rectangles
                                      </p>
                                    </div>
                                  </div>
                                  <div className="properties-btn">
                                    <button
                                      className="btn no-color primaryicon icon create-btn"
                                      type="button"
                                      onClick={() =>
                                        handlePropertiesModalOpen()
                                      }
                                    >
                                      <AddIcon />
                                    </button>
                                  </div>
                                </div>
                                {collectionItemData.properties.length > 0 && (
                                  <>
                                    <div className="properties-list single-page-properties">
                                      {collectionItemData.properties.map(
                                        (collectionProperty, index) => (
                                          <>
                                            {collectionProperty.name != "" &&
                                              collectionProperty.value !=
                                                "" && (
                                                <div className="properties properties-panel text-center ">
                                                  {" "}
                                                  <small>
                                                    {collectionProperty.name}
                                                  </small>{" "}
                                                  <h6>
                                                    {collectionProperty.value}
                                                  </h6>
                                                </div>
                                              )}
                                          </>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="col-lg-12 col-md-12 create-btn mt-4 edit-item-btn">
                                <button
                                  type="submit"
                                  className="default-btn border-radius-5 py-3 px-4 mr-10"
                                  disabled={props.itemSave.buttonDisable}
                                >
                                  {props.itemSave.loadingButtonContent == ""
                                    ? "Save Changes"
                                    : props.itemSave.loadingButtonContent}
                                </button>
                                <a
                                  className="default-btn two border-radius-5 py-3 px-4"
                                  onClick={() => handleDeleteModal(true)}
                                >
                                  Delete Item
                                </a>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddPropertiesModal
            show={propertiesModal.status}
            onHide={handlePropertiesModalClose}
            properties={propertiesSwapper}
            handleNewProperty={handleNewProperty}
            handlePropertyNameChange={handlePropertyNameChange}
            handlePropertyValueChange={handlePropertyValueChange}
            handlePropertyDelete={handlePropertyDelete}
            handlePropertySave={handlePropertySave}
          />
          <DeleteModal
            open={deleteModalStatus}
            handleDeleteModal={handleDeleteModal}
            singleItem={props.singleItems}
            type={"item"}
          />
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  singleItems: state.nfts.singleItems,
  collectionList: state.nfts.collectionList,
  itemSave: state.nfts.itemSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditNewItem);
