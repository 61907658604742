import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./NewLanding.css";
import { Link } from "react-router-dom";
import HowItsWork from "./HowItsWork";
import TopListCollection from "./TopListCollection";
import DiscoverConnect from "./DiscoverConnect";
import TrendingArtwork from "./TrendingArtwork";

const NewLandingPage = (props) => {

  return (
    <>
      <div className="new-landing-sec">
        <div className="banner-area pages-padding">
          <div className="banner-vertical-center">
            <Container>
              <Row className="align-items-center">
                <Col md={7}>
                  <div className="banner-content">
                    <h1>Discover, Collect and <span>Sell Extraordinary NFTs</span></h1>
                    <p>Our unique NFT platform empowers creators with accessible and safe tools. Turn your ideas into NFT’s! We are using a decentralized network to connect creators, artists, and investors. The CRYPTO token will be powering our platform. It will be the default currency used to buy and sell NFT’s.</p>
                    <div className="banner-btn">
                      <Link to={"/explore"} className="default-btn">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="banner-img-sec">
                    <Image
                      className="banner-img"
                      src={
                        window.location.origin + "/assets/images/new-landing/banner-img.png"
                      }
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <HowItsWork />
        <TopListCollection />
        <DiscoverConnect />
        <TrendingArtwork />
      </div>
    </>
  );
};

export default NewLandingPage;
