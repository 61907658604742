import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";

const ProfilerLoader = (props) => {
  const { classes, collection } = props;

  const theme = useTheme();

  return (
    <>
      <div className="profile-loader">
        <SkeletonTheme baseColor={theme.loader.primary} highlightColor={theme.loader.secondary}>
          <div className="profile-banner-section-loader">
            <Skeleton
              count={1}
              className="profile-cover-image-loader"
              height={350}
            />
            <div className="col-12 col-md-12 col-lg-12 mx-auto profile-head">
              <div className="user-details">
                <Skeleton
                  count={1}
                  className="profile-image-loader"
                  height={200}
                  width={200}
                  borderRadius={"50%"}
                />
              </div>
              <div className="links-loader">
                <Skeleton
                  count={5}
                  className="profile-link-loader"
                  height={45}
                  width={45}
                />
              </div>
            </div>
            {collection && (
              <div className="collection-box-loader">
                <Skeleton
                  count={4}
                  className="profile-link-loader"
                  height={89}
                  width={144}
                />
              </div>
            )}
            <div className="desc-loader">
              <Skeleton
                count={1}
                className="profile-desc-loader"
                height={130}
              />
            </div>
            <div className="mobile-links-loader">
              <Skeleton
                count={5}
                className="profile-link-loader"
                height={45}
                width={45}
              />
            </div>
          </div>
        </SkeletonTheme>
      </div>
    </>
  );
};

export default ProfilerLoader;
