import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Image, Media } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close';
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const FavoritedByModal = (props) => {

  return (
    <>
      <Modal show={props.open} onHide={props.handlefavouriteUsersClose} animation={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="custom-modal unreviewed-modal"
        centered>
        <Modal.Header>
          <div className="modal-header-wrapper">
            <h3 className="mb-0 modal-heading-text">Favorited by</h3>
            <div className="custom-close" onClick={() => props.handlefavouriteUsersClose()}>
              <CloseIcon />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="padding-zero">
          {props.itemFavouriteUsers.data.users ? (
            props.itemFavouriteUsers.data.users.length > 0 ? (
              <ul className="list-unstyled favorited-by-sec">
                {props.itemFavouriteUsers.data.users.map((user) => (
                  <Media as="li">
                    <Link to={ user.user_id ===  props.auth.userId ? `/profile` :
                          `/account/${user.user_unique_id}`}>
                      <Image
                        src={user.picture}
                        alt="Image"
                        className="favorited-by-user-img"
                      />
                      <div className="favorited-info">
                        <h5>{user.name}</h5>
                        <p>
                          {user.wallet_address.substr(0, 5)}...
                          {user.wallet_address.substr(user.wallet_address.length - 4)}
                        </p>
                      </div>
                    </Link>
                  </Media>
                ))}
              </ul>
            ) : <NoDataFound />
          ) : <NoDataFound />}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FavoritedByModal;
