import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  FETCH_OTHER_USER_DETAILS_START,
  FETCH_OTHER_USER_DETAILS_SUCCESS,
  FETCH_OTHER_USER_DETAILS_FAILURE,
  UPDATE_SIGN_APPROVE_START,
  UPDATE_SIGN_APPROVE_SUCCESS,
  UPDATE_SIGN_APPROVE_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  profile: {
    data: {},
    loading: false,
    error: false,
  },
  otherProfile : {
    data: {},
    loading: true,
    error: false,
  },
  loginInputData: {
    data: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  updateUserDetails : {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent : "",
    buttonDisable : false
  },
  updateSignApprove : {
    data: {},
    loading: false,
    error: false,
    loadingButtonContent : "",
    buttonDisable : false
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        loginInputData: {
          data: {
            ...action.data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
        loginInputData: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginInputData: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FETCH_USER_DETAILS_START:
      return {
        ...state,
        profile: {
          data: {},
          loading: true,
          error: false,
        },
        updateUserDetails: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent : "",
          buttonDisable : false
        }
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: {},
          loading: false,
          error: true,
        },
      };
    case UPDATE_USER_DETAILS_START:
      return {
        ...state,
        updateUserDetails: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent : "Updating...",
          buttonDisable : true
        },
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        updateUserDetails: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent : "",
          buttonDisable : false
        },
      };
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        updateUserDetails: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent : "",
          buttonDisable : false
        },
      };
    case FETCH_OTHER_USER_DETAILS_START:
      return {
        ...state,
        otherProfile: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_OTHER_USER_DETAILS_SUCCESS:
      return {
        ...state,
        otherProfile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_OTHER_USER_DETAILS_FAILURE:
      return {
        ...state,
        otherProfile: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

      case UPDATE_SIGN_APPROVE_START:
      return {
        ...state,
        updateSignApprove: {
          ...state.updateSignApprove,
          data: {},
          loading: true,
          error: false,
        },
      };
    case UPDATE_SIGN_APPROVE_SUCCESS:
      return {
        ...state,
        updateSignApprove: {
          ...state.updateSignApprove,
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case UPDATE_SIGN_APPROVE_FAILURE:
      return {
        ...state,
        updateSignApprove: {
          ...state.updateSignApprove,
          data: {},
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
