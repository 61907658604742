import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CustomCheckbox = (props) => {
  return (
    <>
      <FormControlLabel
        label={props.label}
        control={
          <Checkbox
            checked={props.checkedStatus}
            value={props.label}
            onChange={props.data ? (event) => props.handleChange(event.target.value , props.data.property) : props.handleChange}
            color="primary"
            // style={{
            //   color: "#8d99ff",
            //   "&.Mui-checked": {
            //     color: "#8d99ff",
            //   },
            // }}
          />
        }
      />
    </>
  );
};

export default CustomCheckbox;
