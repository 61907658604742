import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Accordion from "react-bootstrap/Accordion";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CustomSelect from "./CustomSelect";
import CustomCheckbox from "./CustomCheckbox";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";

const FilterContents = (props) => {
  const {
    handleFilterDrawerStatus,
    ActiveFilter,
    filterData,
    handlePriceSortChange,
    priceRangeFilter,
    handlePriceRangeFilterChange,
    categories,
    propertiesList,
    toggleActiveFilter,
    handlePropertySelect,
    handleCategorySelect,
  } = props;


  return (
    <>
      <div
        className={`filter-drawer ${
          props.mobile
            ? `mobile ${props.mobileFilterStatus ? "active" : ""}`
            : ""
        }`}
      >
        <div
          className="filter-drawer-heading"
          onClick={() => handleFilterDrawerStatus(false)}
        >
          <div className="heading">
            <FilterListIcon />
            <h6 className="mb-0">Filter</h6>
          </div>
          {props.mobile ? <CloseIcon /> : <ArrowBackIcon />}
        </div>
        <div className="price-filter">
          <Accordion
            defaultActiveKey={ActiveFilter}
            className="filter-properties"
          >
            <div className="rounded-lg  border-0 accordion-style filter-drawer-heading collapse-filter">
              <Accordion.Toggle
                as={"div"}
                eventKey="0"
                onClick={() => toggleActiveFilter("0")}
                className={`heading ${ActiveFilter === "0" ? "active" : ""}`}
              >
                <AttachMoneyIcon />
                <h6 className="mb-0">Price</h6>
                <div className="right-infos">
                  <KeyboardArrowDownIcon />
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <div className="filter-collapse-content">
                  {/* <CustomSelect
                    value={filterData.sort_by}
                    handleChange={handlePriceSortChange}
                    items={["Price Low to High", "Price High to Low"]}
                    placeHolder={"Sort by"}
                  /> */}
                  <CustomSelect
                    value={filterData.sort_by}
                    handleChange={handlePriceSortChange}
                    items={[
                      { name: "Newly Added", id: "new" },
                      { name: "Low to high", id: "price_lh" },
                      { name: "High to low", id: "price_hl" },
                      { name: "Buy Now", id: "buy_now" },
                    ]}
                    placeHolder={"Sort by"}
                    idBased
                    nameField={"name"}
                    valueField={"id"}
                  />
                  <div className="price-range-selector">
                    <div className="collection-form">
                      <div className="form-group mb-0">
                        <div className="controls">
                          <input
                            type="number"
                            name="min_amount"
                            placeholder="min"
                            max={priceRangeFilter.max_amount}
                            className="form-control"
                            value={priceRangeFilter.min_amount}
                            onChange={(event) =>
                              handlePriceRangeFilterChange(event)
                            }
                          />
                        </div>
                      </div>
                      To
                      <div className="form-group mb-0">
                        <div className="controls">
                          <input
                            type="number"
                            name="max_amount"
                            placeholder="max"
                            className="form-control"
                            min={priceRangeFilter.min_amount}
                            value={priceRangeFilter.max_amount}
                            onChange={(event) =>
                              handlePriceRangeFilterChange(event)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="default-btn two border-radius-5"
                    onClick={() => props.handlePriceSortyby()}
                    disabled={!((priceRangeFilter.max_amount != "" || Number(priceRangeFilter.max_amount) > 0) || filterData.sort_by.length != 0)}
                  >
                    Apply
                  </button>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
        {!props.showproperties && (
          <div className="categories-filter">
            <Accordion
              defaultActiveKey={ActiveFilter}
              className="filter-properties"
            >
              <div className="rounded-lg  border-0 accordion-style filter-drawer-heading collapse-filter">
                <Accordion.Toggle
                  as={"div"}
                  eventKey="categories"
                  onClick={() => toggleActiveFilter("categories")}
                  className={`heading ${
                    ActiveFilter == "categories" ? "active" : ""
                  }`}
                >
                  <FormatListBulletedOutlinedIcon />
                  <h6 className="mb-0">Categories</h6>
                  <div className="right-infos">
                    {!categories.loading && (
                      <span className="properties-count">
                        {categories.data.categories.length}
                      </span>
                    )}
                    <KeyboardArrowDownIcon />
                  </div>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="categories">
                  <div className="filter-collapse-content">
                    {!categories.loading &&
                      categories.data.categories &&
                      categories.data.categories.length > 0 && (
                        <div className="checkbox-wrapper">
                          {categories.data.categories.map((category) => (
                            <CustomCheckbox
                              label={category.name}
                              handleChange={() =>
                                handleCategorySelect(category)
                              }
                              checkedStatus={
                                filterData.categories.filter(
                                  (cat) => cat.name == category.name
                                ).length > 0
                              }
                            />
                          ))}
                        </div>
                      )}
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
          </div>
        )}

        {props.showproperties && propertiesList && propertiesList.length > 0 && (
          <div className="properites-filter">
            {propertiesList.map((properties, index) => (
              <Accordion
                defaultActiveKey={ActiveFilter}
                className="filter-properties"
                key={index}
              >
                <div className="rounded-lg  border-0 accordion-style filter-drawer-heading collapse-filter">
                  <Accordion.Toggle
                    as={"div"}
                    eventKey={properties.property}
                    onClick={() => toggleActiveFilter(properties.property)}
                    className={`heading ${
                      ActiveFilter === properties.property ? "active" : ""
                    }`}
                  >
                    <FormatListBulletedOutlinedIcon />
                    <h6 className="mb-0">{properties.property}</h6>
                    <div className="right-infos">
                      <span className="properties-count">
                        {properties.nft_values.length}
                      </span>
                      <KeyboardArrowDownIcon />
                    </div>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey={properties.property}>
                    <div className="filter-collapse-content">
                      {properties.nft_values.length > 0 && (
                        <div className="checkbox-wrapper">
                          {properties.nft_values.map((propertyvalue) => (
                            <div className="checkbox-container">
                              <CustomCheckbox
                                label={propertyvalue.value}
                                handleChange={handlePropertySelect}
                                data={properties}
                                checkedStatus={
                                  filterData.properties.length > 0
                                    ? filterData.properties.filter(
                                        (selectedproperty) =>{
                                          if(properties.property == selectedproperty.name){
                                           return selectedproperty.value.includes(propertyvalue.value)
                                          }
                                        }
                                          
                                      ).length > 0 ? true : false
                                    : false
                                }
                              />
                              <p>{propertyvalue.values_count}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(FilterContents);
