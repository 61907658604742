import React, { useState, useEffect , useMemo } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Base from "./components/Base";
import store from "./store";
import { createBrowserHistory as createHistory } from "history";
import "react-redux-notify/dist/ReactReduxNotify.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./scss/style.scss";
import "./scss/responsive.scss";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../src/components/helper/ScrollToTop";
import AuthProvider from "./components/authprovider/AuthProvider";
import "react-loading-skeleton/dist/skeleton.css";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import configuration from "react-global-configuration";
import { apiConstants } from "./components/Constant/constants";
import ThemeChanger from "./components/theme/ThemeChanger";
import TooManyRequest from "./components/404/TooManyRequest";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const history = createHistory();

const App = () => {
  const getLibrary = (provider) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
  };

  const [configLoading, setConfigLoading] = useState(true);

  const [configError, setConfigError] = useState(null);

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") != null
      ? localStorage.getItem("theme")
      : "light"
  );


  useEffect(() => {
    localStorage.setItem("theme", theme);
    if (theme === "dark") {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [theme]);

  const themetoggler = useMemo(
    () => ({
      toggleTheme: () => {
        setTheme((theme) => (theme === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);

      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
      setConfigError(false)
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
      setConfigError(true)
    }
  };

  return (
    <Provider store={store}>
      <Router>
        <Web3ReactProvider getLibrary={getLibrary}>
          {configLoading ? null : configError == null ? null : configError ? (
            <>
            <TooManyRequest />
            </>
          ) : (
            <AuthProvider>
              <ScrollToTop />
              <ThemeChanger theme={theme} themetoggler={themetoggler}>
                <Base />
              </ThemeChanger>
            </AuthProvider>
          )}
        </Web3ReactProvider>
      </Router>
    </Provider>
  );
};

export default App;
