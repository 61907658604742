import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Link } from "react-router-dom";
import { authContext } from "../../authprovider/AuthProvider";
import { Spinner } from "react-bootstrap";
import configuration from "react-global-configuration";
import CustomTooltip from "../../../customComponents/CustomTooltip";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import ChainType from "../../authprovider/ChainType";
import {
  CHAIN_INFO,
} from "../../authprovider/chains";

const sideBarStyles = makeStyles({
  root: {},
  drawer: {
    flexShrink: 0,
    zIndex: "999 !important",
  },
  drawerPaper: {
    paddingTop: (props) => (props.sidebarWidth < 576 ? "67px" : "70px"),
    minWidth: (props) => (props.sidebarWidth < 576 ? "100vw" : "400px"),
    maxWidth: (props) => (props.sidebarWidth < 576 ? "100vw" : "420px"),
    overflow: "visible",
  },
});

const walletOptions = [
  {
    name: "MetaMask",
    logo: "/assets/images/metamask-fox.svg",
    is_popular: true,
  },
  {
    name: "Wallet Connect",
    logo: "/assets/images/wallet-img/wallet-connect (1).png",
    is_popular: false,
  },
];

const Sidebar = (props) => {
  const [sideBarWidth, setSideBarWidth] = useState(window.innerWidth);

  const classes = sideBarStyles({ sidebarWidth: sideBarWidth });

  const { auth, connectWallet, hanldeLogout, context, price } =
    useContext(authContext);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setSideBarWidth(window.innerWidth);
  };

  return (
    <>
      <div>
        <Box
          role="presentation"
          // onClick={props.toggleDrawer(false)}
          // onKeyDown={props.toggleDrawer(false)}
          className={classes.root}
        >
          <Drawer
            anchor={"right"}
            open={props.sidebarState}
            onOpen={() => console.log("")}
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{ onClose: () => props.toggleDrawer(false) }}
            onClose={() => console.log("")}
            disableSwipeToOpen={true}
          >
            {auth.authStatus ? (
              <DrawerAfterLogin
                auth={auth}
                hanldeLogout={hanldeLogout}
                {...props}
                context={context}
                toggleDrawer={props.toggleDrawer}
                price={price}
              />
            ) : (
              <DrawerBeforeLogin
                auth={auth}
                connectWallet={connectWallet}
                toggleDrawer={props.toggleDrawer}
              />
            )}
          </Drawer>
        </Box>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(React.memo(Sidebar));

const DrawerBeforeLogin = ({ auth, connectWallet, toggleDrawer }) => {
  const { loginConnectors, handleConnector, activatingConnector, context } =
    useContext(authContext);

  return (
    <>
      <div className="drawer-before-login-info-wrapper">
        <div className="info">
          <AccountCircleOutlinedIcon />
          My Wallet
          <div className="wallet-drawer-closer">
            <CloseIcon onClick={() => toggleDrawer(false)} />
          </div>
        </div>
      </div>
      <div className="wallet-options-wrapper">
        <h6 className="wallet-option-tooltip">
          Connect with one of our available{" "}
          <strong>
            Wallet <InfoOutlinedIcon />
            <div className="custom-tooltip">
              A crypto wallet is an application or hardware device that allows
              individuals to store and retrieve digital items.
              <Link to="/learn-more">Learn More</Link>
            </div>
          </strong>
          providers or create a new one.
        </h6>
        <div
          className={`wallet-options ${
            activatingConnector != undefined ? "connecting-wallet" : ""
          }`}
        >
          {loginConnectors
            .filter((connectors) => connectors.show)
            .map((connectors, index) => (
              <div
                className={`wallet-options-wrapper ${
                  connectors.isAvailable ? undefined : "disabled"
                }`}
              >
                <div
                  className={`wallet ${
                    connectors.isAvailable ? undefined : "disabled"
                  }`}
                  key={index}
                  //onClick={() => handleConnector(connectors.connectorFunction)}
                >
                  <button
                    disabled={
                      activatingConnector != undefined
                        ? true
                        : false || !connectors.isAvailable
                    }
                    onClick={() =>
                      handleConnector(connectors.connectorFunction)
                    }
                  >
                    <img
                      src={window.location.origin + connectors.logo}
                      alt=""
                    />
                    <h6 className="wallet-name">{connectors.name}</h6>
                    <div className="wallet-info">
                      {connectors.is_popular && <p>Popular</p>}
                      {JSON.stringify(activatingConnector) ===
                        JSON.stringify(connectors.connectorFunction) && (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                        ></Spinner>
                      )}
                    </div>
                  </button>
                </div>
                {!connectors.isAvailable && (
                  <a
                    href={connectors.installUrl}
                    target="_blank"
                    className="install-connector"
                  >
                    Install
                  </a>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const DrawerAfterLogin = ({
  auth,
  hanldeLogout,
  profile,
  context,
  toggleDrawer,
  price,
}) => {
  const theme = useTheme();

  const nativeCurrencyName = configuration.get(
    "configData.native_currency_name"
  )
    ? configuration.get("configData.native_currency_name")
    : "Binance";

  const copyToClipboard = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    getSuccessNotificationMessage(
      "Wallet address copied"
    );
  };
  
  return (
    <>
      <div className="after-login-drawer">
        <div className="info">
          <div className="user-wallet">
            {/* {auth.authStatus ? (
              <>
                {auth.userPicture != null && (
                  <div className="account-image">
                    <img src={auth.userPicture} alt="" />
                  </div>
                )}
              </>
            ) : (
              <AccountCircleOutlinedIcon />
            )}
            My Wallet */}
            <ChainType />
          </div>
          <div className="wallet-address">
            {/* <CustomTooltip placeholder={"copy"} title={"0x6f73...65ba"} /> */}
            <CustomTooltip title="copy" placement="top">
              <h5 className="mb-0" onClick={() => copyToClipboard(auth.accounts)}>
                {auth.accounts.substr(0, 5)}...
                {auth.accounts.substr(auth.accounts.length - 4)}
              </h5>
            </CustomTooltip>
            {/* <h5 className="mb-0">
              {auth.accounts.substr(0, 5)}...
              {auth.accounts.substr(auth.accounts.length - 4)}
            </h5> */}
          </div>
          <div className="wallet-login-drawer-closer">
            <CloseIcon onClick={() => toggleDrawer(false)} />
          </div>
        </div>
        <div className="walet-balance">
          <div className="wallet-balance-wrapper withbutton">
            {/* <h6>{configuration.get("configData.network_token")} Balance</h6> */}
            <h6 className="text-muted">Account Balance</h6>
            <h6 className="text-center">
              {Number(auth.ethBalance).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}{" "}
              {CHAIN_INFO[auth.chainId].nativeCurrency.symbol}
            </h6>
          </div>
          <button
            onClick={() => hanldeLogout()}
            className="default-btn border-radius-5 add-token-btn px-5 py-3"
          >
            Logout
          </button>
        </div>
        {price.usdConvertedPrice != null && (
          <div className="walet-balance-details">
            <div className="walet-balance-details-wrapper ">
              <div className="currency-details">
                <img src={
                CHAIN_INFO[auth.chainId].logo
              } className="price-image" />{" "}
                <div className="currency-info">
                  <h6> {CHAIN_INFO[auth.chainId].nativeCurrency.symbol} </h6>
                  {price.usdConvertedPrice != null && (
                    <p>
                      1{CHAIN_INFO[auth.chainId].nativeCurrency.symbol} ≉{" "}
                      <span>
                        <i className="fa fa-usd" aria-hidden="true"></i>{" "}
                        {price.usdConvertedPrice}
                      </span>{" "}
                    </p>
                  )}
                </div>
              </div>
              <div className="price-details">
                <h6 className="text-end">
                  {Number(auth.ethBalance).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </h6>
                <p className="text-end mb-0">
                  <i className="fa fa-usd" aria-hidden="true"></i>{" "}
                  {price.totalUsdbalaneFormatted} {price.denomination}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
