import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  userLoginSuccess,
  userLoginFailure,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  fetchOtherUserDetailsSuccess,
  fetchOtherUserDetailsFailure,
  updateSignAndApproveSuccess,
  updateSignAndApproveFailure
} from "../actions/UserAction";
import api from "../../Environment";

import {
  FETCH_USER_DETAILS_START,
  FETCH_OTHER_USER_DETAILS_START,
  LOGIN_START,
  UPDATE_USER_DETAILS_START,
  UPDATE_SIGN_APPROVE_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";

function* getUserDetailsAPI() {
  try {
    const response = yield api.postMethod("profile");

    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data));
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem(
        "total_followers",
        response.data.data.total_followers
      );
      localStorage.setItem(
        "total_followings",
        response.data.data.total_followings
      );
      localStorage.setItem(
        "is_subscription_enabled",
        response.data.data.is_subscription_enabled
      );
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
      localStorage.setItem(
        "is_verified_badge",
        response.data.data.is_verified_badge
          ? response.data.data.is_verified_badge
          : 0
      );
      localStorage.setItem(
        "is_content_creator",
        response.data.data.is_content_creator
      );
      localStorage.setItem(
        "default_payment_method",
        response.data.data.default_payment_method
      );
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* userLoginAPI(action) {
  try {

    const response = yield api.postMethod("login", action.data);
    yield put(userLoginSuccess(response.data));
    if (response.data.success) {
      if (response.data.code == 1001)
        window.location.assign("/register-verify");
      else {
        localStorage.setItem("wallet_address", action.data.wallet_address)
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("user_picture", response.data.data.picture);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem(
          "user_unique_id",
          response.data.data.user_unique_id
        );
        // const notificationMessage = getSuccessNotificationMessage(
        //   response.data.message
        // );
        // yield put(createNotification(notificationMessage));
        // window.location.assign("/");
      }
      // localStorage.setItem("userId", response.data.data.user_id);
      // localStorage.setItem("accessToken", response.data.data.token);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* updateUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("update_profile" , action.data);

    if (response.data.success) {
      yield put(updateUserDetailsSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(updateUserDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(updateUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getOtherUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("other_user_profile" , action.data);

    if (response.data.success) {
      yield put(fetchOtherUserDetailsSuccess(response.data));
    } else {
      yield put(fetchOtherUserDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchOtherUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* updateSignApproveAPI(action) {
  try {
    const response = yield api.postMethod("user_sign_approve_update" , action.data);

    if (response.data.success) {
      yield put(updateSignAndApproveSuccess(response.data));
    } else {
      yield put(updateSignAndApproveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(updateSignAndApproveFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
    yield takeLatest(FETCH_OTHER_USER_DETAILS_START, getOtherUserDetailsAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
    yield takeLatest(UPDATE_SIGN_APPROVE_START, updateSignApproveAPI),
  ]);
}
