import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";

const EditCollectionLoader = () => {

  const theme = useTheme()

  return (
    <>
      <div className="collection-form no-shadow">
        <div className="section-title">
          <h2>Edit your collection</h2>
        </div>
        <div className="edit-collection-loader">
          <SkeletonTheme baseColor={theme.loader.primary} highlightColor={theme.loader.secondary}>
            <div className="logo-loader mb-3">
              <h3>
                Logo image <span class="text-danger">*</span>
              </h3>
              <p>
                This image will also be used for navigation. 350 x 350
                recommended
              </p>
              <div className="logo-image-loader">
                <Skeleton count={1} height={250} width={250} circle={true} />
              </div>
            </div>
            <div className="preview-box">
              <h3>Banner image </h3>
              <p>
                This image will appear at the top of your collection page. Aviod
                including too much text in this banner image, as the dimensions
                change on the devices. 1400 x 400 recommended
              </p>

              <div className="cover-image-loader">
                <Skeleton
                  count={1}
                  height={400}
                  width={"100%"}
                  borderRadius={"1em"}
                />
              </div>
            </div>
            <div className="edit-items-details-loader mb-3">
              <h5>
                Name <span class="text-danger">*</span>
              </h5>
              <Skeleton
                count={1}
                height={50}
                width={"100%"}
                borderRadius={"0.5em"}
              />
            </div>
            <div className="edit-items-details-loader mb-3">
              <h5>Description</h5>
              <Skeleton
                count={1}
                height={78}
                width={"100%"}
                borderRadius={"0.5em"}
              />
            </div>
            <div className="edit-items-details-loader mb-3">
              <h5>External Link </h5>
              <p>
                Marketplace will include a link to this URL on this item's
                detial page, so that users can click to learn more about it. You
                are welcome to link to your own webpage with more details.
              </p>
              <Skeleton
                count={1}
                height={50}
                width={"100%"}
                borderRadius={"0.5em"}
              />
            </div>
            <div className="edit-items-details-loader mb-3">
              <h5>Category </h5>
              <p>
                Adding a category will help make your item discoverable on
                Marketplace.
              </p>
              <div className="category-loader">
                <Skeleton
                  count={4}
                  height={44}
                  width={"100px"}
                  borderRadius={"0.5em"}
                />
              </div>
            </div>
            <div className="edit-items-details-loader mb-3">
              <h5>Links </h5>
              <div className="links-loader">
                <Skeleton
                  count={6}
                  height={48}
                  width={"100%"}
                  borderRadius={"0.5em"}
                />
              </div>
            </div>
            <div className="edit-items-details-loader mb-3">
              <h5>Blockchain - Network</h5>
              <div className="select-loader">
                <Skeleton
                  count={1}
                  height={50}
                  width={"100%"}
                  borderRadius={"0.5em"}
                />
              </div>
            </div>
            <div className="edit-items-details-loader mb-3 mt-4">
              <div className="buttons-loader">
              <Skeleton
                  count={2}
                  height={50}
                  width={"200px"}
                  borderRadius={"0.5em"}
                />
              </div>
            </div>
          </SkeletonTheme>
        </div>
      </div>
    </>
  );
};

export default EditCollectionLoader;
