import {
  FETCH_COLLECTIONS_LIST_START,
  FETCH_COLLECTIONS_LIST_SUCCESS,
  FETCH_COLLECTIONS_LIST_FAILURE,
  FETCH_SINGLE_COLLECTIONS_START,
  FETCH_SINGLE_COLLECTIONS_SUCCESS,
  FETCH_SINGLE_COLLECTIONS_FAILURE,
  NFT_COLLECTIONS_SAVE_START,
  NFT_COLLECTIONS_SAVE_SUCCESS,
  NFT_COLLECTIONS_SAVE_FAILURE,
  NFT_COLLECTIONS_DELETE_START,
  NFT_COLLECTIONS_DELETE_SUCCESS,
  NFT_COLLECTIONS_DELETE_FAILURE,
  FETCH_COLLECTIONS_LIST_FOR_OTHERS_START,
  FETCH_COLLECTIONS_LIST_FOR_OTHERS_SUCCESS,
  FETCH_COLLECTIONS_LIST_FOR_OTHERS_FAILURE,
  FETCH_NFT_ITEMS_LIST_START,
  FETCH_NFT_ITEMS_LIST_SUCCESS,
  FETCH_NFT_ITEMS_LIST_FAILURE,
  FETCH_SINGLE_NFT_ITEMS_START,
  FETCH_SINGLE_NFT_ITEMS_SUCCESS,
  FETCH_SINGLE_NFT_ITEMS_FAILURE,
  NFT_ITEMS_SAVE_START,
  NFT_ITEMS_SAVE_SUCCESS,
  NFT_ITEMS_SAVE_FAILURE,
  NFT_ITEMS_DELETE_START,
  NFT_ITEMS_DELETE_SUCCESS,
  NFT_ITEMS_DELETE_FAILURE,
  FETCH_NFT_PROPERTIES_LIST_START,
  FETCH_NFT_PROPERTIES_LIST_SUCCESS,
  FETCH_NFT_PROPERTIES_LIST_FAILURE,
  FETCH_NFT_FAVOURITES_LIST_START,
  FETCH_NFT_FAVOURITES_LIST_SUCCESS,
  FETCH_NFT_FAVOURITES_LIST_FAILURE,
  NFT_FAVOURITES_SAVE_START,
  NFT_FAVOURITES_SAVE_SUCCESS,
  NFT_FAVOURITES_SAVE_FAILURE,
  FETCH_NFT_CATEGORIES_LIST_START,
  FETCH_NFT_CATEGORIES_LIST_SUCCESS,
  FETCH_NFT_CATEGORIES_LIST_FAILURE,
  FETCH_REPORT_ITEMS_LIST_START,
  FETCH_REPORT_ITEMS_LIST_SUCCESS,
  FETCH_REPORT_ITEMS_LIST_FAILURE,
  SAVE_REPORT_ITEMS_START,
  SAVE_REPORT_ITEMS_SUCCESS,
  SAVE_REPORT_ITEMS_FAILURE,
  FETCH_NFT_ITEMS_PAYMENT_LIST_START,
  FETCH_NFT_ITEMS_PAYMENT_LIST_SUCCESS,
  FETCH_NFT_ITEMS_PAYMENT_LIST_FAILURE,
  FETCH_TRENDING_NFT_ITEMS_START,
  FETCH_TRENDING_NFT_ITEMS_SUCCESS,
  FETCH_TRENDING_NFT_ITEMS_FAILURE,
  SELL_NFT_ITEMS_START,
  SELL_NFT_ITEMS_SUCCESS,
  SELL_NFT_ITEMS_FAILURE,
  CANCEL_LIST_NFT_ITEMS_START,
  CANCEL_LIST_NFT_ITEMS_SUCCESS,
  CANCEL_LIST_NFT_ITEMS_FAILURE,
  BUY_NFT_ITEMS_START,
  BUY_NFT_ITEMS_SUCCESS,
  BUY_NFT_ITEMS_FAILURE,
  NFT_COLLECTIONS_NAME_VALIDATION_START,
  NFT_COLLECTIONS_NAME_VALIDATION_SUCCESS,
  NFT_COLLECTIONS_NAME_VALIDATION_FAILURE,
  NFT_USER_PROFILE_ITEMS_LIST_START,
  NFT_USER_PROFILE_ITEMS_LIST_SUCCESS,
  NFT_USER_PROFILE_ITEMS_LIST_FAILURE,
  NFT_SEARCH_START,
  NFT_SEARCH_SUCCESS,
  NFT_SEARCH_FAILURE,
  NFT_ITEM_FAVOURITE_USERS_LIST_START,
  NFT_ITEM_FAVOURITE_USERS_LIST_SUCCESS,
  NFT_ITEM_FAVOURITE_USERS_LIST_FAILURE,
  CLEAR_NFT_COLLECTIONS_SAVE,
  CLEAR_NFT_COLLECTION_ITEM_SAVE,
  LOAD_MORE_FETCH_NFT_ITEMS_LIST_START,
  LOAD_MORE_FETCH_NFT_ITEMS_LIST_SUCCESS,
  LOAD_MORE_FETCH_NFT_ITEMS_LIST_FAILURE,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_START,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE,
  LOAD_MORE_ITEM_ACTIVITY_START,
  LOAD_MORE_ITEM_ACTIVITY_SUCCESS,
  LOAD_MORE_ITEM_ACTIVITY_FAILURE,
  CLEAR_NFT_SINGLE_ITEM_SELL,
  CLEAR_CANCEL_LISTING_DATA,
  CLEAR_NFT_BUY_DATA,
  CLEAR_COLLECTION_DELETE_DATA,
  CLEAR_NFT_COLLECTION_LIST,
  IMPORT_CONTRATCT_ADDRESS_START,
  IMPORT_CONTRATCT_ADDRESS_SUCCESS,
  IMPORT_CONTRATCT_ADDRESS_FAILURE,
  CLEAR_IMPORT_CONTRACT_ADDRESS_DATA,
  CLEAR_SEARCH_DATA,
  TRANSFER_NFT_START,
  TRANSFER_NFT_SUCCESS,
  TRANSFER_NFT_FAILURE,
  CLEAR_TRANSFER_NFT_DATA,
  CLEAR_SINGLE_NFT_IMAGE_DATA,
  BID_OFFER_NFT_ITEMS_SAVE_START,
  BID_OFFER_NFT_ITEMS_SAVE_SUCCESS,
  BID_OFFER_NFT_ITEMS_SAVE_FAILURE,
  BID_OFFER_NFT_ITEMS_LIST_START,
  BID_OFFER_NFT_ITEMS_LIST_SUCCESS,
  BID_OFFER_NFT_ITEMS_LIST_FAILURE,
  ACCEPT_OFFER_NFT_ITEMS_START,
  ACCEPT_OFFER_NFT_ITEMS_SUCCESS,
  ACCEPT_OFFER_NFT_ITEMS_FAILURE,
  CANCEL_BID_OFFER_NFT_ITEM_START,
  CANCEL_BID_OFFER_NFT_ITEM_SUCCESS,
  CANCEL_BID_OFFER_NFT_ITEM_FAILURE,
  LOAD_MORE_OFFER_LIST_START,
  LOAD_MORE_OFFER_LIST_SUCCESS,
  LOAD_MORE_OFFER_LIST_FAILURE,
  MORE_FROM_COLLECTION_START,
  MORE_FROM_COLLECTION_SUCCESS,
  MORE_FROM_COLLECTION_FAILURE
} from "./ActionConstant";


export function fetchNftCollectionsListStart(data) {
  return {
    type: FETCH_COLLECTIONS_LIST_START,
    data,
  };
}

export function fetchNftCollectionsListSuccess(data) {
  return {
    type: FETCH_COLLECTIONS_LIST_SUCCESS,
    data,
  };
}

export function fetchNftCollectionsListFailure(error) {
  return {
    type: FETCH_COLLECTIONS_LIST_FAILURE,
    error,
  };
}

export function fetchSingleNftCollectionsStart(data) {
  return {
    type: FETCH_SINGLE_COLLECTIONS_START,
    data,
  };
}

export function fetchSingleNftCollectionsSuccess(data) {
  return {
    type: FETCH_SINGLE_COLLECTIONS_SUCCESS,
    data,
  };
}

export function fetchSingleNftCollectionsFailure(error) {
  return {
    type: FETCH_SINGLE_COLLECTIONS_FAILURE,
    error,
  };
}

export function nftCollectionsSaveStart(data) {
  return {
    type: NFT_COLLECTIONS_SAVE_START,
    data,
  };
}

export function nftCollectionsSaveSuccess(data) {
  return {
    type: NFT_COLLECTIONS_SAVE_SUCCESS,
    data,
  };
}

export function nftCollectionsSaveFailure(error) {
  return {
    type: NFT_COLLECTIONS_SAVE_FAILURE,
    error,
  };
}

export function nftCollectionsDeleteStart(data) {
  return {
    type: NFT_COLLECTIONS_DELETE_START,
    data,
  };
}

export function nftCollectionsDeleteSuccess(data) {
  return {
    type: NFT_COLLECTIONS_DELETE_SUCCESS,
    data,
  };
}

export function nftCollectionsDeleteFailure(error) {
  return {
    type: NFT_COLLECTIONS_DELETE_FAILURE,
    error,
  };
}

export function fetchNftCollectionsListForOthersStart(data) {
  return {
    type: FETCH_COLLECTIONS_LIST_FOR_OTHERS_START,
    data,
  };
}

export function fetchNftCollectionsListForOthersSuccess(data) {
  return {
    type: FETCH_COLLECTIONS_LIST_FOR_OTHERS_SUCCESS,
    data,
  };
}

export function fetchNftCollectionsListForOthersFailure(error) {
  return {
    type: FETCH_COLLECTIONS_LIST_FOR_OTHERS_FAILURE,
    error,
  };
}

export function fetchNftItemsListStart(data) {
  return {
    type: FETCH_NFT_ITEMS_LIST_START,
    data,
  };
}

export function fetchNftItemsListSuccess(data) {
  return {
    type: FETCH_NFT_ITEMS_LIST_SUCCESS,
    data,
  };
}

export function fetchNftItemsListFailure(error) {
  return {
    type: FETCH_NFT_ITEMS_LIST_FAILURE,
    error,
  };
}

export function fetchSingleNftItemsStart(data) {
  return {
    type: FETCH_SINGLE_NFT_ITEMS_START,
    data,
  };
}

export function fetchSingleNftItemsSuccess(data) {
  return {
    type: FETCH_SINGLE_NFT_ITEMS_SUCCESS,
    data,
  };
}

export function fetchSingleNftItemsFailure(error) {
  return {
    type: FETCH_SINGLE_NFT_ITEMS_FAILURE,
    error,
  };
}

export function nftItemsSaveStart(data) {
  return {
    type: NFT_ITEMS_SAVE_START,
    data,
  };
}

export function nftItemsSaveSuccess(data) {
  return {
    type: NFT_ITEMS_SAVE_SUCCESS,
    data,
  };
}

export function nftItemsSaveFailure(error) {
  return {
    type: NFT_ITEMS_SAVE_FAILURE,
    error,
  };
}

export function nftItemsDeleteStart(data) {
  return {
    type: NFT_ITEMS_DELETE_START,
    data,
  };
}

export function nftItemsDeleteSuccess(data) {
  return {
    type: NFT_ITEMS_DELETE_SUCCESS,
    data,
  };
}

export function nftItemsDeleteFailure(error) {
  return {
    type: NFT_ITEMS_DELETE_FAILURE,
    error,
  };
}

export function fetchNftPropertiesListStart(data) {
  return {
    type: FETCH_NFT_PROPERTIES_LIST_START,
    data,
  };
}

export function fetchNftPropertiesListSuccess(data) {
  return {
    type: FETCH_NFT_PROPERTIES_LIST_SUCCESS,
    data,
  };
}

export function fetchNftPropertiesListFailure(error) {
  return {
    type: FETCH_NFT_PROPERTIES_LIST_FAILURE,
    error,
  };
}

export function fetchNftFavouritesListStart(data) {
  return {
    type: FETCH_NFT_FAVOURITES_LIST_START,
    data,
  };
}

export function fetchNftFavouritesListSuccess(data) {
  return {
    type: FETCH_NFT_FAVOURITES_LIST_SUCCESS,
    data,
  };
}

export function fetchNftFavouritesListFailure(error) {
  return {
    type: FETCH_NFT_FAVOURITES_LIST_FAILURE,
    error,
  };
}

export function nftFavouritesSaveStart(data) {
  return {
    type: NFT_FAVOURITES_SAVE_START,
    data,
  };
}

export function nftFavouritesSaveSuccess(data) {
  return {
    type: NFT_FAVOURITES_SAVE_SUCCESS,
    data,
  };
}

export function nftFavouritesSaveFailure(error) {
  return {
    type: NFT_FAVOURITES_SAVE_FAILURE,
    error,
  };
}

export function fetchCategoriesListStart(data) {
  return {
    type: FETCH_NFT_CATEGORIES_LIST_START,
    data,
  };
}

export function fetchCategoriesListSuccess(data) {
  return {
    type: FETCH_NFT_CATEGORIES_LIST_SUCCESS,
    data,
  };
}

export function fetchCategoriesListFailure(error) {
  return {
    type: FETCH_NFT_CATEGORIES_LIST_FAILURE,
    error,
  };
}

export function fetchReportItemListStart(data) {
  return {
    type: FETCH_REPORT_ITEMS_LIST_START,
    data,
  };
}

export function fetchReportItemListSuccess(data) {
  return {
    type: FETCH_REPORT_ITEMS_LIST_SUCCESS,
    data,
  };
}

export function fetchReportItemListFailure(error) {
  return {
    type: FETCH_REPORT_ITEMS_LIST_FAILURE,
    error,
  };
}

export function reportItemsSaveStart(data) {
  return {
    type: SAVE_REPORT_ITEMS_START,
    data,
  };
}

export function reportItemsSaveSuccess(data) {
  return {
    type: SAVE_REPORT_ITEMS_SUCCESS,
    data,
  };
}

export function reportItemsSaveFailure(error) {
  return {
    type: SAVE_REPORT_ITEMS_FAILURE,
    error,
  };
}

export function nftItemsPaymentListStart(data) {
  return {
    type: FETCH_NFT_ITEMS_PAYMENT_LIST_START,
    data,
  };
}

export function nftItemsPaymentListSuccess(data) {
  return {
    type: FETCH_NFT_ITEMS_PAYMENT_LIST_SUCCESS,
    data,
  };
}

export function nftItemsPaymentListFailure(error) {
  return {
    type: FETCH_NFT_ITEMS_PAYMENT_LIST_FAILURE,
    error,
  };
}

export function fetchTrendingNftItemsStart(data) {
  return {
    type: FETCH_TRENDING_NFT_ITEMS_START,
    data,
  };
}

export function fetchTrendingNftItemsSuccess(data) {
  return {
    type: FETCH_TRENDING_NFT_ITEMS_SUCCESS,
    data,
  };
}

export function fetchTrendingNftItemsFailure(error) {
  return {
    type: FETCH_TRENDING_NFT_ITEMS_FAILURE,
    error,
  };
}

export function sellNftItemStart(data) {
  return {
    type: SELL_NFT_ITEMS_START,
    data,
  };
}

export function sellNftItemSuccess(data) {
  return {
    type: SELL_NFT_ITEMS_SUCCESS,
    data,
  };
}

export function sellNftItemFailure(error) {
  return {
    type: SELL_NFT_ITEMS_FAILURE,
    error,
  };
}

export function cancelListNftItemStart(data) {
  return {
    type: CANCEL_LIST_NFT_ITEMS_START,
    data,
  };
}

export function cancelListNftItemSuccess(data) {
  return {
    type: CANCEL_LIST_NFT_ITEMS_SUCCESS,
    data,
  };
}

export function cancelListNftItemFailure(error) {
  return {
    type: CANCEL_LIST_NFT_ITEMS_FAILURE,
    error,
  };
}

export function buyNftItemStart(data) {
  return {
    type: BUY_NFT_ITEMS_START,
    data,
  };
}

export function buyNftItemSuccess(data) {
  return {
    type: BUY_NFT_ITEMS_SUCCESS,
    data,
  };
}

export function buyNftItemFailure(error) {
  return {
    type: BUY_NFT_ITEMS_FAILURE,
    error,
  };
}

export function nftCollectionNameValidationStart(data) {
  return {
    type: NFT_COLLECTIONS_NAME_VALIDATION_START,
    data,
  };
}

export function nftCollectionNameValidationSuccess(data) {
  return {
    type: NFT_COLLECTIONS_NAME_VALIDATION_SUCCESS,
    data,
  };
}

export function nftCollectionNameValidationFailure(error) {
  return {
    type: NFT_COLLECTIONS_NAME_VALIDATION_FAILURE,
    error,
  };
}

export function nftUserProfileItemsListStart(data) {
  return {
    type: NFT_USER_PROFILE_ITEMS_LIST_START,
    data,
  };
}

export function nftUserProfileItemsListSuccess(data) {
  return {
    type: NFT_USER_PROFILE_ITEMS_LIST_SUCCESS,
    data,
  };
}

export function nftUserProfileItemsListFailure(error) {
  return {
    type: NFT_USER_PROFILE_ITEMS_LIST_FAILURE,
    error,
  };
}

export function nftSearchStart(data) {
  return {
    type: NFT_SEARCH_START,
    data,
  };
}

export function nftSearchSuccess(data) {
  return {
    type: NFT_SEARCH_SUCCESS,
    data,
  };
}

export function nftSearchFailure(error) {
  return {
    type: NFT_SEARCH_FAILURE,
    error,
  };
}
export function nftItemFavouriteUsersListStart(data) {
  return {
    type: NFT_ITEM_FAVOURITE_USERS_LIST_START,
    data,
  };
}

export function nftItemFavouriteUsersListSuccess(data) {
  return {
    type: NFT_ITEM_FAVOURITE_USERS_LIST_SUCCESS,
    data,
  };
}

export function nftItemFavouriteUsersListFailure(error) {
  return {
    type: NFT_ITEM_FAVOURITE_USERS_LIST_FAILURE,
    error,
  };
}

export function clearNftCollectionData(data) {
  return {
    type : CLEAR_NFT_COLLECTIONS_SAVE,
    data
  }
}

export function clearNftCollectionItemData(data) {
  return {
    type : CLEAR_NFT_COLLECTION_ITEM_SAVE,
    data
  }
}

export function loadMoreNftItemsStart(data) {
  return {
    type: LOAD_MORE_FETCH_NFT_ITEMS_LIST_START,
    data,
  };
}

export function loadMoreNftItemsSuccess(data) {
  return {
    type: LOAD_MORE_FETCH_NFT_ITEMS_LIST_SUCCESS,
    data,
  };
}

export function loadMoreNftItemsFailure(error) {
  return {
    type: LOAD_MORE_FETCH_NFT_ITEMS_LIST_FAILURE,
    error,
  };
}

export function loadMoreUserProfileItemsStart(data) {
  return {
    type: LOAD_MORE_USER_PROFILE_ITEMS_LIST_START,
    data,
  };
}

export function loadMoreUserProfileItemsSuccess(data) {
  return {
    type: LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS,
    data,
  };
}

export function loadMoreUserProfileItemsFailure(error) {
  return {
    type: LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE,
    error,
  };
}

export function loadMoreItemActivityStart(data) {
  return {
    type: LOAD_MORE_ITEM_ACTIVITY_START,
    data,
  };
}

export function loadMoreItemActivitySuccess(data) {
  return {
    type: LOAD_MORE_ITEM_ACTIVITY_SUCCESS,
    data,
  };
}

export function loadMoreItemActivityFailure(error) {
  return {
    type: LOAD_MORE_ITEM_ACTIVITY_FAILURE,
    error,
  };
}

export function clearNftSingleSellData(){
  return {
    type : CLEAR_NFT_SINGLE_ITEM_SELL
  }
}

export function clearCancelListingData(){
  return {
    type : CLEAR_CANCEL_LISTING_DATA
  }
}

export function clearNftBuyData() {
  return {
    type : CLEAR_NFT_BUY_DATA
  }
}

export function clearCollectionDeleteData() {
  return {
    type : CLEAR_COLLECTION_DELETE_DATA
  }
}

export function clearNftCollectionList() {
  return {
    type : CLEAR_NFT_COLLECTION_LIST
  }
}

export function importContractAddressStart(data) {
  return {
    type: IMPORT_CONTRATCT_ADDRESS_START,
    data,
  };
}

export function importContractAddressSuccess(data) {
  return {
    type: IMPORT_CONTRATCT_ADDRESS_SUCCESS,
    data,
  };
}

export function importContractAddressFailure(error) {
  return {
    type: IMPORT_CONTRATCT_ADDRESS_FAILURE,
    error,
  };
}

export function clearImportContractAddressData() {
  return {
    type : CLEAR_IMPORT_CONTRACT_ADDRESS_DATA
  }
}


export function clearSearchData() {
  return {
    type : CLEAR_SEARCH_DATA
  }
}

export function transferNFTStart(data) {
  return {
    type: TRANSFER_NFT_START,
    data,
  };
}

export function transferNFTSuccess(data) {
  return {
    type: TRANSFER_NFT_SUCCESS,
    data,
  };
}

export function transferNFTFailure(error) {
  return {
    type: TRANSFER_NFT_FAILURE,
    error,
  };
}

export function clearTransferNftData() {
  return {
    type : CLEAR_TRANSFER_NFT_DATA
  }
}


export function clearNftSingleImageData() {
  return {
    type : CLEAR_SINGLE_NFT_IMAGE_DATA
  }
}

export function bidOfferNftItemSaveStart(data) {
  return {
    type: BID_OFFER_NFT_ITEMS_SAVE_START,
    data,
  };
}

export function bidOfferNftItemSaveSuccess(data) {
  return {
    type: BID_OFFER_NFT_ITEMS_SAVE_SUCCESS,
    data,
  };
}

export function bidOfferNftItemSaveFailure(error) {
  return {
    type: BID_OFFER_NFT_ITEMS_SAVE_FAILURE,
    error,
  };
}

export function bidOfferNftItemListStart(data) {
  return {
    type: BID_OFFER_NFT_ITEMS_LIST_START,
    data,
  };
}

export function bidOfferNftItemListSuccess(data) {
  return {
    type: BID_OFFER_NFT_ITEMS_LIST_SUCCESS,
    data,
  };
}

export function bidOfferNftItemListFailure(error) {
  return {
    type: BID_OFFER_NFT_ITEMS_LIST_FAILURE,
    error,
  };
}

export function acceptOfferNftItemStart(data) {
  return {
    type: ACCEPT_OFFER_NFT_ITEMS_START,
    data,
  };
}

export function acceptOfferNftItemSuccess(data) {
  return {
    type: ACCEPT_OFFER_NFT_ITEMS_SUCCESS,
    data,
  };
}

export function acceptOfferNftItemFailure(error) {
  return {
    type: ACCEPT_OFFER_NFT_ITEMS_FAILURE,
    error,
  };
}

export function cancelBidOfferNftItemStart(data) {
  return {
    type: CANCEL_BID_OFFER_NFT_ITEM_START,
    data,
  };
}

export function cancelBidOfferNftItemSuccess(data) {
  return {
    type: CANCEL_BID_OFFER_NFT_ITEM_SUCCESS,
    data,
  };
}

export function cancelBidOfferNftItemFailure(error) {
  return {
    type: CANCEL_BID_OFFER_NFT_ITEM_FAILURE,
    error,
  };
}

export function loadMoreOfferListStart(data) {
  return {
    type: LOAD_MORE_OFFER_LIST_START,
    data,
  };
}

export function loadMoreOfferListSuccess(data) {
  return {
    type: LOAD_MORE_OFFER_LIST_SUCCESS,
    data,
  };
}

export function loadMoreOfferListFailure(error) {
  return {
    type: LOAD_MORE_OFFER_LIST_FAILURE,
    error,
  };
}

export function moreFromCollectionStart(data) {
  return {
    type: MORE_FROM_COLLECTION_START,
    data,
  };
}

export function moreFromCollectionSuccess(data) {
  return {
    type: MORE_FROM_COLLECTION_SUCCESS,
    data,
  };
}

export function moreFromCollectionFailure(error) {
  return {
    type: MORE_FROM_COLLECTION_FAILURE,
    error,
  };
}