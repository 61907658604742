import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Tab,
  Row,
  Nav,
  InputGroup,
  FormControl,
  Container,
} from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ImageIcon from "@material-ui/icons/Image";
import { authContext } from "../authprovider/AuthProvider";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import PageNotFound from "../404/PageNotFound";
import EditProfilePanel from "./EditProfilePanel";
import EditProfileLoader from "../loaders/EditProfileLoader";

const ProfileSettings = (props) => {
  const { auth } = useContext(authContext);

  useEffect(() => {
    if (!auth.loading && auth.accounts != "" && auth.authStatus) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, [auth.authStatus , auth.loading]);

  return (
    <>
      {auth.loading ? null : auth.authStatus ? (
        <>
          <div className="profile-setting pages-padding">
            <div className="profile-setting-wrapper">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="mt-3 mt-md-0 g-0">
                  <Col md={4} lg={3}>
                    <div className="profile-setting-tabs-switcher">
                      <h5 className="profile-heading">Settings</h5>
                      <Nav variant="pills">
                        <Nav.Item className="setting-tab">
                          <Nav.Link eventKey="first">
                            <AccountBoxIcon />
                            Profile
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                  <Col md={8} lg={9} className="mt-3 mt-md-0">
                    <Tab.Content>
                      <div className="tab-content-wrapper">
                        {props.profile.loading ? (
                          <Col lg={8}>
                            <EditProfileLoader />
                          </Col>
                        ) : (
                          props.profile.data.user && (
                            <>
                              <Col lg={8}>
                                <EditProfilePanel />
                              </Col>
                            </>
                          )
                        )}
                      </div>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </>
      ) : localStorage.getItem("inital_connect") == "false" && (
        <PageNotFound />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ProfileSettings);
