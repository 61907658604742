import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./NewLanding.css";
import { Link } from "react-router-dom";

const HowItsWork = (props) => {


  return (
    <>
      <section className="how-its-works-sec">
        <Container>
          <div className="section-title text-center">
            <h2>How its Work</h2>
          </div>
          <Row className="margin-top-xl">
            <Col md={12}>
              <div className="how-its-work-bg-sec">
                <Image
                  className="how-its-work-bg-img"
                  src={
                    window.location.origin + "/assets/images/new-landing/bg-how-its-works.svg"
                  }
                />
              </div>
              <div className="how-its-work-box">
                <div className="how-its-work-card">
                  <div className="how-its-work-img-sec">
                    <Image
                      className="how-its-work-icon"
                      src={
                        window.location.origin + "/assets/images/new-landing/wallet-icon.svg"
                      }
                    />
                  </div>
                  <div className="how-its-work-info">
                    <div className="step-card">
                      Step 1
                    </div>
                    <h4>Connect Wallet</h4>
                    <p>By installing the metamask extension on your browser you will be able to connect to the EFI NFT platform.</p>
                  </div>
                </div>
                <div className="how-its-work-card">
                  <div className="how-its-work-img-sec">
                    <Image
                      className="how-its-work-icon"
                      src={
                        window.location.origin + "/assets/images/new-landing/idea.svg"
                      }
                    />
                  </div>
                  <div className="how-its-work-info">
                    <div className="step-card">
                      Step 2
                    </div>
                    <h4>Create Collection</h4>
                    <p>On the EFI NFT platform, creators can build their own collections. Creators can also have multiple collections on the platform.</p>
                  </div>
                </div>
                <div className="how-its-work-card">
                  <div className="how-its-work-img-sec">
                    <Image
                      className="how-its-work-icon"
                      src={
                        window.location.origin + "/assets/images/new-landing/digital.svg"
                      }
                    />
                  </div>
                  <div className="how-its-work-info">
                    <div className="step-card">
                      Step 3
                    </div>
                    <h4>Mint NFT or Create NFT</h4>
                    <p>a. Once the Collections are created the Creators will be able to mint NFTs under the collection</p>
                    <p>b. Upload your art work in pattern and personalize the NFT</p>
                  </div>
                </div>
                <div className="how-its-work-card">
                  <div className="how-its-work-img-sec">
                    <Image
                      className="how-its-work-icon"
                      src={
                        window.location.origin + "/assets/images/new-landing/hot-sale.svg"
                      }
                    />
                  </div>
                  <div className="how-its-work-info">
                    <div className="step-card">
                      Step 4
                    </div>
                    <h4>List for Sell</h4>
                    <p>Choose between auctions, fixed-price listings, and declining-price listings.</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HowItsWork;
