import { all, fork } from "redux-saga/effects";

import UserSaga from './UserSaga';
import NftSaga from './NftSaga';
import PageSaga from "./PageSaga";

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(NftSaga)]);
  yield all([fork(PageSaga)]);
}
