import React from "react";
import Box from "@material-ui/core/Box";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <>
      {value == index && (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`nav-tabpanel-${index}`}
          aria-labelledby={`nav-tab-${index}`}
          {...other}
        >
          {value === index && <Box>{children}</Box>}
        </div>
      )}
    </>
  );
};

export default CustomTabPanel;
