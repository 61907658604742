import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import GridOnOutlinedIcon from "@material-ui/icons/GridOnOutlined";
import ExitToAppOutlined from "@material-ui/icons/ExitToAppOutlined";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import MobileHeader from "./MobileHeader";
import { authContext } from "../../authprovider/AuthProvider";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { fetchCategoriesListStart } from "../../../store/actions/NftAction";
import CustomSearch from "../../../customComponents/CustomSearch";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
import Brightness4RoundedIcon from "@material-ui/icons/Brightness4Rounded";
import { themeContext } from "../../theme/ThemeChanger";
import WbSunnyRoundedIcon from "@material-ui/icons/WbSunnyRounded";
import ChainType from "../../authprovider/ChainType";

const EmptyHeader = (props) => {
  const location = useLocation();

  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, []);

  const { categories } = props;

  const [mobileMenu, setMobileMobileMenu] = useState(false);

  const [skipInitialRender, setSkipInitialRender] = useState(false);

  const { auth, hanldeLogout } = useContext(authContext);

  const { theme, themetoggler } = useContext(themeContext);

  const [sidebarState, setSidebarState] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const handleMobileMenu = (status) => {
    setMobileMobileMenu(status);
    setSidebarState(false);
  };

  useEffect(() => {
    setSkipInitialRender(true);
  }, []);

  const handleSearchQueryChange = (value) => {
    setSearchQuery(value);
  };

  const toggleDrawer = (status) => {
    setSidebarState(status);
    setMobileMobileMenu(false);
  };

  useEffect(() => {
    handleMobileMenu(false);
  }, [location]);

  const handleThemeChange = () => {
    themetoggler.toggleTheme();
  };

  return (
    <>
      <div className="navbar-area">
        <div className="desktop-nav desktop-nav-one nav-area">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light ">
              <Link className="navbar-brand" to="/">
                <img
                  src={configuration.get("configData.site_logo")}
                  alt="Logo"
                  style={{
                    height: "50px",
                  }}
                />
              </Link>
              {/* <div className="header-search-wrapper">
                <div className="nav-widget-form">
                  <form className="search-form">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search items, Creators "
                    />
                    <button type="submit">
                      <i className="ri-search-line"></i>
                    </button>
                  </form>
                </div>
              </div> */}
              <CustomSearch
                value={searchQuery}
                onChange={handleSearchQueryChange}
                classes="hide-mobile"
              />
              <div
                className="mean-menu nav-align navbar-items"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav no-margin-right hide-in-mobile">
                  {categories.loading ? null : categories.data.categories
                      .length > 0 ? (
                    <li className="nav-item">
                      <Link to={"/explore"} className="nav-link">
                        Explore
                      </Link>
                      <ul className="dropdown-menu custom-dropdown-menu">
                        <div className="custom-dropdown-wrapper">
                          <li className="nav-item">
                            <Link to={"/all-nfts"} className="nav-link">
                              <img
                                  src={window.location.origin + "/assets/images/grid-blue.png"}
                                  alt=""
                                  className="category-icon"
                                />
                              All NFTs
                            </Link>
                          </li>
                          {categories.data.categories.filter(cat => cat.status === 1).map((category , index) => (
                            <li className="nav-item" key={index}>
                              <Link
                                to={`/explore?tab=${category.category_id}`}
                                className="nav-link"
                              >
                                <img
                                  src={category.picture}
                                  alt=""
                                  className="category-icon"
                                />
                                {category.name}
                              </Link>
                            </li>
                          ))}
                        </div>
                      </ul>
                    </li>
                  ) : null}
                  <li className="d-flex header-chain-type">
                    <ChainType />
                  </li>
                  <li className="nav-item">
                    <Link to={"/"} className="nav-link">
                      Resources
                    </Link>
                    <ul className="dropdown-menu custom-dropdown-menu not-scrollable">
                      {configuration
                        .get("configData.footer_pages1")
                        .map((static_page, index) => (
                          <li className="nav-item text-capitalize" key={index}>
                            <Link
                              to={`/page/${static_page.static_page_unique_id}`}
                              className="nav-link"
                            >
                              {static_page.title}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  {/* {auth.authStatus && (
                    <li className="nav-item">
                      <Link to="/create-new-item" className="nav-link">
                        Create
                      </Link>
                    </li>
                  )} */}
                </ul>

                <div className="mean-menu nav-align navbar-actions">
                  <ul className="navbar-nav no-margin-right">
                    <li className="nav-item theme-switcher">
                      <div className="theme-changer-wrapper">
                        <div
                          className={`theme-changer `}
                        >
                          { theme === "light" ? (
                            <WbSunnyRoundedIcon
                              onClick={() => handleThemeChange()}
                            />
                          ) : (
                            <Brightness4RoundedIcon
                              onClick={() => handleThemeChange()}
                            />
                          )}
                        </div>
                      </div>
                    </li>
                    {auth.authStatus && (
                      <li className="nav-item account-link">
                        <Link to={"/profile"} className="nav-link">
                          {auth.authStatus ? (
                            <>
                              {auth.userPicture != null && (
                                <div className="account-image">
                                  <img src={auth.userPicture} alt="" />
                                </div>
                              )}
                            </>
                          ) : (
                            <AccountCircleOutlinedIcon />
                          )}
                        </Link>
                        <ul className="dropdown-menu custom-dropdown-menu not-scrollable">
                          <li className="nav-item">
                            <Link to={"/profile"} className="nav-link">
                              <AccountCircleOutlinedIcon /> Profile
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              to={"/profile?tab=favorites"}
                              className="nav-link"
                            >
                              <FavoriteBorderOutlinedIcon /> Favourites
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to={"/my-collection"} className="nav-link">
                              <GridOnOutlinedIcon /> My Collections
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to={"/profile-setting"} className="nav-link">
                              <SettingsIcon /> Settings
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className="nav-link"
                              onClick={hanldeLogout}
                            >
                              <ExitToAppOutlined />
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                    <button
                      onClick={() => toggleDrawer(!sidebarState)}
                      className="btn no-color primaryicon icon"
                    >
                      <AccountBalanceWalletOutlinedIcon />
                    </button>
                    {skipInitialRender && (
                      <button
                        onClick={() => handleMobileMenu(!mobileMenu)}
                        className="btn no-color primaryicon icon mobile-ham"
                      >
                        <MenuOutlinedIcon />
                      </button>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <MobileHeader
        toggleMobileMenu={handleMobileMenu}
        mobileMenuStatus={mobileMenu}
      />
      <Sidebar toggleDrawer={toggleDrawer} sidebarState={sidebarState} />
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EmptyHeader);
