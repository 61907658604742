import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import CustomTabs from "../../customComponents/CustomTabs";
import GridOnOutlinedIcon from "@material-ui/icons/GridOnOutlined";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CollectionCard from "../../customComponents/CollectionCard";
import { useHistory, useLocation, useParams } from "react-router";
import CustomSelect from "../../customComponents/CustomSelect";
import GridChange from "../../customComponents/GridChange";
import { connect } from "react-redux";
import { authContext } from "../authprovider/AuthProvider";
import CustomCollectionFilter from "../../customComponents/CustomCollectionFilter";
import TimelineIcon from "@material-ui/icons/Timeline";
import {
  fetchSingleNftCollectionsStart,
  fetchNftItemsListStart,
  fetchNftPropertiesListStart,
  clearNftCollectionData,
  loadMoreNftItemsStart,
  nftItemsPaymentListStart,
  loadMoreItemActivityStart,
  importContractAddressStart,
} from "../../store/actions/NftAction";
import { Button, Dropdown } from "react-bootstrap";
import CustomTabPanel from "../../customComponents/CustomTabPanel";
import CustomInfiniteScroll from "../../customComponents/CustomInfiniteScroll";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import CollectionCardLoader from "../loaders/CollectionCardLoader";
import Activitytable from "../../customComponents/Activitytable";
import CollectionActivityFilter from "../../customComponents/CollectionActivityFilter";
import ProfilerLoader from "../loaders/ProfilerLoader";
import CustomCollectionSearch from "../../customComponents/CustomCollectionSearch";
import PageNotFound from "../../components/404/PageNotFound";
import CloseIcon from "@material-ui/icons/Close";
import useValidation from "../../customHooks/useValidation";
import { useTheme } from "@material-ui/core";
import CollectionActivityLoader from "../loaders/CollectionActivityLoader";
import CustomTooltip from "../../customComponents/CustomTooltip";
import NftToken from "../../abis/NftToken.json";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import configuration from "react-global-configuration";
import { CHAIN_INFO, SupportedChainIdBySymbol } from "../authprovider/chains";

const queryString = require("query-string");

const customTabLabel = (props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 0.5,
          pr: 0,
        }}
      >
        <Box component={props.icon} sx={{ mr: 1 }} />
        <Typography variant="body2">{props.text}</Typography>
        <Typography variant="body2">{props.count}</Typography>
      </Box>
    </>
  );
};

const OwnerCollectionViewer = (props) => {
  const history = useHistory();

  const parsedTab = queryString.parse(props.location.search);

  const location = useLocation();

  const { nft_collection_unique_id } = useParams();

  const [activeTab, setActiveTab] = useState(
    Object.keys(parsedTab).length > 0 ? parsedTab.tab : "items"
  );

  const [skipInitalRender, setSkipIntialRender] = useState(false);

  const [priceSort, setPriceSort] = useState([]);

  const [activityFilter, setActivityFilter] = useState([]);

  const [gridView, setGridView] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");

  const { auth } = useContext(authContext);

  const theme = useTheme();

  const [filterStatus, setFilterStatus] = useState(true);

  const [mobileFilterStatus, setMobileFilterStatus] = useState(false);

  const [desOpen, setDesOpen] = useState(false);

  //get filter propetries

  const [filterDataContents, setFilterDataContents] = useState({
    sort_by: [],
    min_amount: "",
    max_amount: "",
    categories: [],
    properties: [],
    propertyRemoved: {
      count: 0,
      data: [],
    },
    categoryRemoved: {
      count: 0,
      data: [],
    },
    clearAll: false,
    search_key: "",
  });

  const [filterWidth, setFilterWidth] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [importLoading, setImportLoading] = useState(false);

  useEffect(() => {

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };


  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDescToggle = () => {
    setDesOpen(!desOpen);
  };

  const { validation } = useValidation(props.singleCollection);

  useEffect(() => {
    props.dispatch(
      fetchSingleNftCollectionsStart({
        nft_collection_unique_id: nft_collection_unique_id,
      })
    );
    props.dispatch(clearNftCollectionData());
  }, [location.pathname]);

  useEffect(() => {
    if (!validation.loading && !validation.status) {
      props.dispatch(
        fetchNftItemsListStart({
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
      props.dispatch(
        fetchNftPropertiesListStart({
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
      props.dispatch(
        nftItemsPaymentListStart({
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
      props.dispatch(clearNftCollectionData());
    }
  }, [validation, location]);

  useEffect(() => {
    if (skipInitalRender) {
      const query = queryString.stringify(
        { tab: activeTab },
        { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
      );

      history.push({
        pathname: props.location.pathname,
        search: query,
      });

      props.dispatch(
        fetchNftItemsListStart({
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
    }
    setSkipIntialRender(true);
  }, [activeTab]);

  const handlePriceSortChange = (value) => {
    setPriceSort([value]);
    setFilterDataContents({
      ...filterDataContents,
      sort_by: [value],
    });
    if (priceSort.length > 0) {
      props.dispatch(
        fetchNftItemsListStart({
          ...filterDataContents,
          type: activeTab,
          sort_by: priceSort[0].id,
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
    } else {
      props.dispatch(
        fetchNftItemsListStart({
          ...filterDataContents,
          type: activeTab,
          sort_by: value.id,
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
    }
  };

  const handleActivityFilterChange = (value) => {
    const valueExist = activityFilter.filter((selected) => selected == value);
    if (valueExist.length > 0) {
      setActivityFilter(activityFilter.filter((selected) => selected != value));
    } else {
      setActivityFilter([...activityFilter, value]);
    }
  };

  const handleActivityFilterReset = () => {
    setActivityFilter([]);
  };

  const handleGridViewChange = (value) => {
    setGridView(value);
  };

  const handleSearchQueryChange = (value) => {
    setSearchQuery(value);
  };

  const handleFilterDrawerStatus = (status) => {
    setFilterStatus(status);
  };

  const handleMobileFilterDrawerStatus = (status) => {
    setMobileFilterStatus(status);
  };

  useEffect(() => {
    if (Object.keys(filterDataContents).length > 0) {
      if (filterDataContents.sort_by) {
        setPriceSort(filterDataContents.sort_by);
      }
    }
  }, [filterDataContents]);

  const clearFilter = () => {
    setFilterDataContents({
      sort_by: [],
      min_amount: "",
      max_amount: "",
      categories: [],
      properties: [],
      propertyRemoved: {
        count: 0,
        data: [],
      },
      categoryRemoved: {
        count: 0,
        data: [],
      },
      clearAll: true,
      search_key: "",
    });
    setPriceSort([]);
    props.dispatch(
      fetchNftItemsListStart({
        nft_collection_unique_id: nft_collection_unique_id,
      })
    );
  };

  const removeSingleProperty = (removedPropertyName, removedPropertyValue) => {
    setFilterDataContents({
      ...filterDataContents,
      propertyRemoved: {
        count: filterDataContents.propertyRemoved.count + 1,
        data: { name: removedPropertyName, value: [removedPropertyValue] },
      },
    });
  };

  const removeSortby = () => {
    setPriceSort([]);
    setFilterDataContents({
      ...filterDataContents,
      sort_by: [],
    });
    props.dispatch(
      fetchNftItemsListStart({
        nft_collection_unique_id: nft_collection_unique_id,
        sort_by: "",
        min_amount:
          filterDataContents.min_amount > 0
            ? filterDataContents.min_amount
            : "",
        max_amount:
          filterDataContents.max_amount > 0
            ? filterDataContents.max_amount
            : "",
        categories:
          filterDataContents.categories.length > 0
            ? JSON.stringify(filterDataContents.categories)
            : "",
        properties:
          filterDataContents.properties.length > 0
            ? JSON.stringify(filterDataContents.properties)
            : "",
        search_key: filterDataContents.search_key,
      })
    );
  };

  const clearPriceRange = (value) => {
    if (value === "min") {
      setFilterDataContents({
        ...filterDataContents,
        min_amount: "",
      });
      props.dispatch(
        fetchNftItemsListStart({
          nft_collection_unique_id: nft_collection_unique_id,
          sort_by: priceSort.length > 0 ? priceSort[0].id : "",
          min_amount: "",
          max_amount:
            filterDataContents.max_amount > 0
              ? filterDataContents.max_amount
              : "",
          categories:
            filterDataContents.categories.length > 0
              ? JSON.stringify(filterDataContents.categories)
              : "",
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
          search_key: filterDataContents.search_key,
        })
      );
    } else if (value === "max") {
      setFilterDataContents({
        ...filterDataContents,
        max_amount: "",
      });
      props.dispatch(
        fetchNftItemsListStart({
          nft_collection_unique_id: nft_collection_unique_id,
          sort_by: priceSort.length > 0 ? priceSort[0].id : "",
          min_amount:
            filterDataContents.min_amount > 0
              ? filterDataContents.min_amount
              : "",
          max_amount: "",
          categories:
            filterDataContents.categories.length > 0
              ? JSON.stringify(filterDataContents.categories)
              : "",
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
          search_key: filterDataContents.search_key,
        })
      );
    } else if (value === "minmax") {
      setFilterDataContents({
        ...filterDataContents,
        max_amount: "",
        min_amount: "",
      });
      props.dispatch(
        fetchNftItemsListStart({
          nft_collection_unique_id: nft_collection_unique_id,
          sort_by: priceSort.length > 0 ? priceSort[0].id : "",
          min_amount: "",
          max_amount: "",
          categories:
            filterDataContents.categories.length > 0
              ? JSON.stringify(filterDataContents.categories)
              : "",
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
          search_key: filterDataContents.search_key,
        })
      );
    }
  };

  const clearSearchKey = () => {
    setFilterDataContents({
      ...filterDataContents,
      search_key: "",
    });
    props.dispatch(
      fetchNftItemsListStart({
        nft_collection_unique_id: nft_collection_unique_id,
        sort_by: priceSort.length > 0 ? priceSort[0].id : "",
        min_amount:
          filterDataContents.min_amount > 0
            ? filterDataContents.min_amount
            : "",
        max_amount:
          filterDataContents.max_amount > 0
            ? filterDataContents.max_amount
            : "",
        categories:
          filterDataContents.categories.length > 0
            ? JSON.stringify(filterDataContents.categories)
            : "",
        properties:
          filterDataContents.properties.length > 0
            ? JSON.stringify(filterDataContents.properties)
            : "",
        search_key: "",
      })
    );
  };

  const handleImport = async () => {
    const web3 = window.web3;

    let nftContractData;
    const NFTData = NftToken.networks[auth.chainId];
    console.log("NFTData", NFTData)

    if (NFTData) {
      try {
        setImportLoading(true);
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          props.singleCollection.data.nft_collection.contract_address
        );
        const ownerAddress = await nftContractData.methods.owner().call();
        props.dispatch(
          importContractAddressStart({
            contract_address: props.singleCollection.data.nft_collection.contract_address,
            owner_wallet_address: ownerAddress,
          })
        );
        const notificationMessage = getSuccessNotificationMessage(
          "We've queued this item for an update! Check back in a minute..."
        );
        props.dispatch(createNotification(notificationMessage));
      } catch (error) {
        setImportLoading(false);
        const notificationMessage = getErrorNotificationMessage(
          `Contract not deployed in ${CHAIN_INFO[auth.chainId].nativeCurrency.symbol}`
        );
        props.dispatch(createNotification(notificationMessage));
      }
    }
  };

  useEffect(() => {
    if (
      !props.importContract.loading &&
      Object.keys(props.importContract.data).length > 0
    ) {
      const notificationMessage = getSuccessNotificationMessage(
        'Contract metadata refreshed successfully, please wait some times to get new items.'
      );
      props.dispatch(createNotification(notificationMessage));
    }
  }, [props.importContract.loading]);

  return (
    <>
      <div className="profile">
        {validation.loading ? (
          <>
            <ProfilerLoader collection />
          </>
        ) : validation.status ? (
          <>
            <PageNotFound />
          </>
        ) : (
          <>
            {props.singleCollection.data.nft_collection ? (
              <>
                {auth.authStatus &&
                props.singleCollection.data.nft_collection.user_id ==
                  auth.userId ? (
                  <div className="single-page-btn top-icon-res">
                    <Link
                      to={`/my-collection/${nft_collection_unique_id}`}
                      className="default-btn border-radius-5"
                    >
                      Edit collection
                    </Link>

                    {!props.singleCollection.loading &&
                    props.singleCollection.data.nft_collection &&
                    props.singleCollection.data.nft_collection
                      .collection_type != 3 ? (
                      <Link
                        to={`/create-new-item?nft_collection_unique_id=${props.singleCollection.data.nft_collection.nft_collection_id}`}
                        className="default-btn two border-radius-5"
                      >
                        Add item
                      </Link>
                    ) : null}
                  </div>
                ) : null}

                <div className="profile-banner-section">
                  <div className="profile-cover-image ">
                    <img
                      src={props.singleCollection.data.nft_collection.cover}
                      alt=""
                    />
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 mx-auto profile-head">
                    <div className="user-details">
                      <div className="profile-image">
                        <img
                          src={
                            props.singleCollection.data.nft_collection.picture
                          }
                          alt=""
                        />
                      </div>
                      <div className="verified-icon-sec">
                        <h4>{props.singleCollection.data.nft_collection.name}</h4>
                        {props.singleCollection.data.nft_collection.is_verified == 1 && (
                          <img 
                            className="verified-icon" 
                            src={window.location.origin + "/assets/images/icons/verified.png"} 
                            alt="Verified Icon" />
                        )}
                      </div>
                    </div>
                    <div className="head-values head-icon">
                      <Link to="#" className="btn-header">
                        <div className="values-number">
                          <h3 className="mb-0">
                            {
                              props.singleCollection.data.nft_collection
                                .total_items
                            }
                          </h3>
                          <p>items</p>
                        </div>
                      </Link>
                      <Link to="#" className="btn-header">
                        <div className="values-number">
                          <h3 className="mb-0">
                            {
                              props.singleCollection.data.nft_collection
                                .owners_count
                            }
                          </h3>
                          <p>owners</p>
                        </div>
                      </Link>
                      <Link to="#" className="btn-header">
                        <div className="values-number">
                          <h3 className="mb-0">
                            <span>
                              <img src={
                                  CHAIN_INFO[
                                    SupportedChainIdBySymbol[
                                      props.singleCollection.data.nft_collection
                                        .blockchain_type
                                    ]
                                  ].logo
                                } />
                            </span>
                            {Number(
                              props.singleCollection.data.nft_collection
                              .floor_price
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </h3>
                          <p>floor price</p>
                        </div>
                      </Link>
                      <Link to="#" className="btn-header">
                        <div className="values-number">
                          <h3 className="mb-0">
                            <span>
                              <img src={
                                  CHAIN_INFO[
                                    SupportedChainIdBySymbol[
                                      props.singleCollection.data.nft_collection
                                        .blockchain_type
                                    ]
                                  ].logo
                                } />
                            </span>
                            {
                              props.singleCollection.data.nft_collection
                                .volume_traded
                            }
                          </h3>
                          <p>value traded</p>
                        </div>
                      </Link>
                    </div>
                    {props.singleCollection.data.nft_collection.description !=
                      null && (
                      <>
                        <div
                          className={`head-description mx-auto ${
                            desOpen ? "show" : ""
                          }`}
                        >
                          <p>
                            {
                              props.singleCollection.data.nft_collection
                                .description
                            }
                          </p>
                        </div>
                        <div
                          className={`description-btn ${desOpen ? "show" : ""}`}
                        >
                          <button onClick={() => handleDescToggle()}>
                            <i class="fas fa-chevron-down"></i>
                          </button>
                        </div>
                      </>
                    )}

                    <div className="top-head-icons owner-top-btn">
                      <div className="head-icon d-flex">
                        {props.singleCollection.data.nft_collection
                        .collection_type == 3 && (
                        props.singleCollection.data.nft_collection
                          .queue_status == 1 ? (
                          <CustomTooltip title="Refresh Metadata" placement="bottom">
                            <a
                              onClick={() => handleImport()}
                              className="btn-header"
                            >
                              {importLoading ? (
                                <i class="fas fa-sync fa-spin"></i>
                              ) : (
                                <i class="fas fa-refresh"></i>
                              )}
                            </a>
                          </CustomTooltip>
                        ) : (
                          <CustomTooltip title="Metadata Refreshing..." placement="bottom">
                            <a
                              className="btn-header"
                            >
                              <i class="fas fa-sync fa-spin"></i>
                            </a>
                          </CustomTooltip>) )
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}

        {!validation.status && (
          <div className="">
            <div className="user-collections">
              <div className="collection-tabs">
                <CustomTabs
                  activeTab={activeTab}
                  handleTabChange={handleTabChange}
                >
                  <Tab
                    aria-label="phone"
                    label={customTabLabel({
                      icon: GridOnOutlinedIcon,
                      text: "Items",
                      count: `${
                        !props.singleCollection.loading &&
                        props.singleCollection.data.nft_collection
                          ? props.singleCollection.data.nft_collection
                              .total_items
                          : 0
                      }`,
                    })}
                    value={"items"}
                  />
                  <Tab
                    aria-label="phone"
                    label={customTabLabel({
                      icon: TimelineIcon,
                      text: "Activity",
                      // count: 0,
                    })}
                    value={"activity"}
                  />
                </CustomTabs>
              </div>
              <CustomTabPanel value={activeTab} index={"items"}>
                <div className="collection-items">
                  <div className="collection-widget-area">
                    <div className="pr-3">
                      <div className="filter-drawer-wrapper">
                        <CustomCollectionFilter
                          filterStatus={filterStatus}
                          handleFilterDrawerStatus={handleFilterDrawerStatus}
                          mobileFilterStatus={mobileFilterStatus}
                          handleMobileFilterDrawerStatus={
                            handleMobileFilterDrawerStatus
                          }
                          showproperties={true}
                          propertiesList={
                            props.propertiesList.loading
                              ? []
                              : props.propertiesList.data.nft_properties
                          }
                          fetchAction={fetchNftItemsListStart}
                          dispatchData={{
                            nft_collection_unique_id: nft_collection_unique_id,
                          }}
                          setFilterDataContents={setFilterDataContents}
                          filterDataContents={filterDataContents}
                          priceSort={priceSort}
                        />
                        <div className="p-4">
                          <div className="col-12">
                            <div className="collection-actions">
                              <CustomCollectionSearch
                                value={searchQuery}
                                onChange={handleSearchQueryChange}
                                classes="collection-search"
                                dispatchAction={fetchNftItemsListStart}
                                dispatchData={{
                                  min_amount: filterDataContents.min_amount,
                                  max_amount: filterDataContents.max_amount,
                                  categories:
                                    filterDataContents.categories.length > 0
                                      ? JSON.stringify(
                                          filterDataContents.categories
                                        )
                                      : "",
                                  properties:
                                    filterDataContents.properties.length > 0
                                      ? JSON.stringify(
                                          filterDataContents.properties
                                        )
                                      : "",
                                  type: activeTab,
                                  sort_by:
                                    priceSort.length > 0 ? priceSort[0].id : "",
                                  nft_collection_unique_id:
                                    nft_collection_unique_id,
                                }}
                                clearData={setSearchQuery}
                                setFilterDataContents={setFilterDataContents}
                                filterDataContents={filterDataContents}
                              />
                              <div className="price-select-wrapper">
                                <CustomSelect
                                  value={priceSort}
                                  handleChange={handlePriceSortChange}
                                  items={[
                                    { name: "Newly Added", id: "new" },
                                    { name: "Low to high", id: "price_lh" },
                                    { name: "High to low", id: "price_hl" },
                                    { name: "Buy Now", id: "buy_now" },
                                  ]}
                                  placeHolder={"Sort by"}
                                  idBased
                                  nameField={"name"}
                                  valueField={"id"}
                                />
                              </div>
                              <div className="grid-view-change">
                                <GridChange
                                  value={gridView}
                                  onChange={handleGridViewChange}
                                />
                              </div>
                            </div>
                            {Object.keys(filterDataContents).length > 0 && (
                              <>
                                <div className="selected-filters">
                                  {filterDataContents.properties && (
                                    <>
                                      {filterDataContents.properties.map(
                                        (selected) => (
                                          <>
                                            {selected.value.map(
                                              (selectedvalue) => (
                                                <div className="properties-panel text-center properties">
                                                  <p className="mb-0">
                                                    <small>
                                                      {selected.name}
                                                    </small>
                                                    {selectedvalue}
                                                  </p>
                                                  <CloseIcon
                                                    onClick={() =>
                                                      removeSingleProperty(
                                                        selected.name,
                                                        selectedvalue
                                                      )
                                                    }
                                                  />
                                                </div>
                                              )
                                            )}
                                          </>
                                        )
                                      )}
                                    </>
                                  )}
                                  {filterDataContents.sort_by && (
                                    <>
                                      {filterDataContents.sort_by.map(
                                        (selected) => (
                                          <>
                                            <div className="properties-panel text-center ">
                                              <p className="mb-0">
                                                {selected.name}
                                                <CloseIcon
                                                  onClick={() => removeSortby()}
                                                />
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </>
                                  )}
                                  {filterDataContents.min_amount && (
                                    <>
                                      {Number(filterDataContents.min_amount) >
                                        0 &&
                                        Number(filterDataContents.max_amount) ==
                                          0 && (
                                          <div className="properties-panel text-center ">
                                            <p className="mb-0">
                                              {`Price > ${filterDataContents.min_amount}`}
                                              <CloseIcon
                                                onClick={() =>
                                                  clearPriceRange("min")
                                                }
                                              />
                                            </p>
                                          </div>
                                        )}
                                    </>
                                  )}
                                  {filterDataContents.max_amount && (
                                    <>
                                      {Number(filterDataContents.max_amount) >
                                        0 &&
                                        Number(filterDataContents.min_amount) ==
                                          0 ? (
                                          <div className="properties-panel text-center ">
                                            <p className="mb-0">
                                              {`Price < ${filterDataContents.max_amount}`}
                                              <CloseIcon
                                                onClick={() =>
                                                  clearPriceRange("max")
                                                }
                                              />
                                            </p>
                                          </div>
                                        ) : null}
                                    </>
                                  )}
                                  {filterDataContents.max_amount &&
                                    filterDataContents.min_amount && (
                                      <>
                                        {Number(filterDataContents.max_amount) >
                                          0 &&
                                          Number(
                                            filterDataContents.min_amount
                                          ) ? (
                                            <div className="properties-panel text-center ">
                                              <p className="mb-0">
                                                {`Price ${filterDataContents.min_amount} - ${filterDataContents.max_amount}`}
                                                <CloseIcon
                                                  onClick={() =>
                                                    clearPriceRange("minmax")
                                                  }
                                                />
                                              </p>
                                            </div>
                                          ) : null}
                                      </>
                                    )}
                                  {filterDataContents.search_key != "" && (
                                    <div className="properties-panel text-center ">
                                      <p className="mb-0">
                                        {filterDataContents.search_key}
                                        <CloseIcon
                                          onClick={() => clearSearchKey()}
                                        />
                                      </p>
                                    </div>
                                  )}
                                  {filterDataContents.isActive && (
                                    <button
                                      className="mb-0 link-btn"
                                      onClick={() => clearFilter()}
                                    >
                                      Clear All
                                    </button>
                                  )}
                                </div>
                              </>
                            )}
                            <div className="collection-result-count mb-3">
                              {props.itemsList.loading ? (
                                <p>Loading...</p>
                              ) : (
                                <>
                                  {props.itemsList.data.total < 1 ? (
                                    <p>{props.itemsList.data.total} item</p>
                                  ) : (
                                    <p>{props.itemsList.data.total} items</p>
                                  )}
                                </>
                              )}
                            </div>
                            {props.itemsList.loading ? (
                              <>
                                <div
                                  className={`collection-grid no-margin ${
                                    gridView != 1 ? "small" : ""
                                  }`}
                                >
                                  <CollectionCardLoader
                                    count={12}
                                    classes={`mt-3 ${
                                      gridView != 1 ? "small" : ""
                                    }`}
                                  />
                                </div>
                              </>
                            ) : props.itemsList.data.nft_collection_items
                                .length > 0 ? (
                              <div>
                                <CustomInfiniteScroll
                                  fetchAction={loadMoreNftItemsStart}
                                  fetchData={props.itemsList}
                                  fetchedData={
                                    props.itemsList.data.nft_collection_items
                                  }
                                  skip={
                                    props.itemsList.data.nft_collection_items
                                      .length
                                  }
                                  take={12}
                                  skippedFetch={true}
                                  total={props.itemsList.data.total}
                                  dispatchData={{
                                    min_amount: filterDataContents.min_amount,
                                    max_amount: filterDataContents.max_amount,
                                    categories:
                                      filterDataContents.categories.length > 0
                                        ? JSON.stringify(
                                            filterDataContents.categories
                                          )
                                        : "",
                                    properties:
                                      filterDataContents.properties.length > 0
                                        ? JSON.stringify(
                                            filterDataContents.properties
                                          )
                                        : "",
                                    search_key: filterDataContents.search_key,
                                    sort_by:
                                      priceSort.length > 0
                                        ? priceSort[0].id
                                        : "",
                                    nft_collection_unique_id:
                                      nft_collection_unique_id,
                                  }}
                                  useLoader={true}
                                  loaderComponent={
                                    <CollectionCardLoader count={12} />
                                  }
                                  loaderclasses={`collection-grid no-margin ${
                                    gridView != 1 ? "small" : ""
                                  }`}
                                >
                                  <div
                                    className={`collection-grid no-margin ${
                                      gridView != 1 ? "small" : ""
                                    }`}
                                  >
                                    {props.itemsList.data.nft_collection_items.map(
                                      (item, index) => (
                                        <CollectionCard
                                          data={item}
                                          useFlex={false}
                                          key={index}
                                          gridView={gridView}
                                        />
                                      )
                                    )}
                                  </div>
                                </CustomInfiniteScroll>
                              </div>
                            ) : (
                              <NoDataFound />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={activeTab} index={"activity"}>
                <div className="collection-items">
                  <div className="collection-widget-area">
                    <div className="pr-3">
                      <div className="filter-drawer-wrapper">
                        <CollectionActivityFilter
                          filterStatus={filterStatus}
                          handleFilterDrawerStatus={handleFilterDrawerStatus}
                          mobileFilterStatus={mobileFilterStatus}
                          handleMobileFilterDrawerStatus={
                            handleMobileFilterDrawerStatus
                          }
                          selectedPaymentFilter={activityFilter}
                          handleActivityPaymentFilterChange={
                            handleActivityFilterChange
                          }
                          getFilterWidth
                          setFilterWidth={setFilterWidth}
                        />
                        {props.nftItemsPaymentList.loading ? (
                          <CollectionActivityLoader count={6} />
                        ) : props.nftItemsPaymentList.data.nft_item_payments
                            .length > 0 ? (
                          <>
                            <div
                              className="p-lg-3 collection-activity"
                              style={{
                                width:
                                windowWidth > 768
                                    ? `calc(100vw - ${filterWidth}px - 1rem)`
                                    : "100%",
                              }}
                            >
                              {/* <CustomSelect
                                             value={activityFilter}
                                             handleChange={handleActivityFilterChange}
                                             items={[
                                               "Listing",
                                               "Sale",
                                               "Cancel",
                                               "Transfer",
                                             ]}
                                             placeHolder={"Filter"}
                                             multiple={true}
                                           /> */}
                              {activityFilter.length > 0 && (
                                <>
                                  <div className="collection-filter-properties">
                                    {activityFilter.map((activity, index) => (
                                      <div
                                        className="properties-panel"
                                        key={index}
                                      >
                                        <h6 className="mb-0">{activity}</h6>
                                      </div>
                                    ))}
                                    <Button
                                      variant="link"
                                      onClick={() =>
                                        handleActivityFilterReset()
                                      }
                                    >
                                      Clear All
                                    </Button>
                                  </div>
                                </>
                              )}
                              <CustomInfiniteScroll
                                fetchAction={loadMoreItemActivityStart}
                                fetchData={props.nftItemsPaymentList}
                                fetchedData={
                                  props.nftItemsPaymentList.data
                                    .nft_item_payments
                                }
                                skip={
                                  props.nftItemsPaymentList.data
                                    .nft_item_payments.length
                                }
                                take={20}
                                skippedFetch={true}
                                total={props.nftItemsPaymentList.data.total}
                                dispatchData={{
                                  nft_collection_unique_id:
                                    nft_collection_unique_id,
                                }}
                                useOffset={60}
                              >
                                <Activitytable
                                  paymentData={
                                    activityFilter.length > 0
                                      ? props.nftItemsPaymentList.data.nft_item_payments.filter(
                                          (payments) =>
                                            activityFilter.includes(
                                              payments.payment_type_formatted
                                            )
                                        )
                                      : props.nftItemsPaymentList.data
                                          .nft_item_payments
                                  }
                                  auth={auth}
                                  nft_collection_unique_id={
                                    nft_collection_unique_id
                                  }
                                  collection={
                                    props.singleCollection.data.nft_collection
                                  }
                                  classes={"my-4 p-0"}
                                  activityTable
                                />
                              </CustomInfiniteScroll>
                            </div>
                          </>
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  singleCollection: state.nfts.singleCollection,
  itemsList: state.nfts.itemsList,
  propertiesList: state.nfts.propertiesList,
  nftItemsPaymentList: state.nfts.nftItemsPaymentList,
  importContract: state.nfts.importContract,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(OwnerCollectionViewer);
