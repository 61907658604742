import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";

const SinglePageActivityTableLoader = () => {
  const theme = useTheme();

  return (
    <>
      <SkeletonTheme
        baseColor={theme.loader.primary}
        highlightColor={theme.loader.secondary}
      >
        <div className="single-page-table-loader">
          <Skeleton
            count={1}
            width={"100%"}
            height={440}
            borderRadius={"1rem"}
          />
        </div>
      </SkeletonTheme>
    </>
  );
};

export default SinglePageActivityTableLoader;
