import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import PageReducer from "./PageReducer";
import NftReducer from "./NftReducer";

export default combineReducers({
  users: UserReducer,
  nfts: NftReducer,
  page: PageReducer,
});
