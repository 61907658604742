import React from "react";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const sideBarStyles = makeStyles({
  root: {
    backgroundColor: "red",
  },
  drawer: {
    flexShrink: 0,
    zIndex: "999 !important",
  },
  drawerPaper: {
    marginTop : "58px",
    minWidth: "100vw",
    maxWidth: "100vw",
    overflow: "visible",
    padding: "1em",
  },
});


const CustomDrawer = (props) => {

  const classes = sideBarStyles();

  return (
    <>
      <Box role="presentation" className={classes.root}>
        <Drawer
          anchor={"right"}
          open={props.sidebarState}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          disableSwipeToOpen={true}
        >
          {props.children}
        </Drawer>
      </Box>
    </>
  );
};

export default CustomDrawer;
